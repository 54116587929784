import { useProductManagerActions } from "@iso/actions/productManager.actions";
import { Form, Input, message, Select, Spin } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { STYLE_TYPE_BANNER, VIEW_TYPE_BANNER } from "@iso/config/index";
import UploadFile from "component/UploadFile";

const WrapperForm = styled(Form)`
   .ant-form-item-label {
      min-width: 200px;
      text-align: left;
   }
`;

const { Option } = Select;

function AddEditBanner() {
   const { id } = useParams();
   const [form] = Form.useForm();
   const [dataCategory, setDataCategory] = useState({
      list: [],
      selected: null,
   });
   const [isLoading, setIsLoading] = useState(false);
   const [isSubmitting, setIsSubmitting] = useState(false);
   const productManagerActions = useProductManagerActions();

   const getData = async (id) => {
      setIsLoading(true);
      try {
         const resGet = await productManagerActions.getProductDetail(id);
         resGet.productId = resGet?.id;
         resGet.category_id = resGet?.category?.id;
         resGet.class_id = resGet?.class?.id;
         form.setFieldsValue(resGet);

         const cate = dataCategory?.list?.find((category) => {
            return category?.id === resGet?.category?.id;
         });
         setDataCategory((state) => ({ ...state, selected: cate }));
      } finally {
         setIsLoading(false);
      }
   };

   const getDataCategory = async () => {
      try {
         const resGet = await productManagerActions.getCategories();
         setDataCategory({ list: resGet, selected: null });
      } catch (error) {
         console.log(error);
      }
   };

   const onFinish = async (values) => {
      setIsSubmitting(true);
      values.base_price = parseFloat(values.base_price);
      values.is_active = values?.is_active === false ? false : true;
      try {
         id
            ? await productManagerActions.updateProduct(id, values)
            : await productManagerActions.addProduct(values);
         message.success(`${id ? "Edit" : "Add"} product success!`);
         !id && form.resetFields();
      } catch (error) {
         id && getData(id);
      } finally {
         setIsSubmitting(false);
      }
   };

   useEffect(() => {
      id && getData(id);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [dataCategory.list]);

   useEffect(() => {
      getDataCategory();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <Spin spinning={isLoading}>
         <div className="p-8 mt-8 bg-white-0 rounded-sm shadow-sm">
            <WrapperForm
               name="add-edit-product-form"
               onFinish={onFinish}
               form={form}
            >
               <div className="grid grid-cols-12 gap-20">
                  <div className="col-span-12 sm:col-span-5">
                     <UploadFile imageUrl="" />
                  </div>
                  <div className="col-span-12 sm:col-span-7">
                     <Form.Item
                        name="name"
                        label="Name"
                        rules={[
                           { required: true, message: "Name is required!" },
                        ]}
                     >
                        <Input placeholder="Name" />
                     </Form.Item>
                     <Form.Item
                        name="image_url"
                        label="Image URL"
                        rules={[
                           {
                              required: true,
                              message: "Image URL is required!",
                           },
                        ]}
                     >
                        <Input placeholder="Image URL" />
                     </Form.Item>
                     <Form.Item
                        name="general_url_direction"
                        label="General URL"
                        rules={[
                           {
                              required: true,
                              message: "Image URL is required!",
                           },
                        ]}
                     >
                        <Input placeholder="Image URL" />
                     </Form.Item>
                     <Form.Item
                        name="style_type"
                        label="Style type"
                        rules={[
                           {
                              required: true,
                              message: "Style type is required!",
                           },
                        ]}
                     >
                        <Select placeholder="Select style type">
                           {STYLE_TYPE_BANNER?.map((item) => (
                              <Option key={item?.value} value={item?.value}>
                                 {item?.label}
                              </Option>
                           ))}
                        </Select>
                     </Form.Item>
                     <Form.Item
                        name="view_type"
                        label="View type"
                        rules={[
                           {
                              required: true,
                              message: "View type is required!",
                           },
                        ]}
                     >
                        <Select placeholder="Select view type">
                           {VIEW_TYPE_BANNER?.map((item) => (
                              <Option key={item?.value} value={item?.value}>
                                 {item?.label}
                              </Option>
                           ))}
                        </Select>
                     </Form.Item>
                  </div>
               </div>
            </WrapperForm>
         </div>
      </Spin>
   );
}

export default AddEditBanner;
