import { atom } from "recoil";

const userAtom = atom({
   key: "user",
   default: {
      info: null,
      roles: [],
      stores: [],
      isLogin: false,
   },
});

export { userAtom };
