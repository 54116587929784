import { useState, useEffect } from "react";
import { Table, Button, Popconfirm, Tag, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useRecoilValue } from "recoil";
import { Link, useHistory } from "react-router-dom";
import { userAtom } from "@iso/state/user";
import { useProductManagerActions } from "@iso/actions/productManager.actions";
import Page from "component/Page/index";
import FilterGroup from "component/FilterGroup";
import AsyncButton from "component/AsyncButton";

const ProductManager = () => {
   const history = useHistory();
   const [isLoading, setIsLoading] = useState(true);
   const [isSubmitting, setIsSubmitting] = useState({});
   const [currentFilters, setCurrentFilters] = useState({});
   const [data, setData] = useState({
      dataSource: [],
      paging: {
         current: 1,
         total: 0,
         pageSize: 10,
      },
   });
   const user = useRecoilValue(userAtom);
   const productManagerActions = useProductManagerActions();

   const checkRoles = (roles) => {
      const userRoles = user?.roles || [];
      return roles.every((role) => userRoles.includes(role));
   };

   const handleTableChange = (pagination) => {
      getData(pagination.current, pagination.pageSize, currentFilters);
   };

   const getData = async (offset, limit, filters) => {
      setIsLoading(true);
      const resGet = await productManagerActions.getProducts({
         page: offset,
         limit,
         ...filters,
      });
      setData({
         paging: {
            current: parseInt(resGet.page),
            total: parseInt(resGet.total),
            pageSize: limit,
         },
         dataSource: resGet.data,
      });
      setIsLoading(false);
   };

   const columns = [
      {
         title: "Item Name",
         dataIndex: "name",
         key: "name",
      },
      {
         title: "Item Code",
         dataIndex: "id",
         key: "id",
      },
      {
         title: "Item Price",
         dataIndex: "base_price",
         key: "base_price",
      },
      {
         title: "Status",
         key: "is_active",
         render: (record) => (
            <Tag color={record?.is_active ? "green" : "orange"} title="Paid">
               {record?.is_active ? "Active" : "In Active"}
            </Tag>
         ),
      },
      {
         title: (
            <span>
               Cate-ID/
               <br />
               Cate-Name
            </span>
         ),
         dataIndex: "category",
         key: "category",
         render: (category) => (
            <span>
               - {category?.id}
               <br />- {category?.name}
            </span>
         ),
      },
      {
         title: (
            <span>
               Class-ID/
               <br />
               Class-Name
            </span>
         ),
         dataIndex: "class",
         key: "class",
         render: (classCate) => (
            <span>
               - {classCate?.id}
               <br />- {classCate?.name}
            </span>
         ),
      },
      {
         align: "center",
         fixed: "right",
         render: (record) => (
            <div className="flex gap-3 justify-center">
               {checkRoles(["store_edit"]) && (
                  <>
                     <Link to={`/product/edit-product/${record["id"]}`}>
                        <Button type="primary">Edit</Button>
                     </Link>
                     <Popconfirm
                        title="Are you sure to delete this item?"
                        onConfirm={() => handleDeleteProduct(record["id"])}
                        placement="bottom"
                        okText="Yes"
                        cancelText="No"
                     >
                        <AsyncButton
                           isLoading={isSubmitting[record["id"]] || false}
                           type="primary"
                           style={{ marginLeft: 8 }}
                        >
                           Delete
                        </AsyncButton>
                     </Popconfirm>
                  </>
               )}
            </div>
         ),
      },
   ];

   const handleDeleteProduct = async (id) => {
      try {
         const currentIsSubmitting = { ...isSubmitting };
         currentIsSubmitting[`${id}`] = true;
         setIsSubmitting(currentIsSubmitting);
         await productManagerActions.deleteProduct(id);
         handleTableChange(data.paging);
         message.success("Delete product success!");
      } finally {
         const currentIsSubmitting = { ...isSubmitting };
         delete currentIsSubmitting[id];
         setIsSubmitting(currentIsSubmitting);
      }
   };

   useEffect(() => {
      getData(1, data.paging.pageSize);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <Page title="Product Management">
         <div>
            <div className="flex items-start justify-between mb-8">
               <FilterGroup
                  currentFilters={currentFilters}
                  setCurrentFilters={setCurrentFilters}
                  onGetFilterProperties={
                     productManagerActions.getFilterProperties
                  }
                  onGetOptionsProperty={
                     productManagerActions.getOptionsProperty
                  }
                  onFilter={(filters) =>
                     getData(1, data?.paging?.pageSize, filters)
                  }
               />
               {checkRoles(["store_add"]) && (
                  <Button
                     type="primary"
                     onClick={() => history.push("/product/add-product")}
                  >
                     <p className="flex items-center">
                        <PlusOutlined />
                        <span className="ml-2">Add Item</span>
                     </p>
                  </Button>
               )}
            </div>

            <Table
               columns={columns}
               rowKey="key"
               dataSource={data.dataSource}
               onChange={handleTableChange}
               pagination={{
                  current: data.paging.current,
                  total: data.paging.total,
                  pageSize: data.paging.pageSize,
               }}
               loading={isLoading}
            />
         </div>
      </Page>
   );
};

export default ProductManager;
