import { API_ENDPOINT } from "@iso/config/index";
import { axiosClient } from "@iso/helpers/axios.config";

const url = `${API_ENDPOINT}/group-role`;

const groupRoleApi = {
   gets: (params) => {
      return axiosClient.get(url, { params });
   },
   get: (id) => {
      return axiosClient.get(`${url}/${id}`);
   },
   put: (id, data) => {
      return axiosClient.put(`${url}/${id}`, data);
   },
   post: (data) => {
      return axiosClient.post(`${url}`, data);
   },
   del: (id) => {
      return axiosClient.delete(`${url}/${id}`);
   },
};

export default groupRoleApi;
