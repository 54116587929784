import { Form, Input, Modal, Select, Switch } from "antd";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { STYLE_TYPE_BANNER, VIEW_TYPE_BANNER } from "@iso/config/index";
import UploadFile from "component/UploadFile";

const WrapperForm = styled(Form)`
   .ant-form-item-label {
      min-width: 120px;
      text-align: left;
   }
`;

const { Option } = Select;

function BannerForm(props) {
   const { data, visible, onClose, onSubmitForm, isLoadingForm } = props;
   const [form] = Form.useForm();
   const [imageUrl, setImageUrl] = useState("");

   useEffect(() => {
      handleFillValue(data);
   }, [data, visible]);

   const handleFillValue = (data) => {
      if (data?.id) {
         form.setFieldsValue(data);
         setImageUrl(data?.image_url);
      } else {
         form.resetFields();
         setImageUrl("");
      }
   };

   const onFinish = async (values) => {
      onSubmitForm(values);
   };

   return (
      <Modal
         open={visible}
         onCancel={onClose}
         confirmLoading={isLoadingForm}
         width={1000}
         okText="Save"
         onOk={() => form.submit()}
         title={`${data?.id ? "Edit" : "Add"} Banner`}
      >
         <div className="bg-white-0">
            <WrapperForm
               name="add-edit-product-form"
               onFinish={onFinish}
               form={form}
            >
               <div className="grid grid-cols-12 gap-8">
                  <div className="col-span-12 sm:col-span-5">
                     <Form.Item name="image_url">
                        <UploadFile
                           onChange={(file) => {
                              console.log("file: ", file);
                              form.setFieldValue("image_url", file);
                           }}
                           imageUrl={imageUrl}
                        />
                     </Form.Item>
                  </div>
                  <div className="col-span-12 sm:col-span-7">
                     <Form.Item
                        name="name"
                        label="Name"
                        rules={[
                           { required: true, message: "Name is required!" },
                        ]}
                     >
                        <Input placeholder="Name" />
                     </Form.Item>
                     <Form.Item
                        name="general_url_direction"
                        label="General URL"
                        rules={[
                           {
                              required: true,
                              message: "General URL is required!",
                           },
                           {
                              type: "url",
                              message: "General URL is not valid!",
                           },
                        ]}
                     >
                        <Input placeholder="General URL" />
                     </Form.Item>
                     <Form.Item
                        name="style_type"
                        label="Style type"
                        rules={[
                           {
                              required: true,
                              message: "Style type is required!",
                           },
                        ]}
                     >
                        <Select placeholder="Select style type">
                           {STYLE_TYPE_BANNER?.map((item) => (
                              <Option key={item?.value} value={item?.value}>
                                 {item?.label}
                              </Option>
                           ))}
                        </Select>
                     </Form.Item>
                     <Form.Item
                        name="view_type"
                        label="View type"
                        rules={[
                           {
                              required: true,
                              message: "View type is required!",
                           },
                        ]}
                     >
                        <Select placeholder="Select view type">
                           {VIEW_TYPE_BANNER?.map((item) => (
                              <Option key={item?.value} value={item?.value}>
                                 {item?.label}
                              </Option>
                           ))}
                        </Select>
                     </Form.Item>
                     <Form.Item
                        name="is_active"
                        label="Active"
                        valuePropName="checked"
                     >
                        <Switch defaultChecked />
                     </Form.Item>
                  </div>
               </div>
            </WrapperForm>
         </div>
      </Modal>
   );
}

export default BannerForm;
