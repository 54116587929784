import { useItemLangManagerActions } from "@iso/actions/itemLangManager.actions";
import { Collapse, Form, Input, message, Spin } from "antd";
import { useEffect, useState } from "react";
import AsyncButton from "component/AsyncButton";

const { Panel } = Collapse;

const { TextArea } = Input;

function LangDetail(props) {
   const { product, langId, deviceId } = props;
   const [form] = Form.useForm();
   const [data, setData] = useState(null);
   const [isLoading, setIsLoading] = useState(false);
   const [isSubmitting, setIsSubmitting] = useState(false);
   const itemLangManagerActions = useItemLangManagerActions();

   const getData = async () => {
      try {
         setIsLoading(true);
         const resGet = await itemLangManagerActions.getItemLangDetail({
            item_id: product?.id,
            lang_id: langId,
            device_id: deviceId,
         });
         if (resGet) {
            form.setFieldsValue(resGet);
            setData(resGet);
         } else {
            form.resetFields();
            setData(null);
         }
         setData(resGet);
      } finally {
         setIsLoading(false);
      }
   };

   const handleUpdate = async (values) => {
      setIsSubmitting(true);
      try {
         const dataLang = {
            standard_name: data?.standard_name,
            short_name: data?.short_name,
            long_name: data?.long_name,
            standard_description: data?.standard_description,
            short_description: data?.short_description,
            long_description: data?.long_description,
            standard_story: data?.standard_story,
            short_story: data?.short_story,
            long_story: data?.long_story,
         };
         if (data) {
            await itemLangManagerActions.editItemLang(data?.id, {
               ...dataLang,
               ...values,
            });
            message.success("Update item lang success!");
         } else {
            await itemLangManagerActions.addItemLang({
               item_id: product?.id,
               lang_id: langId,
               device_id: deviceId,
               ...values,
            });
            message.success("Update item lang success!");
         }
      } finally {
         setIsSubmitting(false);
      }
   };

   useEffect(() => {
      if (product?.id) {
         getData();
      } else {
         form.resetFields();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [product]);

   return (
      <Spin spinning={isLoading}>
         <Form layout="vertical" onFinish={handleUpdate} form={form}>
            <Collapse>
               <Panel header={<h2 className="uppercase">Name</h2>} key="name">
                  <Form.Item name="standard_name" label="Standard Name">
                     <TextArea />
                  </Form.Item>
                  <Form.Item name="short_name" label="Short Name">
                     <TextArea />
                  </Form.Item>
                  <Form.Item name="long_name" label="Long Name">
                     <TextArea />
                  </Form.Item>
               </Panel>
            </Collapse>
            <Collapse>
               <Panel
                  header={<h2 className="uppercase">Description</h2>}
                  key="description"
               >
                  <Form.Item
                     name="standard_description"
                     label="Standard Description"
                  >
                     <TextArea />
                  </Form.Item>
                  <Form.Item name="short_description" label="Short Description">
                     <TextArea />
                  </Form.Item>
                  <Form.Item name="long_description" label="Long Description">
                     <TextArea />
                  </Form.Item>
               </Panel>
            </Collapse>
            <Collapse>
               <Panel header={<h2 className="uppercase">Story</h2>} key="story">
                  <Form.Item name="standard_story" label="Standard Story">
                     <TextArea />
                  </Form.Item>
                  <Form.Item name="short_story" label="Short Story">
                     <TextArea />
                  </Form.Item>
                  <Form.Item name="long_story" label="Long Story">
                     <TextArea />
                  </Form.Item>
               </Panel>
            </Collapse>
         </Form>
         {!isLoading && (
            <div className="bg-white-0 p-8 text-right flex justify-end shadow-sm">
               <AsyncButton
                  isLoading={isSubmitting}
                  type="primary"
                  onClick={() => form.submit()}
               >
                  Submit
               </AsyncButton>
            </div>
         )}
      </Spin>
   );
}

export default LangDetail;
