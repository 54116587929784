import { useProductManagerActions } from "@iso/actions/productManager.actions";
import { Form, Input, message, Radio, Select, Spin } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Page from "component/Page";
import styled from "styled-components";
import AsyncButton from "component/AsyncButton";

const WrapperForm = styled(Form)`
   .ant-form-item-label {
      min-width: 200px;
      text-align: left;
   }
`;

const { Option } = Select;

function AddEditProduct() {
   const { id } = useParams();
   const [form] = Form.useForm();
   const [dataCategory, setDataCategory] = useState({
      list: [],
      selected: null,
   });
   const [isLoading, setIsLoading] = useState(false);
   const [isSubmitting, setIsSubmitting] = useState(false);
   const productManagerActions = useProductManagerActions();

   const getData = async (id) => {
      setIsLoading(true);
      try {
         const resGet = await productManagerActions.getProductDetail(id);
         resGet.productId = resGet?.id;
         resGet.category_id = resGet?.category?.id;
         resGet.class_id = resGet?.class?.id;
         form.setFieldsValue(resGet);

         const cate = dataCategory?.list?.find((category) => {
            return category?.id === resGet?.category?.id;
         });
         setDataCategory((state) => ({ ...state, selected: cate }));
      } finally {
         setIsLoading(false);
      }
   };

   const getDataCategory = async () => {
      try {
         const resGet = await productManagerActions.getCategories();
         setDataCategory({ list: resGet, selected: null });
      } catch (error) {
         console.log(error);
      }
   };

   const handleChangeCate = (id) => {
      const cate = dataCategory?.list?.find((category) => {
         return category?.id === id;
      });
      setDataCategory((state) => ({ ...state, selected: cate }));
      form.setFieldsValue({ class_id: null });
   };

   const onFinish = async (values) => {
      setIsSubmitting(true);
      values.base_price = parseFloat(values.base_price);
      values.is_active = values?.is_active === false ? false : true;
      try {
         id
            ? await productManagerActions.updateProduct(id, values)
            : await productManagerActions.addProduct(values);
         message.success(`${id ? "Edit" : "Add"} product success!`);
         !id && form.resetFields();
      } catch (error) {
         id && getData(id);
      } finally {
         setIsSubmitting(false);
      }
   };

   useEffect(() => {
      id && getData(id);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [dataCategory.list]);

   useEffect(() => {
      getDataCategory();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <Page title={id ? "Edit Product" : "Add Product"}>
         <Spin spinning={isLoading}>
            <div className="p-8 mt-8 bg-white-0 rounded-sm shadow-sm">
               <WrapperForm
                  name="add-edit-product-form"
                  onFinish={onFinish}
                  form={form}
               >
                  <div className="grid grid-cols-2 gap-20">
                     <div>
                        <Form.Item
                           name="name"
                           label="Name"
                           rules={[
                              { required: true, message: "Name is required!" },
                           ]}
                        >
                           <Input placeholder="Name" />
                        </Form.Item>
                        <Form.Item
                           name="receipt_name"
                           label="Receipt name"
                           rules={[
                              {
                                 required: true,
                                 message: "Receipt name is required!",
                              },
                           ]}
                        >
                           <Input placeholder="Receipt name" />
                        </Form.Item>
                        <Form.Item
                           name="productId"
                           label="Product Id"
                           rules={[
                              {
                                 required: true,
                                 message: "Product Id is required!",
                              },
                           ]}
                        >
                           <Input
                              disabled={id ? true : false}
                              placeholder="Product Id"
                           />
                        </Form.Item>
                        <Form.Item
                           name="sap_code"
                           label="Sap code"
                           rules={[
                              {
                                 required: true,
                                 message: "Sap code is required!",
                              },
                           ]}
                        >
                           <Input placeholder="Sap code" />
                        </Form.Item>{" "}
                        <Form.Item
                           name="category_id"
                           label="Category"
                           rules={[
                              {
                                 required: true,
                                 message: "Category is required!",
                              },
                           ]}
                        >
                           <Select
                              onChange={handleChangeCate}
                              placeholder="Select Category"
                           >
                              {dataCategory?.list?.map((category) => (
                                 <Option
                                    key={category?.id}
                                    value={category?.id}
                                 >
                                    {category?.name}
                                 </Option>
                              ))}
                           </Select>
                        </Form.Item>
                        <Form.Item
                           name="class_id"
                           label="Class"
                           rules={[
                              { required: true, message: "Class is required!" },
                           ]}
                        >
                           <Select
                              placeholder="Select Class"
                              disabled={!dataCategory.selected}
                           >
                              {dataCategory.selected?.classCate?.map((cl) => (
                                 <Option key={cl?.id} value={cl?.id}>
                                    {cl?.name}
                                 </Option>
                              ))}
                           </Select>
                        </Form.Item>
                        <Form.Item
                           name="kitchen_name"
                           label="Kitchen name"
                           rules={[
                              {
                                 required: true,
                                 message: "Kitchen name is required!",
                              },
                           ]}
                        >
                           <Input placeholder="Kitchen name" />
                        </Form.Item>
                        <Form.Item
                           name="prepare_time"
                           label="Prepare time"
                           rules={[
                              {
                                 required: true,
                                 message: "Prepare time is required!",
                              },
                           ]}
                        >
                           <Input type="number" placeholder="Prepare time" />
                        </Form.Item>
                        <Form.Item
                           name="add_time_per_dish"
                           label="Add time per dish"
                           rules={[
                              {
                                 required: true,
                                 message: "Add time per dish is required!",
                              },
                           ]}
                        >
                           <Input
                              type="number"
                              placeholder="Add time per dish"
                           />
                        </Form.Item>
                     </div>
                     <div>
                        <Form.Item
                           name="sku"
                           label="Sku"
                           rules={[
                              { required: true, message: "Sku is required!" },
                           ]}
                        >
                           <Input placeholder="Sku" />
                        </Form.Item>{" "}
                        <Form.Item
                           name="quantity_in_stock"
                           label="Quantity in stock"
                           rules={[
                              {
                                 required: true,
                                 message: "Quantity in stock is required!",
                              },
                           ]}
                        >
                           <Input
                              type="number"
                              placeholder="Quantity in stock"
                           />
                        </Form.Item>
                        <Form.Item
                           name="quantity_sold"
                           label="Quantity sold"
                           rules={[
                              {
                                 required: true,
                                 message: "Quantity sold is required!",
                              },
                           ]}
                        >
                           <Input type="number" placeholder="Quantity sold" />
                        </Form.Item>
                        <Form.Item
                           name="base_price"
                           label="Base price"
                           rules={[
                              {
                                 required: true,
                                 message: "Base price is required!",
                              },
                              {
                                 pattern: new RegExp(
                                    /^(0*[1-9][0-9]*(\[0-9]+)?|0+\[0-9]*[1-9][0-9]*)$/
                                 ),
                                 message: "Base price is invalid!",
                              },
                           ]}
                        >
                           <Input type="number" placeholder="Base price" />
                        </Form.Item>
                        <Form.Item
                           name="unit"
                           label="Unit"
                           rules={[
                              { required: true, message: "Unit is required!" },
                           ]}
                        >
                           <Input placeholder="Unit" />
                        </Form.Item>
                        <Form.Item
                           name="tax_percentage"
                           label="Tax percentage"
                           rules={[
                              {
                                 required: true,
                                 message: "Tax percentage is required!",
                              },
                           ]}
                        >
                           <Input type="number" placeholder="Tax percentage" />
                        </Form.Item>
                        <Form.Item
                           name="max_grouping"
                           label="Max Grouping"
                           rules={[
                              {
                                 required: true,
                                 message: "Max Grouping is required!",
                              },
                           ]}
                        >
                           <Input type="number" placeholder="Max Grouping" />
                        </Form.Item>
                        <Form.Item
                           name="standard_time"
                           label="Standard time"
                           rules={[
                              {
                                 required: true,
                                 message: "Standard time is required!",
                              },
                           ]}
                        >
                           <Input type="number" placeholder="Standard time" />
                        </Form.Item>
                        <Form.Item name="is_active" label="Status">
                           <Radio.Group
                              style={{
                                 display: "flex",
                                 justifyContent: "flex-start",
                              }}
                              defaultValue={true}
                           >
                              <Radio value={true}>Active</Radio>
                              <Radio value={false}>In active</Radio>
                           </Radio.Group>
                        </Form.Item>
                     </div>
                  </div>
               </WrapperForm>
            </div>
         </Spin>
         <div className="mt-8 bg-white-0 p-8 flex justify-end shadow-sm">
            <AsyncButton
               isLoading={isSubmitting}
               type="primary"
               onClick={() => form.submit()}
            >
               Submit
            </AsyncButton>
         </div>
      </Page>
   );
}

export default AddEditProduct;
