import menuItemManagerApi from "api/menuItemManagerApi";

export { useMenuItemManagerActions };

function useMenuItemManagerActions() {
   const getMenuItemList = async (params) => {
      try {
         return await menuItemManagerApi.gets(params);
      } catch (error) {
         console.log(error);
         return {
            status: false,
         };
      }
   };

   const getMenuDisplay = async (params) => {
      try {
         return await menuItemManagerApi.getMenuDisplay(params);
      } catch (error) {
         console.log(error);
         return {
            status: false,
         };
      }
   };

   const getMenuItemDetail = async (id) => {
      try {
         return await menuItemManagerApi.get(id);
      } catch (error) {
         console.log(error);
         return {
            status: false,
         };
      }
   };

   const addMenuItem = async (body) => {
      try {
         return await menuItemManagerApi.post(body);
      } catch (error) {
         console.log(error);
         return {
            status: false,
         };
      }
   };

   const editMenuItem = async (id, body) => {
      try {
         return await menuItemManagerApi.put(id, body);
      } catch (error) {
         console.log(error);
         return {
            status: false,
         };
      }
   };

   const deleteMenuItem = async (id) => {
      try {
         return await menuItemManagerApi.delete(id);
      } catch (error) {
         console.log(error);
         return {
            status: false,
         };
      }
   };
   return {
      getMenuItemList,
      addMenuItem,
      editMenuItem,
      getMenuItemDetail,
      deleteMenuItem,
      getMenuDisplay,
   };
}
