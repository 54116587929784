import { SYS_CONTANT } from "@iso/config/index";
import { userAtom } from "@iso/state/user";
import { useRecoilValue } from "recoil";

const SysContant = SYS_CONTANT;

const handleDirect = (e) => {
   if (Array.isArray(SysContant)) {
      window.location.href = SysContant.find((i) => i.value === e)?.links;
   }
};

function ChooseSystem() {
   const user = useRecoilValue(userAtom);
   return (
      <div className="mx-8">
         <h1 className="text-center my-14 mb-12">Choose your workplace</h1>
         <div className="grid grid-cols-3 gap-6">
            {Array.isArray(SysContant) &&
               SysContant?.map(
                  (item, index) =>
                     user?.roles?.includes(item?.role) && (
                        <div
                           className="flex text-center items-center justify-center py-24 border-2 font-bold hover:border-primary-500 rounded-2xl text-xl cursor-pointer"
                           key={index}
                           onClick={() => handleDirect(item?.value)}
                        >
                           {item.label}
                        </div>
                     )
               )}
         </div>
      </div>
   );
}

export default ChooseSystem;
