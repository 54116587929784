import scheduleSurchargeManagerApi from "api/scheduleSurchargeManagerApi";

export { useScheduleSurchargeManagerActions };

function useScheduleSurchargeManagerActions() {
   const getList = async (params) => {
      try {
         return await scheduleSurchargeManagerApi.gets(params);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const add = async (body) => {
      try {
         return await scheduleSurchargeManagerApi.post(body);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const edit = async (id, body) => {
      try {
         return await scheduleSurchargeManagerApi.put(id, body);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const deleteById = async (id) => {
      try {
         return await scheduleSurchargeManagerApi.delete(id);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   return {
      getList,
      add,
      edit,
      deleteById,
   };
}
