import { useEffect } from "react";
import { Form, Input, Select, DatePicker, Button, message } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { userAtom, loaderAtom } from "@iso/state";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useUserActions } from "@iso/actions";
import { useHistory } from "react-router-dom";
import * as CONFIG from "@iso/config/";
import moment from "moment";

function Register() {
   const [form] = Form.useForm();
   const history = useHistory();
   const user = useRecoilValue(userAtom);
   const setLoader = useSetRecoilState(loaderAtom);
   const userActions = useUserActions();

   const prefixSelector = (
      <Form.Item name="prefix" noStyle>
         <Select style={{ width: 70 }} disabled={true} showArrow={false}>
            <Select.Option value="+84">+84</Select.Option>
         </Select>
      </Form.Item>
   );

   const disabledDate = (current) => {
      // Can not select days after today and today
      return current && current > moment().endOf("day");
   };

   const handleUpdatePofile = async (values) => {
      setLoader(true);
      try {
         const { birthday, fullname, gender, phonenumber, prefix, email } =
            values;
         const newInfo = {
            full_name: fullname,
            phone_number: `${prefix}${phonenumber}`,
            gender: gender,
            birth_day: birthday.format(),
            email: email,
         };
         const resRegister = await userActions.registerInfo(newInfo);
         if (resRegister?.status) {
            message.success("Register success!");
            setLoader(false);
            history.push("/choose-system");
         }
         setLoader(false);
      } catch (error) {
         console.log(error);
      }
   };

   useEffect(() => {
      form.setFieldsValue({
         gender: "male",
         prefix: "+84",
         email: user?.info?.email,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <div className="bg-gray-400 min-h-screen flex items-center justify-center">
         <div className="w-1/3 bg-white-0 p-11 shadow rounded-sm">
            <Form
               layout="vertical"
               name="profile-form"
               onFinish={handleUpdatePofile}
               form={form}
            >
               <Form.Item
                  label="Full Name"
                  name="fullname"
                  rules={[
                     {
                        required: true,
                        message: "Please input your fullname!",
                     },
                  ]}
               >
                  <Input placeholder="Your full name" />
               </Form.Item>

               <Form.Item
                  label="Email"
                  name="email"
                  disabled
                  rules={[
                     { required: true, message: "Please input your email!" },
                     { type: "email", message: "Not valid email address" },
                  ]}
               >
                  <Input disabled />
               </Form.Item>

               <Form.Item
                  label="Phone number"
                  name="phonenumber"
                  rules={[
                     {
                        required: true,
                        message: "Please input your phone number!",
                     },
                     { len: 9, message: "Invalid phone number" },
                  ]}
               >
                  <Input
                     addonBefore={prefixSelector}
                     style={{ width: "100%" }}
                     placeholder="Your phone number"
                  />
               </Form.Item>

               <Form.Item
                  label="Gender"
                  name="gender"
                  rules={[
                     {
                        required: true,
                        message: "Please choose your gender",
                     },
                  ]}
               >
                  <Select style={{ width: "100%" }}>
                     <Select.Option value="male">Male</Select.Option>
                     <Select.Option value="female">Female</Select.Option>
                     <Select.Option value="undefined">Undefined</Select.Option>
                  </Select>
               </Form.Item>

               <Form.Item
                  label="Your Birthday"
                  name="birthday"
                  rules={[
                     {
                        required: true,
                        message: "Please choose your birthday",
                     },
                  ]}
               >
                  <DatePicker
                     format={CONFIG.DATE_FORMAT}
                     disabledDate={disabledDate}
                     style={{ width: "100%" }}
                  />
               </Form.Item>

               <div className="text-center">
                  <Button type="primary" htmlType="submit">
                     <p className="flex items-center">
                        <SaveOutlined />
                        <span className="ml-2">Update your profile</span>
                     </p>
                  </Button>
               </div>
            </Form>
         </div>
      </div>
   );
}

export default Register;
