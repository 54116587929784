import langManagerApi from "api/langManagerApi";

export { useLangManagerActions };

function useLangManagerActions() {
   const getLangList = async (params) => {
      try {
         return await langManagerApi.gets(params);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const getLangDetail = async (id) => {
      try {
         return await langManagerApi.get(id);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const addLang = async (body) => {
      try {
         return await langManagerApi.post(body);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const editLang = async (id, body) => {
      try {
         return await langManagerApi.put(id, body);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const deleteLang = async (id) => {
      try {
         return await langManagerApi.delete(id);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   return {
      getLangList,
      addLang,
      editLang,
      getLangDetail,
      deleteLang,
   };
}
