import { API_ENDPOINT } from "@iso/config/index";
import { axiosClient } from "@iso/helpers/axios.config";

const MENU_ITEM_ENDPOINT = `${API_ENDPOINT}/menu-item`;

const menuItemManagerApi = {
   gets: (params) => {
      return axiosClient.get(MENU_ITEM_ENDPOINT, { params });
   },

   get: (id) => {
      return axiosClient.get(`${MENU_ITEM_ENDPOINT}/${id}`);
   },

   post: (body) => {
      return axiosClient.post(MENU_ITEM_ENDPOINT, body);
   },

   put: (id, body) => {
      return axiosClient.put(`${MENU_ITEM_ENDPOINT}/${id}`, body);
   },

   delete: (id) => {
      return axiosClient.put(`${MENU_ITEM_ENDPOINT}/${id}`, {
         is_active: false,
      });
   },

   getMenuDisplay: (params) => {
      params.page = 1;
      params.limit = 10;
      return axiosClient.get(`${MENU_ITEM_ENDPOINT}/menu-display`, { params });
   },
};

export default menuItemManagerApi;
