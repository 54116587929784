import groupRoleApi from "api/groupRoleApi";

export { useGroupRoleActions };

function useGroupRoleActions() {
   const getGroupRoleList = async (params) => {
      try {
         return await groupRoleApi.gets(params);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const addNewGroupRole = async (data) => {
      try {
         return await groupRoleApi.post(data);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const updateGroupRole = async (id, data) => {
      try {
         return await groupRoleApi.put(id, data);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const deleteGroupRole = async (id) => {
      try {
         return await groupRoleApi.del(id);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const getRoleDetail = async (id) => {
      try {
         return await groupRoleApi.get(id);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   return {
      addNewGroupRole,
      deleteGroupRole,
      getGroupRoleList,
      updateGroupRole,
      getRoleDetail,
   };
}
