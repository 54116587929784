import { useState, useEffect } from "react";
import {
   Checkbox,
   Row,
   Col,
   Button,
   message,
   Form,
   Input,
   Divider,
   Collapse,
} from "antd";
import { useGroupRoleActions } from "@iso/actions/groupRole.actions";
import { useParams } from "react-router-dom";
import { SaveOutlined } from "@ant-design/icons";
import styled from "styled-components";
import Page from "component/Page/index";
import Loading from "../Loading/index";
import useAuthor from "hooks/useAuthor";

const { Panel } = Collapse;
const CustomStyle = styled.div`
   .ant-checkbox-wrapper {
      margin-bottom: 8px;
      white-space: nowrap;
   }
   .ant-collapse {
      width: 100%;
      margin-bottom: 16px;
   }
   .ant-collapse-header {
      align-items: center !important;
      h2 {
         margin-bottom: 0;
      }
   }
   h3 {
      width: 100%;
   }
`;

const GroupRoleDetail = () => {
   const { id } = useParams();
   const [form] = Form.useForm();
   const [checkedList, setCheckedList] = useState([]);
   const [data, setData] = useState();
   const [isLoading, setIsLoading] = useState(true);
   const [isSubmitting, setIsSubmitting] = useState(false);
   const { checkRoles } = useAuthor();
   const groupRoleActions = useGroupRoleActions();

   const getData = async () => {
      setIsLoading(true);
      try {
         const resGet = await groupRoleActions.getRoleDetail(id);
         if (resGet?.functions) {
            form.setFieldsValue({
               name: resGet?.name || "-",
               description: resGet?.description || "",
            });
            const arrDefaultChecked = [];
            Object.entries(resGet?.functions).map(([key, values]) =>
               Object.entries(values).map(([key, value]) =>
                  value.map(
                     (item) => item.isActive && arrDefaultChecked.push(item?.id)
                  )
               )
            );
            if (arrDefaultChecked.length > 0) {
               setCheckedList(arrDefaultChecked);
            }
         }
         setData(resGet);
      } catch (error) {
         console.log(error);
      } finally {
         setIsLoading(false);
      }
   };

   const onRoleClick = (status, item) => {
      if (status) {
         setCheckedList([...checkedList, item?.id]);
      } else {
         const newRoles = checkedList?.filter((role) => role !== item?.id);
         setCheckedList(newRoles);
      }
   };

   const onSubmit = async () => {
      setIsSubmitting(true);
      try {
         await groupRoleActions.updateGroupRole(id, {
            ...form.getFieldsValue(),
            functionals: checkedList,
         });
         message.success("Update Roles success!");
      } catch (error) {
         console.log(error);
      } finally {
         setIsSubmitting(false);
      }
   };

   useEffect(() => {
      getData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <>
         {isLoading ? (
            <Loading />
         ) : (
            <Page title="Role Detail">
               <div className="assign-store-wrapper">
                  <div className="bg-white-0 rounded-sm shadow-sm">
                     <div className="w-2/5 flex mb-8 pt-8 px-6">
                        <div className="flex-grow mr-4">
                           <Form onFinish={onSubmit} form={form}>
                              <div className="flex">
                                 <div className="flex-grow">
                                    <Form.Item
                                       name="name"
                                       label="Name"
                                       rules={[
                                          {
                                             required: true,
                                             message: "Name is required!",
                                          },
                                       ]}
                                    >
                                       <Input placeholder="Name" />
                                    </Form.Item>
                                 </div>
                              </div>
                              <Form.Item name="description" label="Description">
                                 <Input.TextArea
                                    placeholder="Description"
                                    rows={4}
                                 />
                              </Form.Item>
                           </Form>
                        </div>
                     </div>
                  </div>
                  <hr />
                  <h2 className="my-7">Roles list</h2>
                  <CustomStyle>
                     <Checkbox.Group
                        style={{ width: "100%" }}
                        value={checkedList}
                     >
                        <Row>
                           <Collapse>
                              {data?.functions
                                 ? Object.entries(data?.functions).map(
                                      ([key, values]) => (
                                         <Panel
                                            header={
                                               <h2 className="uppercase">
                                                  {key
                                                     ?.toString()
                                                     ?.replace("_", " ")}
                                               </h2>
                                            }
                                            key={key}
                                         >
                                            <Row>
                                               {Object.entries(values).map(
                                                  ([key, value], index) => (
                                                     <>
                                                        {index !== 0 && (
                                                           <Divider />
                                                        )}

                                                        <h3 className="capitalize">
                                                           {key
                                                              ?.toString()
                                                              ?.replaceAll(
                                                                 "_",
                                                                 " "
                                                              )}
                                                        </h3>
                                                        {value.map(
                                                           (item, index) => (
                                                              <Col
                                                                 span={8}
                                                                 key={index}
                                                              >
                                                                 <Checkbox
                                                                    value={
                                                                       item?.id
                                                                    }
                                                                    onChange={(
                                                                       e
                                                                    ) =>
                                                                       onRoleClick(
                                                                          e
                                                                             ?.target
                                                                             ?.checked,
                                                                          item
                                                                       )
                                                                    }
                                                                 >
                                                                    <h4 className="capitalize">
                                                                       {item?.name
                                                                          ?.toString()
                                                                          ?.replaceAll(
                                                                             "_",
                                                                             " "
                                                                          )}
                                                                    </h4>
                                                                 </Checkbox>
                                                              </Col>
                                                           )
                                                        )}
                                                     </>
                                                  )
                                               )}
                                            </Row>
                                         </Panel>
                                      )
                                   )
                                 : ""}
                           </Collapse>
                        </Row>
                     </Checkbox.Group>
                  </CustomStyle>
                  {checkRoles(["group_role_edit"]) && (
                     <div className="px-6 py-8 mb-12 bg-white-0 rounded-sm shadow-sm text-right">
                        <Button
                           loading={isSubmitting}
                           type="primary"
                           onClick={onSubmit}
                        >
                           <p className="flex items-center">
                              <SaveOutlined />
                              <span className="ml-2">Save</span>
                           </p>
                        </Button>
                     </div>
                  )}
               </div>
            </Page>
         )}
      </>
   );
};

export default GroupRoleDetail;
