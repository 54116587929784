import { API_ENDPOINT, API_LOYALTY_BO_ENDPOINT } from "@iso/config/index";
import { axiosClient } from "@iso/helpers/axios.config";

const BANNER_ENDPOINT = `${API_ENDPOINT}/banner`;
const BANNER_CHANNEL_ENDPOINT = `${API_ENDPOINT}/banner_store_channel`;
const STORE_CHANNEL_ENDPOINT = `${API_ENDPOINT}/store_channel`;
const LOYALTY_BO_ENDPOINT = `${API_LOYALTY_BO_ENDPOINT}/content-mn`;

console.log("LOYALTY_BO_ENDPOINT: ", LOYALTY_BO_ENDPOINT);
const bannerManagerApi = {
   getBanners: (params) => {
      return axiosClient.get(`${BANNER_ENDPOINT}`, { params: params });
   },
   createBanner: (body) => {
      return axiosClient.post(`${BANNER_ENDPOINT}`, body);
   },
   updateBanner: (id, body) => {
      return axiosClient.put(`${BANNER_ENDPOINT}/${id}`, body);
   },
   deleteBanner: (id) => {
      return axiosClient.delete(`${BANNER_ENDPOINT}/${id}`);
   },
   getBannersChannel: (params) => {
      return axiosClient.get(
         `${BANNER_CHANNEL_ENDPOINT}/${params?.storeChannelId}`
      );
   },
   createBannerChannel: (body) => {
      return axiosClient.post(`${BANNER_CHANNEL_ENDPOINT}`, body);
   },
   updateBannerChannel: (id, body) => {
      return axiosClient.put(`${BANNER_CHANNEL_ENDPOINT}/${id}`, body);
   },
   updateSortingBannerChannel: (body) => {
      return axiosClient.patch(`${BANNER_CHANNEL_ENDPOINT}/sorting`, body);
   },
   deleteBannerChannel: (id) => {
      return axiosClient.delete(`${BANNER_CHANNEL_ENDPOINT}/${id}`);
   },
   getStoreChannel: () => {
      return axiosClient.get(`${STORE_CHANNEL_ENDPOINT}`);
   },
   uploadImage: (file) => {
      return axiosClient.post(`${LOYALTY_BO_ENDPOINT}/upload-media`, file);
   },
};

export default bannerManagerApi;
