import { useState, useEffect } from "react";
import { Checkbox, Row, Col } from "antd";
import { useStaffActions } from "@iso/actions/staffSetting.actions";
import { useParams } from "react-router-dom";
import { message } from "../../../node_modules/antd/lib/index";
import Page from "component/Page/index";
import Loading from "../Loading/index";
import styled from "styled-components";
const CustomStyle = styled.div`
   .ant-checkbox-wrapper {
      margin-bottom: 8px;
   }
`;

const GroupRoleStaff = () => {
   const { id } = useParams();
   const [isLoading, setIsLoading] = useState(true);
   const [checkedList, setCheckedList] = useState([]);
   const [dataDetail, setDataDetail] = useState();
   const staffActions = useStaffActions();

   const getData = async () => {
      setIsLoading(true);
      try {
         const resGet = await staffActions.getStaffSettingRoleDetail(id);
         setDataDetail(resGet);
         if (resGet?.data) {
            setCheckedList(
               resGet?.data
                  .filter((x) => x.isActive === true)
                  .map((item) => item["id"])
            );
         }
      } catch (error) {
         console.log(error);
      } finally {
         setIsLoading(false);
      }
   };

   useEffect(() => {
      getData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const handleSelectRole = async (e, data) => {
      try {
         await staffActions.updateStaffRole(id, {
            groupRole_id: data["id"],
            action: checkedList.includes(data["id"]) ? "REMOVE" : "ADD",
         });
         checkedList.includes(data["id"])
            ? message.success("Remove role success!", [0.5])
            : message.success("Apply role success!", [0.5]);
      } catch (error) {
         console.log(error);
      }
   };

   const onChange = (checkedList) => {
      setCheckedList(checkedList);
   };

   return (
      <>
         {isLoading ? (
            <Loading />
         ) : (
            <Page title="Group Role Staff">
               <div className="assign-store-wrapper">
                  <div className="px-6 py-8 mb-12 bg-white-0 rounded-sm shadow-sm">
                     <Row>
                        <Col span={8}>
                           <h3 className="mb-0">
                              {dataDetail?.staff?.full_name || "-"}
                           </h3>
                        </Col>
                        <Col span={8}>
                           <h3 className="mb-0">
                              {dataDetail?.staff?.email || "-"}
                           </h3>
                        </Col>
                        <Col span={8}>
                           <h3 className="mb-0">
                              {dataDetail?.staff?.phone_number || "-"}
                           </h3>
                        </Col>
                     </Row>
                  </div>
                  <hr />
                  <h2 className="my-7">Group role list</h2>
                  <div className="px-6 py-8 rounded-sm shadow-sm bg-white-0">
                     <CustomStyle>
                        <Checkbox.Group
                           style={{ width: "100%" }}
                           value={checkedList}
                           onChange={onChange}
                        >
                           <Row>
                              {dataDetail?.data && dataDetail?.data?.length > 0
                                 ? dataDetail?.data.map((item, index) => (
                                      <Checkbox
                                         key={index}
                                         value={item["id"]}
                                         onClick={(e) =>
                                            handleSelectRole(e, item)
                                         }
                                         style={{ marginBottom: 0 }}
                                      >
                                         <h4 className="mb-0">{item?.name}</h4>
                                      </Checkbox>
                                   ))
                                 : ""}
                           </Row>
                        </Checkbox.Group>
                     </CustomStyle>
                  </div>
               </div>
            </Page>
         )}
      </>
   );
};

export default GroupRoleStaff;
