import { FilterOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Divider, Input, Checkbox, Empty, Spin } from "antd";
import { useEffect, useState } from "react";
import Dropdown from "component/Dropdown";
import styled from "styled-components";
import AsyncButton from "component/AsyncButton";

const Content = styled.div`
   min-width: 27rem;
   ul {
      max-height: 40rem;
      overflow-y: scroll;
      /* width */
      ::-webkit-scrollbar {
         width: 0px;
      }
   }
`;

function DropdownFilter(props) {
   const [options, setOptions] = useState({ src: [], filter: [] });
   const [inputSearch, setInputSearch] = useState("");
   const [optionsChecked, setOptionsChecked] = useState({
      origin: [],
      draft: [],
   });
   const [isLoading, setIsLoading] = useState(false);
   const [isSubmitting, setIsSubmitting] = useState(false);

   const getDataOptions = async () => {
      setIsLoading(true);
      try {
         const resGet = await props.onGetOptions();
         setOptions({ src: resGet, filter: resGet });
      } finally {
         setIsLoading(false);
      }
   };

   const handleInputChange = (value) => {
      const filterOptions = options?.src?.filter((option) =>
         option?.label?.toLowerCase()?.includes(value?.toLowerCase())
      );
      setOptions((state) => ({ ...state, filter: filterOptions }));
   };

   const handleOptionCheck = (value) => {
      const isExits = optionsChecked?.draft?.includes(value);
      if (isExits) {
         const newOptionsChecked = optionsChecked?.draft?.filter(
            (option) => option !== value
         );
         setOptionsChecked((state) => ({ ...state, draft: newOptionsChecked }));
      } else {
         setOptionsChecked((state) => ({
            ...state,
            draft: [...state?.draft, value],
         }));
      }
   };

   const handleCloseDropdown = () => {
      setOptionsChecked((state) => ({
         ...state,
         draft: [...state?.origin],
      }));
   };

   const handleFilter = () => {
      setOptionsChecked((state) => ({
         ...state,
         origin: [...state?.draft],
      }));
   };

   const handleUpdateOriginChecked = async () => {
      setIsSubmitting(true);
      await props?.onFilter(optionsChecked?.origin?.join(","));
      setIsSubmitting(false);
   };

   const handleResetOriginChecked = () => {
      setOptionsChecked({
         origin: [],
         draft: [],
      });
   };

   useEffect(() => {
      if (options?.src?.length > 0) {
         handleUpdateOriginChecked();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [optionsChecked?.origin]);

   useEffect(() => {
      handleInputChange(inputSearch);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [inputSearch]);

   useEffect(() => {
      getDataOptions();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const ContentFilter = () => (
      <Content>
         <Input
            placeholder="Search by name"
            onChange={(e) => setInputSearch(e.target.value)}
         />
         <Divider style={{ margin: "10px 0" }} />
         {options?.filter?.length > 0 ? (
            <ul className="grid grid-cols-2 gap-4 mb-0">
               {options?.filter?.map((option) => (
                  <li
                     key={option?.value}
                     className="flex items-center cursor-pointer"
                     onClick={() => handleOptionCheck(option?.value)}
                  >
                     <Checkbox
                        checked={optionsChecked?.draft?.includes(option?.value)}
                     />
                     <span className="inline-block ml-4 truncate">
                        {option?.label}
                     </span>
                  </li>
               ))}
            </ul>
         ) : (
            <Empty />
         )}
         <Divider style={{ margin: "10px 0" }} />
         <div className="flex justify-between items-center">
            <span onClick={handleResetOriginChecked} className="cursor-pointer">
               Reset
            </span>
            <AsyncButton
               isLoading={isSubmitting}
               onClick={handleFilter}
               type="primary"
            >
               OK
            </AsyncButton>
         </div>
      </Content>
   );

   return (
      <Dropdown content={ContentFilter()} onCloseDropdown={handleCloseDropdown}>
         <Button disabled={isLoading}>
            <Spin spinning={isLoading} indicator={<LoadingOutlined />}>
               <p className="flex items-center">
                  <FilterOutlined />
                  <span className="ml-2">{props?.title}</span>
               </p>
            </Spin>
         </Button>
      </Dropdown>
   );
}

export default DropdownFilter;
