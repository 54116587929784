import { useStoreManagerActions } from "@iso/actions/storeManager.actions";
import {
   Button,
   Form,
   Input,
   InputNumber,
   message,
   Popconfirm,
   Select,
   Spin,
} from "antd";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Page from "component/Page";
import useAuthor from "hooks/useAuthor";
import styled from "styled-components";
import AsyncButton from "component/AsyncButton";

const WrapperForm = styled(Form)`
   label {
      min-width: 200px;
   }
`;

const STORE_MODE = [
   { value: "full", name: "Full" },
   { value: "not_qc", name: "Not QC" },
   { value: "only_expeditor", name: "Only Expeditor" },
];

function StoreDetail() {
   const [isLoading, setIsLoading] = useState(false);
   const [isSubmitting, setIsSubmitting] = useState({
      addStoreKVS: false,
      updateStore: false,
      deleteStore: false,
      deleteStoreKVS: false,
   });
   const [form] = Form.useForm();
   const { id } = useParams();
   const [dataStore, setDataStore] = useState(null);
   const [dataBrands, setDataBrands] = useState([]);
   const [dataRegions, setDataRegions] = useState({
      list: [],
      regions: [],
      regionSeleted: null,
   });
   const { checkRoles } = useAuthor();
   const history = useHistory();
   const storeManagerActions = useStoreManagerActions();

   const getData = () => {
      setIsLoading(true);
      Promise.all([
         storeManagerActions.getBrands(),
         storeManagerActions.getRegions(),
         storeManagerActions.getStoreDetail(id),
      ])
         .then((values) => {
            setDataBrands(values[0]);
            setDataStore(values[2]);
            const data = values[2];
            if (data?.storesKvs?.length > 0) {
               data.pos_api_url = data?.storesKvs[0]?.pos_api_url;
               data.pos_gateway_id = data?.storesKvs[0]?.pos_gateway_id;
            }
            form.setFieldsValue(data);

            const regions = values[1];
            const regionOptions = [];
            regions?.forEach((region) => {
               const index = regionOptions?.findIndex(
                  (regionOption) => regionOption?.value === region?.region
               );
               if (index >= 0) {
                  const current = regionOptions[index];
                  regionOptions[index] = {
                     ...current,
                     citys: [
                        ...current?.citys,
                        { label: region?.cityname, value: region?.city },
                     ],
                  };
               } else {
                  regionOptions.push({
                     value: region?.region,
                     citys: [{ label: region?.cityname, value: region?.city }],
                  });
               }
            });

            const regionSelected = regions?.find(
               (region) => region?.id === data?.region
            );

            form.setFieldsValue({
               region_id: regionSelected?.region,
               city: regionSelected?.city,
            });

            setDataRegions({
               list: regions,
               regions: regionOptions,
               regionSelected: regionOptions?.find(
                  (regionOption) =>
                     regionOption?.value === regionSelected?.region
               ),
            });
         })
         .finally(() => {
            setIsLoading(false);
         });
   };

   const handleChangeRegion = (value) => {
      const regionSelected = dataRegions?.regions?.find(
         (dataRegion) => dataRegion?.value === value
      );
      setDataRegions((state) => ({ ...state, regionSelected: regionSelected }));
      form.setFieldsValue({ city: null });
   };

   const handleUpdateStore = async (values) => {
      setIsSubmitting((state) => ({ ...state, updateStore: true }));
      const region = dataRegions?.list?.find(
         (dataRegion) =>
            dataRegion?.region === values?.region_id &&
            dataRegion?.city === values?.city
      );
      values.region = region?.id;
      try {
         await storeManagerActions.editStore(id, values);
         message.success("Update store success!");
      } finally {
         setIsSubmitting((state) => ({ ...state, updateStore: false }));
      }
   };

   const handleAddStoreKVS = async () => {
      setIsSubmitting((state) => ({ ...state, addStoreKVS: true }));
      try {
         await storeManagerActions.addStoreKVS(id);
         message.success("Add store to KMS system success!");
         getData();
      } finally {
         setIsSubmitting((state) => ({ ...state, addStoreKVS: false }));
      }
   };

   const handleDeleteStoreKVS = async () => {
      setIsSubmitting((state) => ({ ...state, deleteStoreKVS: true }));
      try {
         await storeManagerActions.deleteStoreKVS(id);
         message.success("Delete store from KMS system success!");
         getData();
      } finally {
         setIsSubmitting((state) => ({ ...state, deleteStoreKVS: false }));
      }
   };

   const handleDeleteStore = async () => {
      setIsSubmitting((state) => ({ ...state, deleteStore: true }));
      try {
         await storeManagerActions.deleteStore(id);
         message.success("Delete store success!");
         history.push("/store");
      } finally {
         setIsSubmitting((state) => ({ ...state, deleteStore: false }));
      }
   };

   useEffect(() => {
      getData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <Page title="Store Detail">
         <p className=" text-2xl mb-3 text-black">General Information</p>
         <Spin spinning={isLoading}>
            <div className="bg-white-0 rounded-sm shadow-sm px-6 pt-8 pb-1">
               <WrapperForm
                  name="edit-store-form"
                  onFinish={handleUpdateStore}
                  form={form}
               >
                  <div className="grid grid-cols-2 gap-20">
                     <div>
                        <Form.Item
                           name="brand_id"
                           label="Brand"
                           rules={[
                              {
                                 required: true,
                              },
                           ]}
                        >
                           <Select placeholder="Select Brand">
                              {dataBrands?.map((item, index) => (
                                 <Select.Option key={index} value={item?.id}>
                                    {item?.name || "-"}
                                 </Select.Option>
                              ))}
                           </Select>
                        </Form.Item>
                        <Form.Item name="department_id" label="Department ID">
                           <Input placeholder="Department Id" />
                        </Form.Item>
                        <Form.Item
                           name="region_id"
                           label="Region"
                           rules={[
                              {
                                 required: true,
                                 message: "Store region is required!",
                              },
                           ]}
                        >
                           <Select
                              onChange={handleChangeRegion}
                              placeholder="Select Region"
                           >
                              {dataRegions?.regions?.map((item) => (
                                 <Select.Option
                                    key={item?.value}
                                    value={item?.value}
                                 >
                                    {item?.value}
                                 </Select.Option>
                              ))}
                           </Select>
                        </Form.Item>
                        <Form.Item
                           name="city"
                           label="City"
                           rules={[
                              {
                                 required: true,
                                 message: "Store city is required!",
                              },
                           ]}
                        >
                           <Select placeholder="Select City">
                              {dataRegions?.regionSelected?.citys?.map(
                                 (item) => (
                                    <Select.Option
                                       key={item?.value}
                                       value={item?.value}
                                    >
                                       {item.label}
                                    </Select.Option>
                                 )
                              )}
                           </Select>
                        </Form.Item>
                        <Form.Item name="RM" label="RM">
                           <Input placeholder="Store manager" />
                        </Form.Item>
                        <Form.Item name="AM" label="AM">
                           <Input placeholder="Area Manager" />
                        </Form.Item>
                        <Form.Item name="SRM" label="SRM">
                           <Input placeholder="Senior Restaurant Manager" />
                        </Form.Item>
                     </div>
                     <div>
                        <Form.Item
                           name="id"
                           label="Store ID"
                           rules={[
                              {
                                 required: true,
                                 message: "Store id is required!",
                              },
                           ]}
                        >
                           <Input disabled placeholder="Store Code" />
                        </Form.Item>
                        <Form.Item
                           name="full_name"
                           label="Full Name"
                           rules={[
                              {
                                 required: true,
                                 message: "Store name is required!",
                              },
                           ]}
                        >
                           <Input placeholder="Store name" />
                        </Form.Item>{" "}
                        <Form.Item
                           name="short_name"
                           label="Short Name"
                           rules={[
                              {
                                 required: true,
                                 message: "Short name is required!",
                              },
                           ]}
                        >
                           <Input placeholder="Short name" />
                        </Form.Item>
                        <Form.Item name="address" label="Address">
                           <Input placeholder="Store address" />
                        </Form.Item>
                        <Form.Item name="phone" label="Phone">
                           <Input placeholder="Store phone" />
                        </Form.Item>
                        <Form.Item
                           name="total_seat_number"
                           label="Total seat number"
                        >
                           <InputNumber defaultValue={0} min={0} />
                        </Form.Item>{" "}
                        <Form.Item
                           name="total_table_number"
                           label="Total table number"
                        >
                           <InputNumber defaultValue={0} min={0} />
                        </Form.Item>
                     </div>
                  </div>
               </WrapperForm>
            </div>
         </Spin>
         {dataStore?.storesKvs?.length > 0 && (
            <>
               <p className=" text-2xl mb-3 mt-4 text-black">
                  System Configuration
               </p>
               <Spin spinning={isLoading}>
                  <div className="bg-white-0 rounded-sm shadow-sm px-6 pt-8 pb-1">
                     <WrapperForm
                        name="add-edit-store-form"
                        onFinish={handleUpdateStore}
                        form={form}
                     >
                        <div className="grid grid-cols-2 gap-20">
                           <div>
                              <Form.Item name="pos_api_url" label="POS api url">
                                 <Input placeholder="POS api url" />
                              </Form.Item>
                              <Form.Item name="store_mode" label="Store mode">
                                 <Select defaultValue="full">
                                    {STORE_MODE?.map((item) => (
                                       <Select.Option
                                          key={item?.value}
                                          value={item?.value}
                                       >
                                          {item?.name}
                                       </Select.Option>
                                    ))}
                                 </Select>
                              </Form.Item>
                           </div>
                           <div>
                              <Form.Item
                                 name="pos_gateway_id"
                                 label="POS gateway id"
                              >
                                 <Input placeholder="POS gateway Id" />
                              </Form.Item>
                              <Form.Item
                                 name="default_page_render"
                                 label="Default page render"
                              >
                                 <InputNumber />
                              </Form.Item>
                           </div>
                        </div>
                     </WrapperForm>
                  </div>
               </Spin>
            </>
         )}
         {dataStore &&
            (checkRoles(["store_delete"]) || checkRoles(["store_edit"])) && (
               <div className="mt-8 bg-white-0 p-8 flex justify-end shadow-sm gap-16">
                  {checkRoles(["store_delete"]) && (
                     <Popconfirm
                        title="Are you sure to delete this item?"
                        onConfirm={() => handleDeleteStore()}
                        placement="bottom"
                        okText="Yes"
                        cancelText="No"
                     >
                        <AsyncButton isLoading={isSubmitting.deleteStore}>
                           Delete
                        </AsyncButton>
                     </Popconfirm>
                  )}
                  {dataStore?.storesKvs?.length === 0 &&
                     checkRoles(["store_edit"]) && (
                        <Popconfirm
                           title="You want add this store in KMS System?"
                           onConfirm={() => handleAddStoreKVS()}
                           placement="bottom"
                           okText="Yes"
                           cancelText="No"
                           className="ml-16"
                        >
                           <AsyncButton
                              type="primary"
                              isLoading={isSubmitting.addStoreKVS}
                           >
                              Add KMS System
                           </AsyncButton>
                        </Popconfirm>
                     )}
                  {dataStore?.storesKvs?.length > 0 &&
                     checkRoles(["store_delete"]) && (
                        <Popconfirm
                           title="Are you sure to delete this store in KMS system?"
                           onConfirm={() => handleDeleteStoreKVS()}
                           placement="bottom"
                           okText="Yes"
                           cancelText="No"
                           className="ml-16"
                        >
                           <Button
                              isLoading={isSubmitting.addStoreKVS}
                              type="primary"
                           >
                              Delete KMS System
                           </Button>
                        </Popconfirm>
                     )}
                  {checkRoles(["store_edit"]) && (
                     <AsyncButton
                        isLoading={isSubmitting.updateStore}
                        type="primary"
                        onClick={() => form.submit()}
                        className="ml-16"
                     >
                        Submit
                     </AsyncButton>
                  )}
               </div>
            )}
      </Page>
   );
}

export default StoreDetail;
