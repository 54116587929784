import historyManagerApi from "api/historyManagerApi";

export { useHistoryManagerActions };

function useHistoryManagerActions() {
   const getProductHistroy = async (params) => {
      try {
         return await historyManagerApi.getProductHistory(params);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   return {
      getProductHistroy,
   };
}
