import { useAddOnManagerActions } from "@iso/actions/addOnManager.actions";
import { Form, Input, message } from "antd";
import { useEffect, useState } from "react";
import AsyncButton from "component/AsyncButton";

function EditPrice(props) {
   const [form] = Form.useForm();
   const [isLoading, setIsLoading] = useState(false);
   const addOnManagerActions = useAddOnManagerActions();

   const handleUpdatePrice = async (values) => {
      setIsLoading(true);
      try {
         await addOnManagerActions.editAddOnItem(props?.id, {
            addon_price: parseFloat(values?.addon_price),
         });
         message.success("Update Add-On item success!");
      } finally {
         props?.getData();
         setIsLoading(false);
      }
   };

   useEffect(() => {
      form.setFieldsValue({ addon_price: props?.defaultPrice });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <Form form={form} onFinish={handleUpdatePrice}>
         <Form.Item
            name="addon_price"
            rules={[
               {
                  required: true,
                  message: "Add-On price is required!",
               },
               {
                  pattern: new RegExp(
                     /^(0*[0-9][0-9]*(\[0-9]+)?|0+\[0-9]*[1-9][0-9]*)$/
                  ),
                  message: "Add-On price is invalid!",
               },
            ]}
         >
            <Input type="number" />
         </Form.Item>
         <AsyncButton isLoading={isLoading} type="primary" htmlType="submit">
            Submit
         </AsyncButton>
      </Form>
   );
}

export default EditPrice;
