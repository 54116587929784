import itemLangManagerApi from "api/itemLangManagerApi";

export { useItemLangManagerActions };

function useItemLangManagerActions() {
   const getDevices = async () => {
      try {
         return await itemLangManagerApi.getDevices();
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const getItemLangDetail = async (params) => {
      try {
         return await itemLangManagerApi.get(params);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const addItemLang = async (body) => {
      try {
         return await itemLangManagerApi.post(body);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const editItemLang = async (id, body) => {
      try {
         return await itemLangManagerApi.put(id, body);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };
   return { getDevices, addItemLang, editItemLang, getItemLangDetail };
}
