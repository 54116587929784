import { useState, useEffect } from "react";
import { Table, Button, Row, Col, Popconfirm, message, Tag } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Page from "component/Page/index";
import AddEditModal from "./AddEditModal";
import useAuthor from "hooks/useAuthor";
import AsyncButton from "component/AsyncButton";
import { useScheduleSurchargeManagerActions } from "@iso/actions/scheduleSurchargeManager.actions";
import * as moment from "moment";
import { COUNTRY_NAME } from "@iso/config/index";

const ScheduleSurchargeManager = () => {
   const [isLoading, setIsLoading] = useState(true);
   const [isSubmitting, setIsSubmitting] = useState({});
   const [modalSurchargeState, setModalSurchargeState] = useState({
      visible: false,
      data: null,
   });
   const [data, setData] = useState({
      dataSource: [],
      paging: {
         current: 1,
         total: 0,
         pageSize: 10,
      },
   });
   const { checkRoles } = useAuthor();
   const surchargeActions = useScheduleSurchargeManagerActions();

   const columns = [
      {
         title: "ID",
         dataIndex: "id",
         key: "id",
      },
      {
         title: "From Date",
         dataIndex: "date_from",
         key: "date_from",
         render: (record) => <span>{moment(record).format("DD/MM/YYYY")}</span>,
      },
      {
         title: "To Date",
         dataIndex: "date_to",
         key: "date_to",
         render: (record) => <span>{moment(record).format("DD/MM/YYYY")}</span>,
      },
      {
         title: "Country",
         dataIndex: "country",
         key: "country",
         render: (record) => <span>{COUNTRY_NAME[record]}</span>,
      },
      {
         title: "Surcharge Percent",
         dataIndex: "surcharge_percent",
         key: "surcharge_percent",
         render: (record) => (
            <Tag color="green">
               <span className="uppercase font-bold text-sl">{record} %</span>
            </Tag>
         ),
      },
      {
         title: "Content",
         dataIndex: "content",
         key: "content",
      },
      {
         title: "Description EN",
         dataIndex: "description_en",
         key: "description_en",
      },
      {
         title: "Description VI",
         dataIndex: "description_vi",
         key: "description_vi",
      },

      {
         title: "Actions",
         align: "center",
         fixed: "right",
         render: (record) => (
            <>
               <div className="flex justify-center gap-3">
                  {checkRoles(["schedule_surcharge_edit"]) && (
                     <Button
                        type="primary"
                        onClick={() =>
                           handleToggleModalSurchargeState(true, record)
                        }
                     >
                        Edit
                     </Button>
                  )}
                  {checkRoles(["schedule_surcharge_edit"]) && (
                     <Popconfirm
                        title="Are you sure to delete?"
                        onConfirm={() => handleDelete(record["id"])}
                        placement="bottom"
                        okText="Yes"
                        cancelText="No"
                     >
                        <AsyncButton
                           isLoading={isSubmitting[record["id"]] || false}
                           type="primary"
                           style={{ marginLeft: 8 }}
                        >
                           Delete
                        </AsyncButton>
                     </Popconfirm>
                  )}
               </div>
            </>
         ),
      },
   ];

   //  const getData = async () => {
   //     try {
   //        setIsLoading(true);
   //        const resGet = await surchargeActions.getList();
   //        setData({
   //           dataSource: resGet?.data,
   //        });
   //     } catch (error) {
   //        console.log(error);
   //     } finally {
   //        setIsLoading(false);
   //     }
   //  };

   const getData = async (page, limit, filters) => {
      try {
         setIsLoading(true);
         const resGet = await surchargeActions.getList({
            page: page,
            limit,
            ...filters,
         });
         setData({
            paging: {
               current: parseInt(resGet.page),
               total: parseInt(resGet.total),
               pageSize: parseInt(resGet.limit),
            },
            dataSource: resGet?.data,
         });
      } finally {
         setIsLoading(false);
      }
   };
   const handleToggleModalSurchargeState = (visible, data) => {
      setModalSurchargeState({
         visible: visible,
         data: data || null,
      });
   };

   const handleDelete = async (id) => {
      try {
         const currentIsSubmitting = { ...isSubmitting };
         currentIsSubmitting[`${id}`] = true;
         setIsSubmitting(currentIsSubmitting);
         await surchargeActions.deleteById(id);
         message.success("Delete success!!!");
         getData();
      } finally {
         const currentIsSubmitting = { ...isSubmitting };
         delete currentIsSubmitting[id];
         setIsSubmitting(currentIsSubmitting);
      }
   };

   const handleTableChange = (pagination) => {
      getData(pagination.current, pagination.pageSize);
   };

   useEffect(() => {
      getData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <Page title="Schedule Surcharge Manager">
         <div className="staff-setting-wrapper">
            <Row className="pb-8">
               <Col span={12} />
               <Col span={3} offset={9} className="text-right">
                  {checkRoles(["schedule_surcharge_add"]) && (
                     <Button
                        type="primary"
                        onClick={() => handleToggleModalSurchargeState(true)}
                     >
                        <p className="flex items-center">
                           <PlusOutlined />
                           <span className="ml-2">Add</span>
                        </p>
                     </Button>
                  )}
               </Col>
            </Row>

            <AddEditModal
               modalLangState={modalSurchargeState}
               handleToggleModalSurchargeState={handleToggleModalSurchargeState}
               getData={getData}
            />

            <Table
               columns={columns?.filter((col) => {
                  if (col.title === "Actions") {
                     return checkRoles(["schedule_surcharge_edit"]);
                  }
                  return true;
               })}
               rowKey="key"
               dataSource={data.dataSource}
               loading={isLoading}
               onChange={handleTableChange}
            />
         </div>
      </Page>
   );
};

export default ScheduleSurchargeManager;
