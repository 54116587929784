import { API_ENDPOINT } from "@iso/config/index";
import { axiosClient } from "@iso/helpers/axios.config";

const COMMON_ENDPONT = `${API_ENDPOINT}/common`;

const commonManagerApi = {
   clearCacheKMS: () => {
      return axiosClient.post(`${COMMON_ENDPONT}/clear-cache-kms`);
   },
};

export default commonManagerApi;
