import { LoadingOutlined } from "@ant-design/icons";
import { Button, Spin } from "antd";
import styled from "styled-components";

const WrapperButton = styled(Button)`
   &.only-icon {
      padding: 0;
      width: 22px;
      height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
   }
`;

const Loading = styled(Spin)`
   position: absolute;
   width: 100%;
   height: 100%;
   left: 0;
   top: -3px;
`;

function AsyncButton(props) {
   return (
      <WrapperButton
         disabled={props?.isLoading}
         {...props}
         className={`relative ${props?.onlyIcon ? "only-icon" : ""}`}
      >
         <Loading spinning={props?.isLoading} indicator={<LoadingOutlined />} />
         {props?.children}
      </WrapperButton>
   );
}

export default AsyncButton;
