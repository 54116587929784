import { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { userAtom } from "@iso/state";
import { useRecoilValue } from "recoil";
import { getMessagingToken } from "utils/firebase";
import MainLayout from "layouts/MainLayout/index";

function PrivateRoute({ component, ...rest }) {
   const user = useRecoilValue(userAtom);

   useEffect(() => {
      getTokenFCMFrirebase();
   }, []);

   const getTokenFCMFrirebase = async () => {
      await getMessagingToken();
   };

   return (
      <Route
         {...rest}
         render={() =>
            user?.isLogin ? (
               <MainLayout>{component}</MainLayout>
            ) : (
               <Redirect
                  to={{
                     pathname: "/",
                  }}
               />
            )
         }
      />
   );
}

export default PrivateRoute;
