import React from "react";
import { Spin } from "antd";

function Loading() {
   return (
      <div className=" min-h-screen min-w-full flex items-center justify-center">
         <Spin size="large" />
      </div>
   );
}

export default Loading;
