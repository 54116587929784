import { Table, Tag } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useState } from "react";
import AsyncButton from "component/AsyncButton";
import styled from "styled-components";
import * as CONFIG from "@iso/config/index";

const TableWrapper = styled(Table)`
   .ant-table-tbody > tr > td {
      padding: 16px 11px;
   }
   .ant-table-row.selected {
      background-color: #d2d2d2;
      .ant-table-cell-row-hover {
         background: #d2d2d2 !important;
      }
   }
`;

function AddOnGroupTable(props) {
   const [isSubmitting, setIsSubmitting] = useState({});

   const columns = [
      {
         title: "Name",
         dataIndex: "name",
         key: "name",
      },
      {
         title: "Type",
         dataIndex: "type",
         key: "type",
         align: "center",
         render: (record) => (
            <span className="uppercase">
               {record === CONFIG.ADD_ON_TYPE.COMBO ? (
                  <Tag color="green">{CONFIG.ADD_ON_TYPE_NAME.COMBO}</Tag>
               ) : record === CONFIG.ADD_ON_TYPE.SINGLE ? (
                  <Tag color="orange">{CONFIG.ADD_ON_TYPE_NAME.SINGLE}</Tag>
               ) : (
                  <Tag color="blue">{CONFIG.ADD_ON_TYPE_NAME.MULTIPLE}</Tag>
               )}
            </span>
         ),
      },
      {
         title: "Max",
         dataIndex: "max_selection",
         align: "center",
         key: "max_selection",
         render: (record) => (
            <span>
               {record === CONFIG.UNLIMITED_ADD_ON_SELECTION
                  ? "Unlimited"
                  : record}
            </span>
         ),
      },
      {
         align: "center",
         render: (record) => (
            <AsyncButton
               onClick={(e) => {
                  e.stopPropagation();
                  handleAddItemDefineAddOn(record["id"]);
               }}
               onlyIcon
               isLoading={isSubmitting[record["id"].toString()] || false}
               type="primary"
               style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "0 auto",
               }}
            >
               <div className="flex items-center">
                  <PlusOutlined />
               </div>
            </AsyncButton>
         ),
      },
   ];

   const BodyRow = ({ className, style, ...restProps }) => {
      return (
         <tr
            className={`${className} cursor-pointer ${
               props?.selectedId === restProps["data-row-key"] ? "selected" : ""
            }`}
            {...restProps}
         />
      );
   };

   const handleAddItemDefineAddOn = async (id) => {
      try {
         const currentIsSubmitting = { ...isSubmitting };
         currentIsSubmitting[`${id}`] = true;
         setIsSubmitting(currentIsSubmitting);
         await props?.onAddItemDefineAddOn(id);
      } finally {
         const currentIsSubmitting = { ...isSubmitting };
         delete currentIsSubmitting[id];
         setIsSubmitting(currentIsSubmitting);
      }
   };

   return (
      <TableWrapper
         loading={props?.isLoading}
         dataSource={props?.data}
         columns={columns}
         pagination={false}
         rowKey="id"
         components={{
            body: {
               row: BodyRow,
            },
         }}
         scroll={{ y: 500 }}
         onRow={(record) => {
            return {
               onClick: () =>
                  props?.setAddOnSelected({
                     id: record?.id,
                     name: record?.name,
                     rowKey: record?.id,
                  }),
            };
         }}
      />
   );
}

export default AddOnGroupTable;
