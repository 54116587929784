import productManagerApi from "api/productManagerApi";
import categoryManagerApi from "api/categoryManagerApi";

export { useProductManagerActions };

function useProductManagerActions() {
   const getFilterProperties = async () => {
      try {
         return await productManagerApi.getFilterProperties();
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const getOptionsProperty = async (params) => {
      try {
         return await productManagerApi.getOptionsProperty({
            ...params,
         });
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const getCategories = async (params) => {
      try {
         return await categoryManagerApi.get(params);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const getAllProduct = async (params) => {
      try {
         return await productManagerApi.getAll({
            ...params,
         });
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const getProducts = async (params) => {
      try {
         return await productManagerApi.gets({
            ...params,
         });
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const getProductDetail = async (id) => {
      try {
         return await productManagerApi.get(id);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const addProduct = async (data) => {
      try {
         return await productManagerApi.post(data);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const deleteProduct = async (id) => {
      try {
         return await productManagerApi.del(id);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const updateProduct = async (id, data) => {
      try {
         return await productManagerApi.put(id, data);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   //Attribute Tab
   const getItemAttribute = async (params) => {
      try {
         return await productManagerApi.getItemAttribute(params);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const updateItemAttribute = async (id, data) => {
      try {
         return await productManagerApi.putItemAttribute(id, data);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   //Customization Tab
   const getAddOnGroups = async (params) => {
      try {
         return await productManagerApi.getAddOnGroups(params);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const getItemDefineAddOn = async (params) => {
      try {
         return await productManagerApi.getItemDefineAddOn(params);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const addItemDefineAddOn = async (data) => {
      try {
         return await productManagerApi.postItemDefineAddOn(data);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const updateSortingItemsDefineAddOn = async (body) => {
      try {
         return await productManagerApi.postSortingItemsDefintAddOn(body);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const deleteItemDefineAddOn = async (id) => {
      try {
         return await productManagerApi.delItemDefineAddOn(id);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   //Availability Tab
   const getStationsAssignItem = async (params) => {
      try {
         return await productManagerApi.getStationsAssignItem(params);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const updateStationsAssignItem = async (id, data) => {
      try {
         return await productManagerApi.putStationsAssignItem(id, data);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   return {
      getFilterProperties,
      getOptionsProperty,

      getCategories,
      getAllProduct,
      getProducts,
      getProductDetail,
      addProduct,
      deleteProduct,
      updateProduct,

      getItemAttribute,
      updateItemAttribute,

      getAddOnGroups,
      getItemDefineAddOn,
      addItemDefineAddOn,
      updateSortingItemsDefineAddOn,
      deleteItemDefineAddOn,

      getStationsAssignItem,
      updateStationsAssignItem,
   };
}
