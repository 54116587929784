import { useState, useEffect } from "react";
import { Table, Button, Row, Col, Popconfirm, message } from "antd";
import { useLangManagerActions } from "@iso/actions/langManager.actions";
import { PlusOutlined } from "@ant-design/icons";
import Page from "component/Page/index";
import AddEditLangModal from "./AddEditLang";
import useAuthor from "hooks/useAuthor";
import AsyncButton from "component/AsyncButton";

const LangManager = () => {
   const [isLoading, setIsLoading] = useState(true);
   const [isSubmitting, setIsSubmitting] = useState({});
   const [modalLangState, setModalLangState] = useState({
      visible: false,
      data: null,
   });
   const [data, setData] = useState({
      dataSource: [],
      paging: {
         current: 1,
         total: 0,
         pageSize: 10,
      },
   });
   const { checkRoles } = useAuthor();
   const langManagerActions = useLangManagerActions();

   const columns = [
      {
         title: "Lang ID",
         dataIndex: "id",
         key: "id",
      },
      {
         title: "Name",
         dataIndex: "name",
         key: "name",
      },
      {
         title: "Currency Code",
         dataIndex: "currency_code",
         key: "currency_code",
      },
      {
         title: "Currency Conversion Rate",
         dataIndex: "currency_conversion_rate",
         key: "currency_conversion_rate",
      },
      {
         title: "Iso Code",
         dataIndex: "iso_code",
         key: "iso_code",
      },
      {
         title: "Actions",
         align: "center",
         fixed: "right",
         render: (record) => (
            <>
               <div className="flex justify-center gap-3">
                  {checkRoles(["lang_edit"]) && (
                     <Button
                        type="primary"
                        onClick={() => handleToggleModalLangState(true, record)}
                     >
                        Edit
                     </Button>
                  )}
                  {checkRoles(["lang_delete"]) && (
                     <Popconfirm
                        title="Are you sure to delete this language?"
                        onConfirm={() => handleDeleteLang(record["id"])}
                        placement="bottom"
                        okText="Yes"
                        cancelText="No"
                     >
                        <AsyncButton
                           isLoading={isSubmitting[record["id"]] || false}
                           type="primary"
                           style={{ marginLeft: 8 }}
                        >
                           Delete
                        </AsyncButton>
                     </Popconfirm>
                  )}
               </div>
            </>
         ),
      },
   ];

   const getData = async () => {
      try {
         setIsLoading(true);
         const resGet = await langManagerActions.getLangList();
         setData({
            dataSource: resGet,
         });
      } catch (error) {
         console.log(error);
      } finally {
         setIsLoading(false);
      }
   };

   const handleToggleModalLangState = (visible, data) => {
      setModalLangState({
         visible: visible,
         data: data || null,
      });
   };

   const handleDeleteLang = async (id) => {
      try {
         const currentIsSubmitting = { ...isSubmitting };
         currentIsSubmitting[`${id}`] = true;
         setIsSubmitting(currentIsSubmitting);
         await langManagerActions.deleteLang(id);
         message.success("Delete lang success!!!");
         getData();
      } finally {
         const currentIsSubmitting = { ...isSubmitting };
         delete currentIsSubmitting[id];
         setIsSubmitting(currentIsSubmitting);
      }
   };

   useEffect(() => {
      getData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <Page title="Lang Management">
         <div className="staff-setting-wrapper">
            <Row className="pb-8">
               <Col span={12} />
               <Col span={3} offset={9} className="text-right">
                  {checkRoles(["lang_add"]) && (
                     <Button
                        type="primary"
                        onClick={() => handleToggleModalLangState(true)}
                     >
                        <p className="flex items-center">
                           <PlusOutlined />
                           <span className="ml-2">Add Lang</span>
                        </p>
                     </Button>
                  )}
               </Col>
            </Row>

            <AddEditLangModal
               modalLangState={modalLangState}
               handleToggleModalLangState={handleToggleModalLangState}
               getData={getData}
            />

            <Table
               columns={columns?.filter((col) => {
                  if (col.title === "Actions") {
                     return (
                        checkRoles(["lang_edit"]) || checkRoles(["lang_delete"])
                     );
                  }
                  return true;
               })}
               rowKey="key"
               dataSource={data.dataSource}
               loading={isLoading}
               pagination={false}
            />
         </div>
      </Page>
   );
};

export default LangManager;
