import { useMemo } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";

function useRouter() {
   const history = useHistory();
   const location = useLocation();
   const params = useParams();

   const goToPage = (path) => {
      history.push(path);
   };

   const replace = (path) => {
      history.replace(path);
   };

   const goBack = () => {
      history.goBack();
   };

   const goForward = () => {
      history.goForward();
   };

   const searchParams = useMemo(
      () => new URLSearchParams(location.search),
      [location.search]
   );

   const getSearchParam = (key) => {
      return searchParams.get(key);
   };

   const setSearchParam = (key, value) => {
      searchParams.set(key, value);
      const newSearch = searchParams.toString();
      const newLocation = {
         ...location,
         search: newSearch ? `?${newSearch}` : "",
      };
      history.push(newLocation);
   };

   const removeSearchParam = (key) => {
      searchParams.delete(key);
      const newSearch = searchParams.toString();
      const newLocation = {
         ...location,
         search: newSearch ? `?${newSearch}` : "",
      };
      history.push(newLocation);
   };

   return {
      params,
      pathname: location.pathname,
      search: location.search,
      hash: location.hash,
      goToPage,
      replace,
      goBack,
      goForward,
      searchParams,
      getSearchParam,
      setSearchParam,
      removeSearchParam,
   };
}

export default useRouter;
