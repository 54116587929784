import { useProductManagerActions } from "@iso/actions/productManager.actions";
import { Form, Input, message, Select, Spin } from "antd";
import { useEffect, useState } from "react";
import styled from "styled-components";
import ItemDefineAddOnTable from "./ItemDefineAddOnTable";
import AddOnGroupTable from "./AddOnGroupTable";
import ItemSelectionTable from "./ItemSelectionTable";

const WrapperForm = styled(Form)`
   .ant-form-item-label {
      min-width: 200px;
      text-align: left;
   }
`;

const { Option } = Select;

function Customization(props) {
   const { product, currentTab } = props;
   const productManagerActions = useProductManagerActions();
   const [form] = Form.useForm();
   const [dataCategory, setDataCategory] = useState({
      list: [],
      selected: null,
   });
   const [isLoading, setIsLoading] = useState({
      getDataProduct: false,
      getDataStation: false,
      getDataItemDefineAddOn: false,
      getDataAddOnGroups: false,
   });
   const [dataAddOnGroups, setDataAddOnGroups] = useState();
   const [dataItemDefineAddOn, setDataItemDefineAddOn] = useState([]);
   const [addOnSelected, setAddOnSelected] = useState(null);

   const getDataAddOnGroups = async () => {
      try {
         setIsLoading((state) => ({ ...state, getDataAddOnGroups: true }));
         const resGet = await productManagerActions.getAddOnGroups({
            item_id: product?.id,
         });
         setDataAddOnGroups(resGet);
      } finally {
         setIsLoading((state) => ({ ...state, getDataAddOnGroups: false }));
      }
   };

   const getDataItemDefineAddOn = async (page, limit) => {
      try {
         setIsLoading((state) => ({ ...state, getDataItemDefineAddOn: true }));
         const resGet = await productManagerActions.getItemDefineAddOn({
            page,
            limit,
            item_id: product?.id,
         });
         setDataItemDefineAddOn(resGet?.data);
      } finally {
         setIsLoading((state) => ({ ...state, getDataItemDefineAddOn: false }));
      }
   };

   const getDataProduct = async () => {
      if (product?.id) {
         setIsLoading((state) => ({ ...state, getDataProduct: true }));
         try {
            const resGet = await productManagerActions.getProductDetail(
               product?.id
            );
            resGet.productId = resGet?.id;
            resGet.category_id = resGet?.category?.id;
            resGet.class_id = resGet?.class?.id;
            form.setFieldsValue(resGet);

            const cate = dataCategory?.list?.find((category) => {
               return category?.id === resGet?.category?.id;
            });
            setDataCategory((state) => ({ ...state, selected: cate }));
         } finally {
            setIsLoading((state) => ({ ...state, getDataProduct: false }));
         }
      } else {
         form.resetFields();
      }
   };

   const handleAddItemDefineAddOn = async (addOnId) => {
      try {
         await productManagerActions.addItemDefineAddOn({
            item_id: product?.id,
            addon_id: addOnId,
         });
         message.success("Add item define Add-On success!");
         getDataAddOnGroups();
         getDataItemDefineAddOn();
      } catch (error) {}
   };

   const handleUpdateSortingItemDefineAddOn = async (itemIds) => {
      setIsLoading((state) => ({ ...state, getDataItemDefineAddOn: true }));
      try {
         await productManagerActions.updateSortingItemsDefineAddOn({
            item_ids: itemIds,
         });
         message.success("Update sorting item define Add-On success!");
         setIsLoading((state) => ({ ...state, getDataItemDefineAddOn: false }));
      } catch {
         getDataItemDefineAddOn();
      }
   };

   const handleDeleteItemDefineAddOn = async (id) => {
      try {
         await productManagerActions.deleteItemDefineAddOn(id);
         message.success("Delete item define Add-On success!");
         getDataAddOnGroups();
         getDataItemDefineAddOn();
      } catch (error) {}
   };

   useEffect(() => {
      if (product?.id) {
         getDataProduct();
         getDataAddOnGroups();
         getDataItemDefineAddOn();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [product, currentTab]);

   return (
      <>
         <Spin spinning={isLoading?.getDataProduct}>
            <div className="p-8 mt-8 bg-white-0 rounded-sm shadow-sm">
               <WrapperForm name="product-form" form={form}>
                  <div className="grid grid-cols-2 gap-20">
                     <div>
                        <Form.Item name="productId" label="Product Id">
                           <Input disabled placeholder="Product Id" />
                        </Form.Item>
                        <Form.Item name="category_id" label="Category">
                           <Select disabled placeholder="Category">
                              {dataCategory?.list?.map((category) => (
                                 <Option
                                    key={category?.id}
                                    value={category?.id}
                                 >
                                    {category?.name}
                                 </Option>
                              ))}
                           </Select>
                        </Form.Item>
                     </div>
                     <div>
                        <Form.Item name="name" label="Name">
                           <Input disabled placeholder="Name" />
                        </Form.Item>
                        <Form.Item name="class_id" label="Class">
                           <Select placeholder="Class" disabled>
                              {dataCategory.selected?.classCate?.map((cl) => (
                                 <Option key={cl?.id} value={cl?.id}>
                                    {cl?.name}
                                 </Option>
                              ))}
                           </Select>
                        </Form.Item>
                     </div>
                  </div>
               </WrapperForm>
            </div>
         </Spin>
         <div className="grid grid-cols-10 gap-4 mt-12">
            <div className="col-span-3">
               <p className="text-1xl mb-3 mt-4 text-black">Add-on Groups</p>
               <AddOnGroupTable
                  selectedId={addOnSelected?.rowKey}
                  data={dataAddOnGroups}
                  isLoading={isLoading?.getDataAddOnGroups}
                  onAddItemDefineAddOn={handleAddItemDefineAddOn}
                  setAddOnSelected={setAddOnSelected}
               />
            </div>
            <div className="col-span-4">
               <p className="text-1xl mb-3 mt-4 text-black">
                  Define Add-on Groups
               </p>
               <ItemDefineAddOnTable
                  selectedId={addOnSelected?.rowKey}
                  setDataItemDefineAddOn={setDataItemDefineAddOn}
                  dataItemDefineAddOn={dataItemDefineAddOn}
                  isLoading={isLoading?.getDataItemDefineAddOn}
                  onDeleteItemDefineAddOn={handleDeleteItemDefineAddOn}
                  onUpdateSorting={handleUpdateSortingItemDefineAddOn}
                  setAddOnSelected={setAddOnSelected}
               />
            </div>
            <div className="col-span-3">
               <ItemSelectionTable addOn={addOnSelected} />
            </div>
         </div>
      </>
   );
}

export default Customization;
