import useModal from "hooks/useModal";
import { useEffect, useState } from "react";
import { useBannerManagerActions } from "@iso/actions/bannerManager.actions";
import BannerChannelListing from "./BannerChannelListing";
import BannerChannelForm from "./BannerChannelForm";
import { message } from "antd";

function BannerChannel() {
   const {
      visible: visiblePopupBanner,
      toggle: togglePopupBanner,
      hide: hidePopupBanner,
   } = useModal();
   const [data, setData] = useState({
      dataSource: [],
      paging: {
         current: 1,
         total: 0,
         pageSize: 10,
         store_channel_id: "",
         store_id: "",
      },
   });
   const [bannerDetail, setBannerDetail] = useState({});
   const [loading, setLoading] = useState({
      isLoadingForm: false,
      isLoadingList: false,
      isLoadingDelete: "",
   });
   const bannerManagerActions = useBannerManagerActions();

   useEffect(() => {
      handleGetStoreChannel();
   }, []);

   const handleGetStoreChannel = async () => {
      const res = await bannerManagerActions.getStoreChannel();
      if (Object.keys(res).length) {
         const store = res[Object.keys(res)[0]];
         const store_channel_id =
            store.channels[Object.keys(store.channels)[0]]?.store_channel_id;
         handleGetData({
            ...data?.paging,
            store_id: store?.store_id,
            store_channel_id: store_channel_id,
         });
      }
   };

   const handleGetData = async (params) => {
      setLoading({ ...loading, isLoadingList: true });

      try {
         if (params?.store_channel_id) {
            const resGet = await bannerManagerActions.getBannersChannel({
               storeChannelId: params?.store_channel_id,
            });
            setData({
               dataSource: resGet,
               paging: {
                  ...data?.paging,
                  ...params,
                  current: 1,
                  total: 0,
               },
            });
         } else {
            setData({
               dataSource: [],
               paging: {
                  ...params,
                  current: 1,
                  total: 0,
               },
            });
         }

         setLoading({ ...loading, isLoadingList: false });
      } catch (err) {
         setLoading({ ...loading, isLoadingList: false });
      }
   };

   const handleSubmitForm = async (values) => {
      try {
         setLoading({ ...loading, isLoadingForm: true });
         if (bannerDetail?.id) {
            await bannerManagerActions.updateBannerChannel(
               bannerDetail?.id,
               values
            );
         } else {
            await bannerManagerActions.createBannerChannel(values);
         }
         handleGetData({
            ...data?.paging,
         });
         hidePopupBanner();
         message.success(
            `${bannerDetail?.id ? "Edit" : "Add"} banner success!`
         );
         setLoading({ ...loading, isLoadingForm: false });
      } catch (err) {
         setLoading({ ...loading, isLoadingForm: false });
         message.error(`${bannerDetail?.id ? "Edit" : "Add"} banner failed!`);
      }
   };

   const handleDelete = async (id) => {
      setLoading({ ...loading, isLoadingDelete: id });
      try {
         await bannerManagerActions.deleteBannerChannel(id);
         handleGetData({
            ...data?.paging,
         });
         message.success(`Delete banner success!`);
         setLoading({ ...loading, isLoadingDelete: "" });
      } catch (err) {
         message.error(`Delete banner failed!`);
         setLoading({ ...loading, isLoadingDelete: "" });
      }
   };

   const handleShowPopupBanner = (data) => {
      setBannerDetail(data);
      togglePopupBanner();
   };

   const handleUpdateSorting = async (newData) => {
      try {
         setData({ ...data, dataSource: newData });
         await bannerManagerActions.updateSortingBannerChannel({
            items: newData?.map((item, index) => ({
               id: item?.id,
               sorting: index + 1,
            })),
         });
         message.success(`Update sorting banner success!`);
      } catch (err) {
         message.error(`Update sorting banner success!`);
      }
   };

   return (
      <section>
         <BannerChannelListing
            data={data}
            onDelete={handleDelete}
            onActionForm={handleShowPopupBanner}
            isLoadingList={loading.isLoadingList}
            isLoadingDelete={loading.isLoadingDelete}
            onFilter={handleGetData}
            onSetData={(newData) => handleUpdateSorting(newData)}
         />

         <BannerChannelForm
            data={bannerDetail}
            visible={visiblePopupBanner}
            onClose={togglePopupBanner}
            onSubmitForm={handleSubmitForm}
            isLoadingForm={loading.isLoadingForm}
            onFilter={handleGetData}
         />
      </section>
   );
}

export default BannerChannel;
