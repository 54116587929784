import { Table, Button, Popconfirm, Tag, Select, Form } from "antd";
import { PlusOutlined, MenuOutlined } from "@ant-design/icons";
import { useRecoilValue } from "recoil";
import { userAtom } from "@iso/state/user";
import AsyncButton from "component/AsyncButton";
import * as moment from "moment";
import {
   SortableElement,
   SortableHandle,
   SortableContainer,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { bannersAtom } from "@iso/state/banner";
import { SearchOutlined } from "@ant-design/icons";
import { useEffect } from "react";

const BannerChannelListing = (props) => {
   const {
      data,
      onActionForm,
      onFilter,
      onDelete,
      isLoadingList,
      isLoadingDelete,
      onSetData,
   } = props;

   const [form] = Form.useForm();
   const user = useRecoilValue(userAtom);
   const bannerState = useRecoilValue(bannersAtom);

   useEffect(() => {
      form.setFieldsValue({
         store_id: data?.paging?.store_id,
         store_channel_id: data?.paging?.store_channel_id,
      });
   }, [data.paging]);

   const checkRoles = (roles) => {
      const userRoles = user?.roles || [];
      return roles.every((role) => userRoles.includes(role));
   };

   const handleTableChange = (pagination) => {
      onFilter({
         current: pagination.current,
         pageSize: pagination.pageSize,
      });
   };

   const onSortEnd = ({ oldIndex, newIndex }) => {
      if (oldIndex !== newIndex) {
         const newData = arrayMoveImmutable(
            data?.dataSource?.slice(),
            oldIndex,
            newIndex
         ).filter((el) => !!el);
         onSetData(newData);
      }
   };

   const DragHandle = SortableHandle(() => (
      <MenuOutlined
         style={{
            cursor: "grab",
            color: "#999",
         }}
      />
   ));

   const columns = [
      {
         title: "Sort",
         dataIndex: "id",
         className: "drag-visible",
         render: () => <DragHandle />,
      },
      {
         title: "Banner",
         dataIndex: "banner",
         key: "banner",
         render: (value) => (
            <div className="flex items-center gap-2">
               <img width={50} height={50} src={value?.image_url} alt="" />
               <span>{value?.name}</span>
            </div>
         ),
      },
      {
         title: "Active From",
         dataIndex: "active_from",
         key: "active_from",
         render: (value) => (
            <span className="">{moment(value).format("DD/MM/YYYY")}</span>
         ),
      },
      {
         title: "Active To",
         dataIndex: "active_to",
         key: "active_to",
         render: (value) => (
            <span className="">{moment(value).format("DD/MM/YYYY")}</span>
         ),
      },
      {
         title: "Active",
         key: "is_active",
         render: (_, record) => (
            <Tag
               color={
                  record?.is_active && record?.banner?.is_active
                     ? "green-inverse"
                     : "red-inverse"
               }
            >
               {record?.is_active && record?.banner?.is_active
                  ? "Active"
                  : "Inactive"}
            </Tag>
         ),
      },
      {
         align: "center",
         fixed: "right",
         render: (record) => (
            <div className="flex gap-3 justify-center">
               {checkRoles(["store_edit"]) && (
                  <>
                     <Button
                        onClick={() => onActionForm(record)}
                        type="primary"
                     >
                        Edit
                     </Button>
                     <Popconfirm
                        title="Are you sure to delete this item?"
                        onConfirm={() => onDelete(record["id"])}
                        placement="bottom"
                        okText="Yes"
                        cancelText="No"
                     >
                        <AsyncButton
                           isLoading={isLoadingDelete === record["id"]}
                           type="primary"
                           style={{ marginLeft: 8 }}
                        >
                           Delete
                        </AsyncButton>
                     </Popconfirm>
                  </>
               )}
            </div>
         ),
      },
   ];

   const SortableItem = SortableElement((props) => <tr {...props} />);
   const SortableBody = SortableContainer((props) => <tbody {...props} />);

   const DraggableContainer = (props) => (
      <SortableBody
         useDragHandle
         disableAutoscroll
         helperClass="row-dragging"
         onSortEnd={onSortEnd}
         {...props}
      />
   );

   const DraggableBodyRow = ({ className, style, ...restProps }) => {
      const index = data?.dataSource?.findIndex(
         (x) => x?.id === restProps["data-row-key"]
      );
      return <SortableItem index={index} {...restProps} />;
   };

   const handleFilter = (values) => {
      onFilter({
         store_id: values.store_id,
         store_channel_id: values.store_channel_id,
      });
   };

   return (
      <div>
         <div className="flex items-start justify-between mb-8">
            <div className="flex gap-6">
               <Form className="flex gap-6" form={form} onFinish={handleFilter}>
                  <Form.Item style={{ marginBottom: 0 }} name={"store_id"}>
                     <Select
                        style={{ width: "200px" }}
                        placeholder="Select store"
                        showSearch
                        filterOption={(input, option) =>
                           option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(value) => {
                           onFilter({
                              store_id: value,
                              store_channel_id:
                                 bannerState?.store_channels[value]?.channels[
                                    Object.keys(
                                       bannerState?.store_channels[value]
                                          ?.channels
                                    )[0]
                                 ]?.store_channel_id,
                           });
                        }}
                     >
                        {Object.keys(bannerState?.store_channels)?.map(
                           (key) => (
                              <Select.Option value={key}>
                                 {bannerState?.store_channels[key]?.store_name}
                              </Select.Option>
                           )
                        )}
                     </Select>
                  </Form.Item>
                  <Form.Item
                     style={{ marginBottom: 0 }}
                     name={"store_channel_id"}
                  >
                     <Select
                        style={{ width: "200px" }}
                        placeholder="Select channel"
                     >
                        {data?.paging?.store_id
                           ? Object.keys(
                                bannerState?.store_channels[
                                   data?.paging?.store_id
                                ]?.channels
                             )?.map((key) => (
                                <Select.Option
                                   value={
                                      bannerState?.store_channels[
                                         data?.paging?.store_id
                                      ]?.channels[key]?.store_channel_id
                                   }
                                >
                                   {
                                      bannerState?.store_channels[
                                         data?.paging?.store_id
                                      ]?.channels[key]?.name
                                   }
                                </Select.Option>
                             ))
                           : ""}
                     </Select>
                  </Form.Item>
                  <Form.Item style={{ marginBottom: 0 }}>
                     <Button type="primary" htmlType="submit">
                        <p className="flex items-center">
                           <SearchOutlined />
                           <span className="ml-2">Submit</span>
                        </p>
                     </Button>
                  </Form.Item>
               </Form>
            </div>
            {checkRoles(["store_add"]) && (
               <Button type="primary" onClick={() => onActionForm({})}>
                  <p className="flex items-center">
                     <PlusOutlined />
                     <span className="ml-2">Add Item</span>
                  </p>
               </Button>
            )}
         </div>
         <Table
            columns={columns}
            rowKey="id"
            dataSource={data?.dataSource}
            onChange={handleTableChange}
            pagination={{
               current: data?.paging.current,
               total: data?.paging?.total,
               pageSize: data?.paging?.pageSize,
            }}
            components={{
               body: {
                  wrapper: DraggableContainer,
                  row: DraggableBodyRow,
               },
            }}
            loading={isLoadingList}
         />
      </div>
   );
};

export default BannerChannelListing;
