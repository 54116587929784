import bannerManagerApi from "api/bannerManagerApi";
import { bannersAtom } from "../state";
import { useRecoilState } from "recoil";

export { useBannerManagerActions };

function useBannerManagerActions() {
   const [bannerState, setBannerState] = useRecoilState(bannersAtom);

   const getStoreChannel = async (params) => {
      try {
         if (!Object.keys(bannerState?.store_channels).length) {
            const res = await bannerManagerApi.getStoreChannel(params);
            const mappedData = {};

            res.forEach((item) => {
               mappedData[item.store_id] = {
                  store_id: item?.store_id,
                  store_name: item?.stores?.full_name,
               };

               if (!mappedData[item.store_id].channels) {
                  mappedData[item.store_id].channels = {};
               }

               mappedData[item.store_id].channels[
                  item?.storeSaleChannels?.name
               ] = {
                  id: item?.storeSaleChannels?.id,
                  name: item?.storeSaleChannels?.name,
                  store_channel_id: item?.id,
               };
            });

            setBannerState({ store_channels: mappedData });

            return mappedData;
         }

         return bannerState?.store_channels;
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const getBanners = async (params) => {
      try {
         return await bannerManagerApi.getBanners(params);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const createBanner = async (body) => {
      try {
         return await bannerManagerApi.createBanner(body);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const updateBanner = async (id, body) => {
      try {
         return await bannerManagerApi.updateBanner(id, body);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const deleteBanner = async (id, body) => {
      try {
         return await bannerManagerApi.deleteBanner(id, body);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const getBannersChannel = async (params) => {
      try {
         return await bannerManagerApi.getBannersChannel(params);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const createBannerChannel = async (body) => {
      try {
         return await bannerManagerApi.createBannerChannel(body);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const updateBannerChannel = async (id, body) => {
      try {
         return await bannerManagerApi.updateBannerChannel(id, body);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const updateSortingBannerChannel = async (body) => {
      try {
         return await bannerManagerApi.updateSortingBannerChannel(body);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const deleteBannerChannel = async (id) => {
      try {
         return await bannerManagerApi.deleteBannerChannel(id);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const uploadImage = async (file) => {
      try {
         const formData = new FormData();
         formData.append("upload", file);
         const response = await bannerManagerApi.uploadImage(formData);
         return response;
      } catch (err) {
         throw err;
      }
   };

   return {
      getBanners,
      getStoreChannel,
      createBanner,
      updateBanner,
      deleteBanner,
      getBannersChannel,
      createBannerChannel,
      updateBannerChannel,
      updateSortingBannerChannel,
      deleteBannerChannel,
      uploadImage,
   };
}
