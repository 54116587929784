import attributeManagerApi from "api/attributeManagerApi";
import commonManagerApi from "api/commonManagerApi";

export { useCommonManagerActions };

function useCommonManagerActions() {
   const clearCacheKMS = async () => {
      try {
         return await commonManagerApi.clearCacheKMS();
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   return {
      clearCacheKMS,
   };
}
