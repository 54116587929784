import { atom } from "recoil";

const productAtom = atom({
   key: "product",
   default: {
      list: [],
      paging: {
         current: 1,
         pageSize: 8,
         total: 0,
      },
      search: null,
      isLoading: false,
   },
});

export { productAtom };
