import { useState, useEffect } from "react";
import { Form, Input, message, Modal, Select } from "antd";
import { useAttributeManagerActions } from "@iso/actions/attributeManager.actions";
import styled from "styled-components";
import AsyncButton from "component/AsyncButton";

const WrapperForm = styled(Form)`
   label {
      min-width: 200px;
   }
`;

function AddEditAttributeModal(props) {
   const [isLoading, setIsLoading] = useState(false);
   const [form] = Form.useForm();
   const attributeActions = useAttributeManagerActions();

   const getData = async (data) => {
      setIsLoading(true);
      try {
         if (data) {
            form.setFieldsValue(data);
         }
      } catch (error) {
         console.log(error);
      } finally {
         setIsLoading(false);
      }
   };

   const onFinish = async (values) => {
      setIsLoading(true);
      try {
         props.modalAttributeState.data
            ? await attributeActions.editAttribute(
                 props.modalAttributeState.data?.id,
                 values
              )
            : await attributeActions.addAttribute(values);
         message.success(
            `${
               props.modalAttributeState.data?.id ? "Edit" : "Add"
            } attribute success!`
         );
         !props.modalAttributeState.data?.id && form.resetFields();
         props.handleToggleModalAttributeState(false);
         props.getData();
      } catch (error) {
         console.log(error);
      } finally {
         setIsLoading(false);
      }
   };

   useEffect(() => {
      props.modalAttributeState.data && getData(props.modalAttributeState.data);
      if (props.modalAttributeState.visible === false) {
         form.resetFields();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [props.modalAttributeState.visible]);

   return (
      <Modal
         title={`${
            props.modalAttributeState.data?.id ? "Edit" : "Add"
         } Attribute`}
         visible={props.modalAttributeState.visible}
         footer={null}
         onCancel={() => props.handleToggleModalAttributeState(false)}
      >
         <WrapperForm
            name="add-edit-attribute-form"
            onFinish={onFinish}
            form={form}
         >
            <Form.Item
               name="attribute_type_id"
               label="Attribute Type"
               rules={[
                  {
                     required: true,
                     message: "Attribute type is required!",
                  },
               ]}
            >
               <Select placeholder="Select Attribute Type">
                  {props?.dataAttributeType?.map((attributeType) => (
                     <Select.Option
                        key={attributeType?.id}
                        value={attributeType?.id}
                     >
                        {attributeType?.name}
                     </Select.Option>
                  ))}
               </Select>
            </Form.Item>

            <Form.Item
               name="name"
               label="Name"
               rules={[
                  {
                     required: true,
                     message: "Attribute name is required!",
                  },
               ]}
            >
               <Input placeholder="Attribute name" />
            </Form.Item>

            <Form.Item
               name="value"
               label="Value"
               rules={[
                  {
                     required: true,
                     message: "Attribute value is required!",
                  },
               ]}
            >
               <Input placeholder="Attribute value" />
            </Form.Item>

            <Form.Item className="text-center" style={{ marginBottom: 0 }}>
               <AsyncButton
                  isLoading={isLoading}
                  type="primary"
                  htmlType="submit"
               >
                  Submit
               </AsyncButton>
            </Form.Item>
         </WrapperForm>
      </Modal>
   );
}

export default AddEditAttributeModal;
