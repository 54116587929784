import { Checkbox, Table } from "antd";
import { useEffect, useState } from "react";
import { useAddOnManagerActions } from "@iso/actions/addOnManager.actions";
import styled from "styled-components";

const TableWrapper = styled(Table)`
   .ant-table-tbody > tr > td {
      padding: 16px 11px;
   }
`;

function ItemSelectionTable(props) {
   const [isLoading, setIsLoading] = useState(false);
   const [data, setData] = useState({
      dataSource: [],
      paging: {
         current: 1,
         total: 0,
         pageSize: 7,
      },
   });
   const addOnManagerActions = useAddOnManagerActions();

   const columns = [
      {
         title: "Name",
         dataIndex: ["productItems", "name"],
         key: "name",
      },
      {
         title: "Price",
         dataIndex: "addon_price",
         key: "name",
      },
      {
         title: "Is Fixed",
         key: "is_fixed",
         align: "center",
         render: (record) => <Checkbox checked={record?.is_fixed} />,
      },
   ];

   const getDataAddOnItems = async (page, limit) => {
      setIsLoading(true);
      try {
         const resGet = await addOnManagerActions.getAddOnItemList({
            page,
            limit,
            addon_id: props?.addOn?.id,
         });
         setData((state) => ({
            ...state,
            paging: {
               current: parseInt(resGet.page),
               total: parseInt(resGet.total),
               pageSize: parseInt(resGet.limit),
            },
            dataSource: resGet?.data,
         }));
      } finally {
         setIsLoading(false);
      }
   };

   const handleTableChange = (pagination) => {
      getDataAddOnItems(pagination.current, pagination.pageSize);
   };

   useEffect(() => {
      if (props?.addOn) {
         getDataAddOnItems(1, data?.paging?.pageSize);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [props?.addOn]);

   return (
      <>
         {props?.addOn?.name ? (
            <p className="text-1xl mb-3 mt-4 text-black">
               {props?.addOn?.name}
            </p>
         ) : (
            <p className="text-1xl mb-3 mt-4 text-black opacity-0">Name</p>
         )}
         <TableWrapper
            loading={isLoading}
            dataSource={data?.dataSource}
            columns={columns}
            pagination={{
               current: data?.paging.current,
               total: data?.paging.total,
               pageSize: data?.paging.pageSize,
               position: ["bottomCenter"],
            }}
            onChange={handleTableChange}
         />
      </>
   );
}

export default ItemSelectionTable;
