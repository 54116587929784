import { useState, useEffect } from "react";
import { Form, Input, message, Modal } from "antd";
import { useAttributeManagerActions } from "@iso/actions/attributeManager.actions";
import styled from "styled-components";
import AsyncButton from "component/AsyncButton";

const WrapperForm = styled(Form)`
   label {
      min-width: 200px;
   }
`;

function AddEditAttributeTypeModal(props) {
   const [isLoading, setIsLoading] = useState(false);
   const [form] = Form.useForm();
   const attributeActions = useAttributeManagerActions();

   const getData = async (data) => {
      setIsLoading(true);
      try {
         if (data) {
            form.setFieldsValue(data);
         }
      } catch (error) {
         console.log(error);
      } finally {
         setIsLoading(false);
      }
   };

   const onFinish = async (values) => {
      setIsLoading(true);
      try {
         props.modalAttributeTypeState.data
            ? await attributeActions.editAttributeType(
                 props.modalAttributeTypeState.data?.id,
                 values
              )
            : await attributeActions.addAttributeType(values);
         message.success(
            `${
               props.modalAttributeTypeState.data?.id ? "Edit" : "Add"
            } attribute type success!`
         );
         !props.modalAttributeTypeState.data?.id && form.resetFields();
         props.handleToggleModalAttributeTypeState(false);
         props.getData();
      } catch (error) {
         console.log(error);
      } finally {
         setIsLoading(false);
      }
   };

   useEffect(() => {
      props.modalAttributeTypeState.data &&
         getData(props.modalAttributeTypeState.data);
      if (props.modalAttributeTypeState.visible === false) {
         form.resetFields();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [props.modalAttributeTypeState.visible]);

   return (
      <Modal
         title={`${
            props.modalAttributeTypeState.data?.id ? "Edit" : "Add"
         } Attribute Type`}
         visible={props.modalAttributeTypeState.visible}
         footer={null}
         onCancel={() => props.handleToggleModalAttributeTypeState(false)}
      >
         <WrapperForm
            name="add-edit-attribute-type-form"
            onFinish={onFinish}
            form={form}
         >
            <Form.Item
               name="name"
               label="Name"
               rules={[
                  {
                     required: true,
                     message: "Attribute type name is required!",
                  },
               ]}
            >
               <Input placeholder="Attribute Type name" />
            </Form.Item>

            <Form.Item className="text-center" style={{ marginBottom: 0 }}>
               <AsyncButton
                  isLoading={isLoading}
                  type="primary"
                  htmlType="submit"
               >
                  Submit
               </AsyncButton>
            </Form.Item>
         </WrapperForm>
      </Modal>
   );
}

export default AddEditAttributeTypeModal;
