import { useAddOnManagerActions } from "@iso/actions/addOnManager.actions";
import {
   Spin,
   Form,
   Input,
   Select,
   Popconfirm,
   InputNumber,
   message,
} from "antd";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Page from "component/Page";
import styled from "styled-components";
import useAuthor from "hooks/useAuthor";
import ProductItemTable from "./ProductItemTable";
import AsyncButton from "component/AsyncButton";
import AddOnItemTable from "./AddOnItemTable";
import * as CONFIG from "@iso/config/index";

const WrapperForm = styled(Form)`
   label {
      min-width: 200px;
   }
`;

function AddOnDetail() {
   const { id } = useParams();
   const [form] = Form.useForm();
   const [isLoading, setIsLoading] = useState({
      dataAddonDetail: false,
      dataItemAddOn: false,
      dataProductItem: false,
   });
   const [dataAddOn, setDataAddOn] = useState(null);
   const [dataAddOnItems, setDataAddOnItems] = useState([]);
   const [isUnlimited, setIsUnlimited] = useState(false);
   const [isSubmitting, setIsSubmitting] = useState({
      addOnItem: false,
      updateAddOn: false,
      deleteAddOn: false,
      deleteAddOnItem: {},
   });
   const { checkRoles } = useAuthor();
   const history = useHistory();
   const addOnManagerActions = useAddOnManagerActions();

   const getData = async () => {
      setIsLoading((state) => ({ ...state, dataAddonDetail: true }));
      try {
         const resGet = await addOnManagerActions.getAddOnDetail(id);
         form.setFieldsValue(resGet);
         if (resGet?.max_selection === CONFIG.UNLIMITED_ADD_ON_SELECTION) {
            setIsUnlimited(true);
         } else {
            setIsUnlimited(false);
         }
         setDataAddOn(resGet);
      } finally {
         setIsLoading((state) => ({ ...state, dataAddonDetail: false }));
      }
   };

   const getDataAddOnItems = async () => {
      setIsLoading((state) => ({ ...state, dataItemAddOn: true }));
      try {
         const resGet = await addOnManagerActions.getAllAddOnItemList({
            addon_id: id,
         });
         setDataAddOnItems(resGet);
      } finally {
         setIsLoading((state) => ({ ...state, dataItemAddOn: false }));
      }
   };

   const handleChangeTypeMaxSelection = (type) => {
      if (type === CONFIG.MAX_SELECTION_ADD_ON_TYPE.LIMIT) {
         form.setFieldsValue({ max_selection: 1 });
         setIsUnlimited(false);
      } else {
         setIsUnlimited(true);
         form.setFieldsValue({ max_selection: -1 });
      }
   };

   const handleUpdateAddOn = async (values) => {
      setIsSubmitting((state) => ({ ...state, updateAddOn: true }));
      try {
         await addOnManagerActions.editAddOn(id, values);
         message.success("Update Add-On success!");
         getData();
      } catch (error) {
         console.log(error);
         form.setFieldsValue(dataAddOn);
         if (dataAddOn?.max_selection === CONFIG.UNLIMITED_ADD_ON_SELECTION) {
            setIsUnlimited(true);
         } else {
            setIsUnlimited(false);
         }
      } finally {
         setIsSubmitting((state) => ({ ...state, updateAddOn: false }));
      }
   };

   const handleDeleteAddOn = async () => {
      setIsSubmitting((state) => ({ ...state, deleteAddOn: true }));
      try {
         await addOnManagerActions.deleteAddOn(id);
         message.success("Delete Add-On success!");
         history.push("/product/add-on");
      } finally {
         setIsSubmitting((state) => ({ ...state, deleteAddOn: false }));
      }
   };

   useEffect(() => {
      getDataAddOnItems();
      getData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <>
         <p className=" text-2xl mb-3 text-black">General Information</p>
         <Spin spinning={isLoading.dataAddonDetail}>
            <div className="bg-white-0 rounded-sm shadow-sm px-6 pt-8 pb-1">
               <WrapperForm
                  name="edit-add-on-form"
                  onFinish={handleUpdateAddOn}
                  form={form}
               >
                  <div className="grid grid-cols-2 gap-20">
                     <div>
                        <Form.Item
                           name="name"
                           label="Add-On name"
                           rules={[
                              {
                                 required: true,
                                 message: "Add-On name is required!",
                              },
                           ]}
                        >
                           <Input placeholder="Add-On name" />
                        </Form.Item>
                        <div className="flex justify-between items-start">
                           <Form.Item
                              name="max_selection"
                              label="Max Selection"
                              rules={[
                                 {
                                    required: !isUnlimited,
                                    message: "Max selection is required!",
                                 },
                              ]}
                           >
                              <InputNumber
                                 defaultValue={1}
                                 type="number"
                                 disabled={isUnlimited}
                                 min={isUnlimited ? -1 : 1}
                              />
                           </Form.Item>
                           <Select
                              value={isUnlimited ? "unlimited" : "limit"}
                              style={{ width: 110 }}
                              onChange={handleChangeTypeMaxSelection}
                              options={[
                                 {
                                    value: "unlimited",
                                    label: "Unlimited",
                                 },
                                 {
                                    value: "limit",
                                    label: "Limit",
                                 },
                              ]}
                           />
                        </div>
                     </div>
                     <div>
                        <Form.Item
                           name="type"
                           label="Type"
                           rules={[
                              {
                                 required: true,
                                 message: "Add-On type is required!",
                              },
                           ]}
                        >
                           <Select
                              placeholder="Add-On type"
                              options={[
                                 {
                                    value: CONFIG.ADD_ON_TYPE.COMBO,
                                    label: CONFIG.ADD_ON_TYPE_NAME.COMBO,
                                 },
                                 {
                                    value: CONFIG.ADD_ON_TYPE.SINGLE,
                                    label: CONFIG.ADD_ON_TYPE_NAME.SINGLE,
                                 },
                                 {
                                    value: CONFIG.ADD_ON_TYPE.MULTIPLE,
                                    label: CONFIG.ADD_ON_TYPE_NAME.MULTIPLE,
                                 },
                              ]}
                           />
                        </Form.Item>
                     </div>
                  </div>
               </WrapperForm>
            </div>
         </Spin>
         {(checkRoles(["add_on_edit"]) || checkRoles(["add_on_delete"])) && (
            <div className="mt-8 bg-white-0 shadow-sm p-8 flex justify-end">
               {checkRoles(["add_on_delete"]) && (
                  <Popconfirm
                     title="Are you sure to delete this item?"
                     onConfirm={() => handleDeleteAddOn()}
                     placement="bottom"
                     okText="Yes"
                     cancelText="No"
                  >
                     <AsyncButton isLoading={isSubmitting?.deleteAddOn}>
                        Delete
                     </AsyncButton>
                  </Popconfirm>
               )}
               <div className="ml-16">
                  {checkRoles(["add_on_edit"]) && (
                     <AsyncButton
                        isLoading={isSubmitting.updateAddOn}
                        type="primary"
                        onClick={() => form.submit()}
                     >
                        Submit
                     </AsyncButton>
                  )}
               </div>
            </div>
         )}
         <p className=" text-2xl mb-3 mt-4 text-black">Add-on selections</p>
         <div className="grid grid-cols-7 gap-20">
            <div className="col-span-3">
               <ProductItemTable
                  onGetDataAddOnItems={getDataAddOnItems}
                  addOnId={id}
               />
            </div>
            <div className="col-span-4">
               <p className=" text-1xl mb-3 mt-4 text-black">Items Add-on</p>
               <AddOnItemTable
                  getDataAddOnItems={getDataAddOnItems}
                  setDataAddOnItems={setDataAddOnItems}
                  setIsLoading={setIsLoading}
                  dataAddOnItems={dataAddOnItems}
                  isLoading={isLoading.dataItemAddOn}
               />
            </div>
         </div>
      </>
   );
}

export default AddOnDetail;
