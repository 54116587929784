import { useState, useEffect } from "react";
import { Table, Button, Input, Form, Modal, Popconfirm } from "antd";
import { Link } from "react-router-dom";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { useGroupRoleActions } from "@iso/actions/groupRole.actions";
import { message } from "../../../node_modules/antd/lib/index";
import { useSetRecoilState } from "recoil";
import { loaderAtom } from "@iso/state";
import styled from "styled-components";
import Page from "component/Page/index";
import TextArea from "antd/lib/input/TextArea";
import useAuthor from "hooks/useAuthor";

const CustomStyle = styled.div`
   label {
      min-width: 90px;
   }
`;

const GroupRole = () => {
   const [form] = Form.useForm();
   const [isLoading, setIsLoading] = useState(true);
   const [isSubmitting, setIsSubmitting] = useState(false);
   const [isShowModalAddMember, setIsShowModalAddMember] = useState({
      stt: false,
      err: null,
   });
   const [data, setData] = useState({
      dataSource: [],
      paging: {
         current: 1,
         total: 0,
         pageSize: 10,
      },
   });
   const { checkRoles } = useAuthor();
   const [dateSearch, setDataSearch] = useState("");
   const groupRoleActions = useGroupRoleActions();

   const columns = [
      {
         title: "Name",
         dataIndex: "name",
         key: "name",
      },
      {
         title: "Description",
         dataIndex: "description",
         key: "description",
      },
      {
         title: "Actions",
         align: "center",
         fixed: "right",
         render: (record) => (
            <div className="flex gap-3 justify-center">
               {checkRoles(["group_role_view"]) && (
                  <Link to={`/root/group-role/${record["id"]}`}>
                     <Button type="primary" style={{ marginLeft: 8 }}>
                        View
                     </Button>
                  </Link>
               )}
               {checkRoles(["group_role_delete"]) && (
                  <Popconfirm
                     title="Are you sure to delete this role?"
                     onConfirm={() => handleDeleteRole(record["id"])}
                     placement="bottom"
                     okText="Yes"
                     cancelText="No"
                  >
                     <Button
                        loading={isSubmitting}
                        type="primary"
                        style={{ marginLeft: 8 }}
                     >
                        Delete
                     </Button>
                  </Popconfirm>
               )}
            </div>
         ),
      },
   ];

   const handleTableChange = (pagination) => {
      getData(pagination.current, pagination.pageSize, dateSearch);
   };
   const getData = async (offset, limit, search) => {
      try {
         setIsLoading(true);
         const resGet = await groupRoleActions.getGroupRoleList({
            page: offset,
            limit,
            search: search ? search : null,
         });
         setData({
            paging: {
               current: resGet.page,
               total: resGet.total,
               pageSize: limit,
            },
            dataSource: resGet.docs,
         });
      } catch (error) {
         console.log(error);
      } finally {
         setIsLoading(false);
      }
   };

   const handleSearch = (values) => {
      const search = values.search.trim();
      setDataSearch(search);
      getData(1, data.paging.pageSize, search);
   };

   const handleDeleteRole = async (id) => {
      try {
         setIsSubmitting(true);
         await groupRoleActions.deleteGroupRole(id);
         message.success("Delete role success!!!");
         getData(1, data.paging.pageSize);
         setIsShowModalAddMember({
            stt: false,
            err: null,
         });
         form.resetFields();
      } catch (error) {
         console.log(error);
      } finally {
         setIsSubmitting(false);
      }
   };

   const handleAddRole = async (values) => {
      setIsSubmitting(true);
      try {
         await groupRoleActions.addNewGroupRole(values);
         getData(1, data.paging.pageSize);
         setIsShowModalAddMember({
            stt: false,
            err: null,
         });
         message.success("Add role success!");
         form.resetFields();
      } catch (error) {
         console.log(error);
      } finally {
         setIsSubmitting(false);
      }
   };

   const handleToggleModal = (st) => {
      setIsShowModalAddMember({
         stt: st,
         err: null,
      });
   };

   useEffect(() => {
      getData(1, data.paging.pageSize);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <Page title="Group Role Management">
         <div className="staff-setting-wrapper">
            <div className="flex justify-between">
               <Form
                  name="nest-messages"
                  onFinish={handleSearch}
                  style={{ display: "flex", gap: 8 }}
               >
                  <Form.Item name="search" label="Search">
                     <Input placeholder="Search by name" />
                  </Form.Item>
                  <Form.Item>
                     <Button type="primary" htmlType="submit">
                        <p className="flex items-center">
                           <SearchOutlined />
                           <span className="ml-2">Submit</span>
                        </p>
                     </Button>
                  </Form.Item>
               </Form>
               {checkRoles(["group_role_create"]) && (
                  <Button
                     type="primary"
                     onClick={() => handleToggleModal(true)}
                  >
                     <p className="flex items-center">
                        <PlusOutlined />
                        <span className="ml-2">Add role</span>
                     </p>
                  </Button>
               )}
            </div>
            <Table
               columns={columns?.filter((col) => {
                  if (col.title === "Actions") {
                     return (
                        checkRoles(["group_role_view"]) ||
                        checkRoles(["group_role_delete"])
                     );
                  }
                  return true;
               })}
               rowKey="key"
               dataSource={data.dataSource}
               onChange={handleTableChange}
               pagination={{
                  current: data.paging.current,
                  total: data.paging.total,
                  pageSize: data.paging.pageSize,
               }}
               loading={isLoading}
            />
            <Modal
               title="Add role"
               visible={isShowModalAddMember.stt}
               footer={null}
               onCancel={() => handleToggleModal(false)}
            >
               <CustomStyle>
                  <Form
                     name="invite-member-form"
                     onFinish={handleAddRole}
                     form={form}
                  >
                     <div style={{ width: "100%", gap: 8 }}>
                        <Form.Item
                           name="name"
                           label="Name"
                           style={{ flexGrow: 1 }}
                           rules={[
                              { required: true, message: "Name is required!" },
                              {
                                 message: "Name is invalid",
                              },
                           ]}
                        >
                           <Input />
                        </Form.Item>

                        <Form.Item
                           name="description"
                           label="Description"
                           style={{ flexGrow: 1 }}
                        >
                           <TextArea rows={4} />
                        </Form.Item>

                        <div className="text-center">
                           <Button
                              loading={isSubmitting}
                              type="primary"
                              htmlType="submit"
                           >
                              Submit
                           </Button>
                        </div>
                     </div>
                     {isShowModalAddMember?.err !== null && (
                        <span>{isShowModalAddMember?.err || ""}</span>
                     )}
                  </Form>
               </CustomStyle>
            </Modal>
         </div>
      </Page>
   );
};

export default GroupRole;
