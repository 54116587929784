import React from "react";

function Container(props) {
   const { children, className } = props;
   return (
      <div className={`px-14 h-full ${className ? className : ""}`}>
         {children}
      </div>
   );
}

export default Container;
