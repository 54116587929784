import { API_ENDPOINT, API_ENDPOINT_BO_DELI } from "@iso/config/index";
import { axiosClient } from "@iso/helpers/axios.config";

const userApi = {
   get: (params) => {
      return axiosClient.get(`${API_ENDPOINT}/common/staff`, { params });
   },
   register: (data) => {
      return axiosClient.post(`${API_ENDPOINT}/common/register`, data);
   },
   update: (data) => {
      return axiosClient.patch(`${API_ENDPOINT}/common/staff`, data);
   },
   registerBoDeli: (data) => {
      return axiosClient.post(
         `${API_ENDPOINT_BO_DELI}/iam/common/register`,
         data
      );
   },
   updateBoDeli: (data) => {
      return axiosClient.patch(`${API_ENDPOINT_BO_DELI}/staff`, data);
   },
   createSessionCookie: (data) => {
      return axiosClient.post(`${API_ENDPOINT}/auth/session-cookie`, data);
   },
   createCustomToken: (data) => {
      return axiosClient.post(`${API_ENDPOINT}/auth/custom-token`, data);
   },
};

export default userApi;
