import attributeManagerApi from "api/attributeManagerApi";

export { useAttributeManagerActions };

function useAttributeManagerActions() {
   // Attribute Type
   const getFilterPropertiesAttributeType = async () => {
      try {
         return await attributeManagerApi.getFilterPropertiesAttributeType();
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const getOptionsPropertyAttributeType = async (params) => {
      try {
         return await attributeManagerApi.getOptionsPropertyAttributeType(
            params
         );
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const getAllAttributeTypeList = async () => {
      try {
         return await attributeManagerApi.getAllAttributeTypes();
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const getAttributeTypeList = async (params) => {
      try {
         return await attributeManagerApi.getAttributeTypes(params);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const getAttributeTypeDetail = async (id) => {
      try {
         return await attributeManagerApi.getAttributeType(id);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const addAttributeType = async (body) => {
      try {
         return await attributeManagerApi.postAttributeType(body);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const editAttributeType = async (id, body) => {
      try {
         return await attributeManagerApi.putAttributeType(id, body);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const deleteAttributeType = async (id) => {
      try {
         return await attributeManagerApi.deleteAttributeType(id);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   // Attribute
   const getFilterPropertiesAttribute = async () => {
      try {
         return await attributeManagerApi.getFilterPropertiesAttribute();
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const getOptionsPropertyAttribute = async (params) => {
      try {
         return await attributeManagerApi.getOptionsPropertyAttribute(params);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const getAllAttributeList = async () => {
      try {
         return await attributeManagerApi.getAllAttribute();
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const getAttributeList = async (params) => {
      try {
         return await attributeManagerApi.getAttributes(params);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const getAttributeDetail = async (id) => {
      try {
         return await attributeManagerApi.getAttribute(id);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const addAttribute = async (body) => {
      try {
         return await attributeManagerApi.postAttribute(body);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const editAttribute = async (id, body) => {
      try {
         return await attributeManagerApi.putAttribute(id, body);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const deleteAttribute = async (id) => {
      try {
         return await attributeManagerApi.deleteAttribute(id);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   // Item Attribute
   const addItemAttribute = async (body) => {
      try {
         return await attributeManagerApi.postItemAttribute(body);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const editItemAttribute = async (id, body) => {
      try {
         return await attributeManagerApi.putItemAttribute(id, body);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const deleteItemAttribute = async (id) => {
      try {
         return await attributeManagerApi.deleteItemAttribute(id);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   return {
      getFilterPropertiesAttributeType,
      getOptionsPropertyAttributeType,
      getAllAttributeTypeList,
      getAttributeTypeList,
      getAttributeTypeDetail,
      addAttributeType,
      editAttributeType,
      deleteAttributeType,

      getFilterPropertiesAttribute,
      getOptionsPropertyAttribute,
      getAllAttributeList,
      getAttributeList,
      getAttributeDetail,
      addAttribute,
      editAttribute,
      deleteAttribute,

      addItemAttribute,
      editItemAttribute,
      deleteItemAttribute,
   };
}
