import { API_ENDPOINT } from "@iso/config/index";
import { axiosClient } from "@iso/helpers/axios.config";

const SCHEDULE_SURCHARGE_ENDPONT = `${API_ENDPOINT}/schedule-surcharge`;

const scheduleSurchargeManagerApi = {
   gets: (params) => {
      return axiosClient.get(SCHEDULE_SURCHARGE_ENDPONT, { params });
   },

   get: (id) => {
      return axiosClient.get(`${SCHEDULE_SURCHARGE_ENDPONT}/${id}`);
   },

   post: (body) => {
      return axiosClient.post(SCHEDULE_SURCHARGE_ENDPONT, body);
   },

   put: (id, body) => {
      return axiosClient.put(`${SCHEDULE_SURCHARGE_ENDPONT}/${id}`, body);
   },

   delete: (id) => {
      return axiosClient.delete(`${SCHEDULE_SURCHARGE_ENDPONT}/${id}`);
   },
};

export default scheduleSurchargeManagerApi;
