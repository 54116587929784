import { atom } from "recoil";

const categoriesAtom = atom({
   key: "categories",
   default: {
      list: [],
      currentClass: null,
      currentTab: "1",
   },
});

export { categoriesAtom };
