import { API_ENDPOINT } from "@iso/config/index";
import { axiosClient } from "@iso/helpers/axios.config";

const STAFF_SETTING = `${API_ENDPOINT}/staff`;

const staffSettingApi = {
   gets: (params) => {
      return axiosClient.get(STAFF_SETTING, { params });
   },
   inviteMember: (data) => {
      return axiosClient.post(STAFF_SETTING, data);
   },
   updateStaffStore: (id, data) => {
      return axiosClient.put(`${STAFF_SETTING}/${id}/assign`, data);
   },
   getStaffSettingStoreDetail: (id) => {
      return axiosClient.get(`${STAFF_SETTING}/${id}/assign`);
   },
   updateStaffRole: (id, data) => {
      return axiosClient.put(`${STAFF_SETTING}/${id}/group-role`, data);
   },
   getStaffSettingRoleDetail: (id) => {
      return axiosClient.get(`${STAFF_SETTING}/${id}/group-role`);
   },
};

export default staffSettingApi;
