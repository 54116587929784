import { useState, useEffect } from "react";
import { Form, Input, message, Modal, Select, InputNumber } from "antd";
import { useAddOnManagerActions } from "@iso/actions/addOnManager.actions";
import styled from "styled-components";
import AsyncButton from "component/AsyncButton";
import * as CONFIG from "@iso/config/index";

const WrapperForm = styled(Form)`
   label {
      min-width: 200px;
   }
`;

function AddAddOnModal(props) {
   const [isLoading, setIsLoading] = useState(false);
   const [form] = Form.useForm();
   const [isUnlimited, setIsUnlimited] = useState(false);
   const addOnManagerActions = useAddOnManagerActions();

   const handleAddAddOn = async (values) => {
      setIsLoading(true);
      try {
         if (isUnlimited) {
            values.max_selection = CONFIG.UNLIMITED_ADD_ON_SELECTION;
         }
         await addOnManagerActions.addAddOn(values);
         props?.getData();
         props?.onClose();
         message.success("Add Add-On success!");
      } finally {
         setIsLoading(false);
      }
   };

   const handleChangeTypeMaxSelection = (type) => {
      console.log(type);
      if (type === CONFIG.MAX_SELECTION_ADD_ON_TYPE.LIMIT) {
         form.setFieldsValue({ max_selection: 1 });
         setIsUnlimited(false);
      } else {
         setIsUnlimited(true);
         form.setFieldsValue({ max_selection: -1 });
      }
   };

   useEffect(() => {
      form.resetFields();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [props?.visible]);

   return (
      <Modal
         title="Add Add-On"
         visible={props.visible}
         footer={null}
         onCancel={() => props.onClose()}
      >
         <WrapperForm name="add-on-form" onFinish={handleAddAddOn} form={form}>
            <Form.Item
               name="name"
               label="Name"
               rules={[
                  {
                     required: true,
                     message: "Add-On name is required!",
                  },
               ]}
            >
               <Input placeholder="Add-On name" />
            </Form.Item>
            <Form.Item
               name="type"
               label="Type"
               rules={[
                  {
                     required: true,
                     message: "Add-On type is required!",
                  },
               ]}
            >
               <Select
                  placeholder="Add-On type"
                  options={[
                     {
                        value: CONFIG.ADD_ON_TYPE.COMBO,
                        label: CONFIG.ADD_ON_TYPE_NAME.COMBO,
                     },
                     {
                        value: CONFIG.ADD_ON_TYPE.SINGLE,
                        label: CONFIG.ADD_ON_TYPE_NAME.SINGLE,
                     },
                     {
                        value: CONFIG.ADD_ON_TYPE.MULTIPLE,
                        label: CONFIG.ADD_ON_TYPE_NAME.MULTIPLE,
                     },
                  ]}
               />
            </Form.Item>
            <div className="flex justify-between items-start">
               <Form.Item
                  name="max_selection"
                  label="Max Selection"
                  rules={[
                     {
                        required: !isUnlimited,
                        message: "Max selection is required!",
                     },
                  ]}
                  initialValue={1}
               >
                  <InputNumber
                     defaultValue={1}
                     type="number"
                     disabled={isUnlimited}
                     min={isUnlimited ? -1 : 1}
                  />
               </Form.Item>
               <Select
                  defaultValue="limit"
                  style={{ width: 110 }}
                  onChange={handleChangeTypeMaxSelection}
                  options={[
                     {
                        value: "unlimited",
                        label: "Unlimited",
                     },
                     {
                        value: "limit",
                        label: "Limit",
                     },
                  ]}
               />
            </div>
            <Form.Item className="text-center" style={{ marginBottom: 0 }}>
               <AsyncButton
                  isLoading={isLoading}
                  type="primary"
                  htmlType="submit"
                  disabled={isLoading}
               >
                  Submit
               </AsyncButton>
            </Form.Item>
         </WrapperForm>
      </Modal>
   );
}

export default AddAddOnModal;
