import { useEffect } from "react";
import { Form, Input, Select, DatePicker, Button, message } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { userAtom, loaderAtom } from "@iso/state";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useUserActions } from "@iso/actions";
import * as CONFIG from "@iso/config/";
import Page from "component/Page/index";
import moment from "moment";

function ProfileSetting() {
   const user = useRecoilValue(userAtom);
   const [form] = Form.useForm();
   const setLoader = useSetRecoilState(loaderAtom);

   const userActions = useUserActions();

   const prefixSelector = (
      <Form.Item name="prefix" noStyle>
         <Select style={{ width: 70 }} disabled={true} showArrow={false}>
            <Select.Option value="+84">+84</Select.Option>
         </Select>
      </Form.Item>
   );

   const disabledDate = (current) => {
      // Can not select days after today and today
      return current && current > moment().endOf("day");
   };

   const handleUpdatePofile = async (values) => {
      setLoader(true);
      try {
         const { birthday, fullname, gender, phonenumber, prefix } = values;
         const newInfo = {
            full_name: fullname,
            phone_number: `${prefix}${phonenumber}`,
            gender: gender,
            birth_day: birthday.format(),
         };
         const resUpdate = await userActions.updateInfo(newInfo);
         if (resUpdate?.status) {
            message.success("Update your profile successful!");
         }
         setLoader(false);
      } catch (error) {
         console.log(error);
      }
   };

   useEffect(() => {
      form.setFieldsValue({
         fullname: user?.info?.full_name,
         email: user?.info?.email,
         phonenumber: user?.info?.phone_number?.replace("+84", ""),
         gender: user?.info?.gender || "male",
         prefix: "+84",
         birthday: moment(user?.info?.birth_day),
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <Page title="Profile Settings">
         <Form
            layout="vertical"
            name="profile-form"
            onFinish={handleUpdatePofile}
            form={form}
         >
            <Form.Item
               label="Full Name"
               name="fullname"
               rules={[
                  { required: true, message: "Please input your fullname!" },
               ]}
            >
               <Input />
            </Form.Item>

            <Form.Item
               label="Email"
               name="email"
               rules={[
                  { required: true, message: "Please input your email!" },
                  { type: "email", message: "Not valid email address" },
               ]}
            >
               <Input disabled />
            </Form.Item>

            <Form.Item
               label="Phone number"
               name="phonenumber"
               rules={[
                  {
                     required: true,
                     message: "Please input your phone number!",
                  },
                  { len: 9, message: "Invalid phone number" },
               ]}
            >
               <Input addonBefore={prefixSelector} style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item
               label="Gender"
               name="gender"
               rules={[
                  { required: true, message: "Please choose your gender" },
               ]}
            >
               <Select style={{ width: "100%" }}>
                  <Select.Option value="male">Male</Select.Option>
                  <Select.Option value="female">Female</Select.Option>
                  <Select.Option value="undefined">Undefined</Select.Option>
               </Select>
            </Form.Item>

            <Form.Item
               label="Your Birthday"
               name="birthday"
               rules={[
                  { required: true, message: "Please choose your birthday" },
               ]}
            >
               <DatePicker
                  format={CONFIG.DATE_FORMAT}
                  disabledDate={disabledDate}
                  style={{ width: "100%" }}
               />
            </Form.Item>

            <Form.Item className="text-right">
               <Button type="primary" htmlType="submit">
                  <p className="flex items-center">
                     <SaveOutlined />
                     <span className="ml-2">Update your profile</span>
                  </p>
               </Button>
            </Form.Item>
         </Form>
      </Page>
   );
}

export default ProfileSetting;
