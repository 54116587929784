import { atom } from "recoil";

const bannersAtom = atom({
   key: "banners",
   default: {
      store_channels: {},
   },
});

export { bannersAtom };
