import { Checkbox, message, Popconfirm, Table } from "antd";
import { useState } from "react";
import { arrayMoveImmutable } from "array-move";
import {
   SortableContainer,
   SortableElement,
   SortableHandle,
} from "react-sortable-hoc";
import { CloseOutlined, EditOutlined, MenuOutlined } from "@ant-design/icons";
import { useAddOnManagerActions } from "@iso/actions/addOnManager.actions";
import Dropdown from "component/Dropdown";
import EditPrice from "./EditPrice";
import AsyncButton from "component/AsyncButton";

function AddOnItemTable(props) {
   const [isSubmitting, setIsSubmitting] = useState({
      deleteAddOnItem: {},
   });
   const addOnManagerActions = useAddOnManagerActions();

   const onSortEnd = ({ oldIndex, newIndex }) => {
      if (oldIndex !== newIndex) {
         const newData = arrayMoveImmutable(
            props?.dataAddOnItems.slice(),
            oldIndex,
            newIndex
         ).filter((el) => !!el);
         props?.setDataAddOnItems(newData);
         handleUpdateSorting(newData);
      }
   };

   const DragHandle = SortableHandle(() => (
      <MenuOutlined
         style={{
            cursor: "grab",
            color: "#999",
         }}
      />
   ));

   const SortableItem = SortableElement((props) => <tr {...props} />);
   const SortableBody = SortableContainer((props) => <tbody {...props} />);

   const DraggableContainer = (props) => (
      <SortableBody
         useDragHandle
         disableAutoscroll
         helperClass="row-dragging"
         onSortEnd={onSortEnd}
         {...props}
      />
   );

   const DraggableBodyRow = ({ className, style, ...restProps }) => {
      const index = props?.dataAddOnItems.findIndex(
         (x) => x.id === restProps["data-row-key"]
      );
      return <SortableItem index={index} {...restProps} />;
   };

   const addOnItemColumns = [
      {
         title: "Sort",
         dataIndex: "id",
         className: "drag-visible",
         render: () => <DragHandle />,
      },
      {
         title: "Item Name",
         dataIndex: ["productItems", "name"],
         key: "name",
         className: "drag-visible",
      },
      {
         title: "Price",
         key: "addon_price",
         align: "center",
         render: (record) => (
            <Dropdown
               isHidenDropdown={!record?.is_fixed}
               content={
                  <EditPrice
                     id={record?.id}
                     getData={props?.getDataAddOnItems}
                     defaultPrice={record?.addon_price}
                  />
               }
            >
               <div className="cursor-pointer">
                  <span className="flex items-center justify-center">
                     {record?.addon_price}
                     {record?.is_fixed && <EditOutlined className="ml-4" />}
                  </span>
               </div>
            </Dropdown>
         ),
      },
      {
         title: "Is Fixed",
         key: "is_fixed",
         align: "center",
         className: "drag-hiden",
         render: (record) => (
            <Checkbox
               defaultChecked={record?.is_fixed}
               onChange={(e) =>
                  handleUpdateFixedAddOnItem(record?.id, e.target.checked)
               }
            />
         ),
      },
      {
         align: "center",
         className: "drag-hiden",
         render: (record) => (
            <Popconfirm
               title="Are you sure to delete this Add-On item?"
               onConfirm={() => handleDeleteAddOnItem(record["id"])}
               placement="bottom"
               okText="Yes"
               cancelText="No"
            >
               <AsyncButton
                  onlyIcon
                  isLoading={
                     isSubmitting.deleteAddOnItem[record["id"].toString()] ||
                     false
                  }
                  type="primary"
                  style={{
                     display: "flex",
                     alignItems: "center",
                     margin: "0 auto",
                  }}
               >
                  <div className="flex items-center">
                     <CloseOutlined />
                  </div>
               </AsyncButton>
            </Popconfirm>
         ),
      },
   ];

   const UpdateIsSubmittingAddOnItem = (status, id) => {
      if (status === true) {
         const deleteAddOnItem = isSubmitting?.deleteAddOnItem;
         deleteAddOnItem[`${id}`] = true;
         setIsSubmitting((state) => ({ ...state, deleteAddOnItem }));
      } else {
         const deleteAddOnItem = isSubmitting?.deleteAddOnItem;
         delete deleteAddOnItem.id;
         setIsSubmitting((state) => ({
            ...state,
            deleteAddOnItem: deleteAddOnItem,
         }));
      }
   };

   const handleUpdateFixedAddOnItem = async (id, status) => {
      props?.setIsLoading((state) => ({ ...state, dataItemAddOn: true }));
      try {
         await addOnManagerActions.editAddOnItem(id, { is_fixed: status });
         message.success("Update Add-On item success!");
      } finally {
         props?.getDataAddOnItems();
         props?.setIsLoading((state) => ({ ...state, dataItemAddOn: false }));
      }
   };

   const handleUpdateSorting = async (newData) => {
      props?.setIsLoading((state) => ({ ...state, dataItemAddOn: true }));
      try {
         await addOnManagerActions.updateSortingAddOnItemList({
            item_ids: newData?.map((item) => item?.id),
         });
         message.success("Update Add-On item sorting success!");
      } catch (error) {
         props?.getDataAddOnItems();
      } finally {
         props?.setIsLoading((state) => ({ ...state, dataItemAddOn: false }));
      }
   };

   const handleDeleteAddOnItem = async (id) => {
      UpdateIsSubmittingAddOnItem(true, id);
      try {
         await addOnManagerActions.deleteAddOnItem(id);
         message.success("Delete Add-On item success!");
         props?.getDataAddOnItems();
      } finally {
         UpdateIsSubmittingAddOnItem(false, id);
      }
   };

   return (
      <Table
         loading={props?.isLoading}
         pagination={false}
         columns={addOnItemColumns}
         dataSource={props?.dataAddOnItems}
         rowKey="id"
         components={{
            body: {
               wrapper: DraggableContainer,
               row: DraggableBodyRow,
            },
         }}
         scroll={{ y: 800 }}
      />
   );
}

export default AddOnItemTable;
