import { useState, useEffect } from "react";
import { Form, Input, message, Button, Modal, DatePicker, Select } from "antd";
import { useLangManagerActions } from "@iso/actions/langManager.actions";
import styled from "styled-components";
import { useScheduleSurchargeManagerActions } from "@iso/actions/scheduleSurchargeManager.actions";
import * as moment from "moment";
import { COUNTRY_CODE, COUNTRY_NAME } from "@iso/config/index";

const WrapperForm = styled(Form)`
   label {
      min-width: 200px;
   }
`;

const { RangePicker } = DatePicker;

function AddEditModal(props) {
   const [isLoading, setIsLoading] = useState(false);
   const [form] = Form.useForm();
   const surchargeManagerActions = useScheduleSurchargeManagerActions();

   useEffect(() => {
      props.modalLangState.data && getData(props.modalLangState.data);
      if (props.modalLangState.visible === false) {
         form.resetFields();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [props.modalLangState.visible]);

   const getData = async (data) => {
      setIsLoading(true);
      try {
         if (data) {
            form.setFieldsValue(data);
         }
      } catch (error) {
         console.log(error);
      } finally {
         setIsLoading(false);
      }
   };

   const onFinish = async (values) => {
      setIsLoading(true);
      try {
         console.log(values);
         let cookedValue = null;

         if (props.modalLangState.data?.id) {
            cookedValue = {
               surcharge_percent: values?.surcharge_percent,
               content: values?.content,
               country: values?.country,
               description_vi: values?.description_vi,
               description_en: values?.description_en,
            };
         } else {
            cookedValue = {
               date_from: moment(values?.dateRange[0])?.format("YYYY/MM/DD"),
               date_to: moment(values?.dateRange[1])?.format("YYYY/MM/DD"),
               surcharge_percent: values?.surcharge_percent,
               description_vi: values?.description_vi,
               content: values?.content,
               country: values?.country,
               description_en: values?.description_en,
            };
         }
         props.modalLangState.data
            ? await surchargeManagerActions.edit(
                 props.modalLangState.data?.id,
                 cookedValue
              )
            : await surchargeManagerActions.add(cookedValue);
         message.success(
            `${
               props.modalLangState.data?.id ? "Edit" : "Add"
            } surcharge success!`
         );
         !props.modalLangState.data?.id && form.resetFields();
         props.handleToggleModalSurchargeState(false);
         props.getData();
      } catch (error) {
         console.log(error);
      } finally {
         setIsLoading(false);
      }
   };

   return (
      <Modal
         title={`${
            props.modalLangState.data?.id ? "Edit" : "Add"
         } Schedule surcharge`}
         visible={props.modalLangState.visible}
         footer={null}
         onCancel={() => props.handleToggleModalSurchargeState(false)}
      >
         <WrapperForm name="add-edit-lang-form" onFinish={onFinish} form={form}>
            {props.modalLangState.data?.id ? (
               ""
            ) : (
               <Form.Item
                  name="dateRange"
                  label="Surcharge Date Range"
                  rules={[
                     {
                        required: true,
                        message: "dateRange is required!",
                     },
                  ]}
               >
                  <RangePicker />
               </Form.Item>
            )}

            <Form.Item
               name="country"
               label="Country"
               rules={[
                  {
                     required: true,
                     message: "Country is required!",
                  },
               ]}
            >
               <Select placeholder="Select country">
                  <Select.Option value={COUNTRY_CODE.VN}>
                     {COUNTRY_NAME.VN}
                  </Select.Option>
                  <Select.Option value={COUNTRY_CODE.IN}>
                     {COUNTRY_NAME.IN}
                  </Select.Option>
               </Select>
            </Form.Item>

            <Form.Item
               name="surcharge_percent"
               label="Surcharge Percent"
               rules={[
                  {
                     required: true,
                     message: "surcharge_percent is required!",
                  },
               ]}
            >
               <Input placeholder="Surcharge percent" type="number" />
            </Form.Item>
            <Form.Item name="content" label="Content">
               <Input placeholder="Content" />
            </Form.Item>
            <Form.Item name="description_vi" label="Description Vi">
               <Input placeholder="Description vi" />
            </Form.Item>
            <Form.Item name="description_en" label="Description En">
               <Input placeholder="Description vi" />
            </Form.Item>
            <Form.Item className="text-center" style={{ marginBottom: 0 }}>
               <Button
                  loading={isLoading}
                  type="primary"
                  htmlType="submit"
                  disabled={isLoading}
               >
                  Submit
               </Button>
            </Form.Item>
         </WrapperForm>
      </Modal>
   );
}

export default AddEditModal;
