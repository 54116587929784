import addOnManagerApi from "api/addOnManagerApi";

export { useAddOnManagerActions };

function useAddOnManagerActions() {
   const getFilterProperties = async () => {
      try {
         return await addOnManagerApi.getFilterProperties();
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const getOptionsProperty = async (params) => {
      try {
         return await addOnManagerApi.getOptionsProperty({
            ...params,
         });
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const getAddOnList = async (params) => {
      try {
         return await addOnManagerApi.gets(params);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const getAddOnDetail = async (id) => {
      try {
         return await addOnManagerApi.get(id);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const addAddOn = async (body) => {
      try {
         return await addOnManagerApi.post(body);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const editAddOn = async (id, body) => {
      try {
         return await addOnManagerApi.put(id, body);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const deleteAddOn = async (id) => {
      try {
         return await addOnManagerApi.delete(id);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   //Add-On Item
   const getAllAddOnItemList = async (params) => {
      try {
         return await addOnManagerApi.getsAllAddOnItem(params);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const getAddOnItemList = async (params) => {
      try {
         return await addOnManagerApi.getsAddOnItem(params);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const addAddOnItem = async (body) => {
      try {
         return await addOnManagerApi.postAddOnItem(body);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const updateSortingAddOnItemList = async (body) => {
      try {
         return await addOnManagerApi.postSortingAddOnItem(body);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const editAddOnItem = async (id, body) => {
      try {
         return await addOnManagerApi.putAddOnItem(id, body);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const deleteAddOnItem = async (id) => {
      try {
         return await addOnManagerApi.deleteAddOnItem(id);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   return {
      getFilterProperties,
      getOptionsProperty,

      getAddOnList,
      getAddOnDetail,
      addAddOn,
      editAddOn,
      deleteAddOn,

      getAllAddOnItemList,
      addAddOnItem,
      getAddOnItemList,
      updateSortingAddOnItemList,
      editAddOnItem,
      deleteAddOnItem,
   };
}
