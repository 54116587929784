import { useState } from "react";
import styled from "styled-components";
import OutsideClickHandler from "react-outside-click-handler";

const DropdownWrapper = styled.div`
   position: relative;
   display: inline-block;
   .content {
      top: calc(100% + 5px);
      position: absolute;
      z-index: 10;
      min-width: 150px;
   }
`;

function Dropdown(props) {
   const [visible, setVisible] = useState(false);

   const handleOpen = () => {
      if (!props?.isHidenDropdown && visible === false) {
         setVisible(true);
      } else {
         setVisible(false);
      }
   };

   const handleClose = () => {
      if (typeof props?.onCloseDropdown === "function") {
         props?.onCloseDropdown();
      }
      setVisible(false);
   };

   return (
      <DropdownWrapper>
         <OutsideClickHandler onOutsideClick={handleClose}>
            <button onClick={handleOpen}>{props?.children}</button>
            <div
               className={`content bg-white-0 shadow-lg rounded-md p-4 ${
                  visible ? "opacity-100" : "opacity-0 invisible"
               }`}
            >
               {props?.content}
            </div>
         </OutsideClickHandler>
      </DropdownWrapper>
   );
}

export default Dropdown;
