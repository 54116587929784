import { API_ENDPOINT } from "@iso/config/index";
import { axiosClient } from "@iso/helpers/axios.config";

const STORE_ENDPONT = `${API_ENDPOINT}/store`;
const STORE_KVS_ENDPONT = `${API_ENDPOINT}/store/store-kvs`;

const storeManagerApi = {
   getFilterProperties: () => {
      return axiosClient.get(`${STORE_ENDPONT}/filter-properties`);
   },

   getOptionsProperty: (params) => {
      return axiosClient.get(`${STORE_ENDPONT}/options-property`, {
         params,
      });
   },

   getAll: () => {
      return axiosClient.get(`${STORE_ENDPONT}/all`);
   },

   gets: (params) => {
      return axiosClient.get(STORE_ENDPONT, { params });
   },

   get: (id) => {
      return axiosClient.get(`${STORE_ENDPONT}/${id}`);
   },

   getDevices: (id) => {
      return axiosClient.get(`${STORE_ENDPONT}/devices`);
   },

   post: (body) => {
      return axiosClient.post(STORE_ENDPONT, body);
   },

   postStoreKVS: (id) => {
      return axiosClient.post(`${STORE_KVS_ENDPONT}/${id}`);
   },

   put: (id, body) => {
      return axiosClient.put(`${STORE_ENDPONT}/${id}`, body);
   },

   delete: (id) => {
      return axiosClient.delete(`${STORE_ENDPONT}/${id}`);
   },

   deleteStoreKVS: (id) => {
      return axiosClient.delete(`${STORE_KVS_ENDPONT}/${id}`);
   },

   getBrands: () => {
      return axiosClient.get(`${STORE_ENDPONT}/brands`);
   },

   getRegions: () => {
      return axiosClient.get(`${STORE_ENDPONT}/regions`);
   },
};

export default storeManagerApi;
