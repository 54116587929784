import { Tabs } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Page from "component/Page";
import AddOnDetail from "./AddOnDetail";
import AddOn from "./AddOn";

const { TabPane } = Tabs;

function AddOnManager() {
   const { id } = useParams();
   const [currentTab, setCurrentTab] = useState("1");

   const handleTabClick = (key) => {
      if (key === "1") {
         setCurrentTab(key);
      }
   };

   useEffect(() => {
      if (id) {
         setCurrentTab("2");
      } else {
         setCurrentTab("1");
      }
   }, [id]);

   return (
      <Page title="Add-On Management">
         <Tabs onTabClick={handleTabClick} activeKey={currentTab} type="card">
            <TabPane tab="Add-On" key="1">
               {currentTab === "1" && <AddOn />}
            </TabPane>
            <TabPane disabled={currentTab === "1"} tab="Add-On Detail" key="2">
               {currentTab === "2" && <AddOnDetail />}
            </TabPane>
         </Tabs>
      </Page>
   );
}

export default AddOnManager;
