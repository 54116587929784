import { Tabs } from "antd";
import { useState } from "react";
import Page from "component/Page";
import AttributeType from "./AttributeType";
import Attribute from "./Attribute";

const { TabPane } = Tabs;

function AttributeManager() {
   const [currentTab, setCurrentTab] = useState(1);
   return (
      <Page title="Attribute Management">
         <Tabs type="card" onChange={(e) => setCurrentTab(e)}>
            <TabPane tab="Attribute Type" key="1">
               <AttributeType currentTab={currentTab} />
            </TabPane>
            <TabPane tab="Attribute" key="2">
               <Attribute currentTab={currentTab} />
            </TabPane>
         </Tabs>
      </Page>
   );
}

export default AttributeManager;
