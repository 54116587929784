import staffSettingApi from "api/staffSettingApi";

export { useStaffActions };

function useStaffActions() {
   const getStaffSettingList = async (params) => {
      try {
         return await staffSettingApi.gets(params);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const inviteMember = async (data) => {
      try {
         return await staffSettingApi.inviteMember(data);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const updateStaffStore = async (userID, data) => {
      try {
         return await staffSettingApi.updateStaffStore(userID, data);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const getStaffSettingStoreDetail = async (id) => {
      try {
         return await staffSettingApi.getStaffSettingStoreDetail(id);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const updateStaffRole = async (userID, data) => {
      try {
         return await staffSettingApi.updateStaffRole(userID, data);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const getStaffSettingRoleDetail = async (id) => {
      try {
         return await staffSettingApi.getStaffSettingRoleDetail(id);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   return {
      getStaffSettingList,
      getStaffSettingStoreDetail,
      inviteMember,
      updateStaffStore,
      getStaffSettingRoleDetail,
      updateStaffRole,
   };
}
