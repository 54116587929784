import { useHistoryManagerActions } from "@iso/actions/historyManager.actions";
import { useEffect, useState } from "react";
import { Button, Modal, Table } from "antd";
import * as moment from "moment/moment";

function History(props) {
   const { product } = props;
   const [data, setData] = useState({
      dataSource: [],
      paging: {
         current: 1,
         total: 0,
         pageSize: 10,
      },
   });
   const historyManagerActions = useHistoryManagerActions();
   const [isLoading, setIsLoading] = useState(true);
   const [itemSelected, setItemSelected] = useState(null);

   const columns = [
      {
         title: "User",
         dataIndex: "user_name",
         key: "user_name",
      },
      {
         title: "Table",
         dataIndex: "table",
         key: "table",
         render: (record) => (
            <span className="capitalize">{record.replaceAll("_", " ")}</span>
         ),
      },
      {
         title: "Description",
         dataIndex: "description",
         key: "description",
      },
      {
         title: "Date",
         dataIndex: "created_at",
         key: "created_at",
         render: (record) => (
            <span>{moment(new Date(record)).format("hh:mm DD/MM/YYYY")}</span>
         ),
      },
      {
         title: "Actions",
         align: "center",
         fixed: "right",
         render: (record) =>
            record?.trackingItems?.length > 0 && (
               <Button type="primary" onClick={() => setItemSelected(record)}>
                  Detail
               </Button>
            ),
      },
   ];

   const columnsDetail = [
      {
         title: "Property",
         dataIndex: "column",
         key: "column",
         render: (record) => (
            <span className="capitalize">{record.replaceAll("_", " ")}</span>
         ),
      },
      {
         title: "Old Value",
         dataIndex: "old_value",
         key: "old_value",
      },
      {
         title: "New Value",
         dataIndex: "new_value",
         key: "new_value",
      },
   ];

   const getData = async (offset, limit) => {
      setIsLoading(true);
      try {
         const resGet = await historyManagerActions.getProductHistroy({
            page: offset,
            limit,
            item_id: product?.id,
         });
         setData({
            paging: {
               current: resGet?.page,
               total: resGet?.total,
               pageSize: limit,
            },
            dataSource: resGet?.data,
         });
      } finally {
         setIsLoading(false);
      }
   };

   const handleTableChange = (pagination) => {
      getData(pagination.current, pagination.pageSize);
   };

   useEffect(() => {
      if (product?.id) {
         getData();
      }
      getData(1, data.paging.pageSize);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [product]);

   return (
      <>
         <Table
            loading={isLoading}
            onChange={handleTableChange}
            pagination={data?.paging}
            dataSource={data?.dataSource}
            columns={columns}
         />
         <Modal
            title={itemSelected?.description}
            footer={false}
            visible={itemSelected}
            onCancel={() => setItemSelected(null)}
            width="50%"
         >
            <Table
               pagination={false}
               dataSource={itemSelected?.trackingItems}
               columns={columnsDetail}
            />
         </Modal>
      </>
   );
}

export default History;
