import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router";
import { Spin } from "antd";
import { useUserActions } from "@iso/actions";
import { useHistory } from "react-router-dom";
import firebase from "firebase";
import ProductManager from "@iso/pages/ProductManager/index";
import ProfileSetting from "@iso/pages/ProfileSetting/index";
import PrivateRoute from "./PrivateRoute/index";
import Login from "@iso/pages/Login/index";
import StaffSetting from "@iso/pages/StaffSetting/index";
import GroupRole from "@iso/pages/GroupRole/index";
import GroupRoleDetail from "@iso/pages/GroupRole/GroupRoleDetail";
import AssignStore from "@iso/pages/StaffSetting/AssignStore";
import GroupRoleStaff from "@iso/pages/StaffSetting/GroupRoleStaff";
import Auth from "@iso/pages/Auth/index";
import Register from "@iso/pages/Register/index";
import Cookies from "universal-cookie";
import ChooseSystem from "@iso/pages/ChooseSystem/index";
import StoreManager from "@iso/pages/StoreManager/index";
import LangManager from "@iso/pages/LangManager/index";
import MenuItemManager from "@iso/pages/MenuItemManager/index";
import ClearCache from "@iso/pages/ClearCache/index";
import AddEditProduct from "@iso/pages/ProductManager/AddEditProduct";
import ProductDetail from "@iso/pages/ProductManager/ProductDetail/index";
import StoreDetail from "@iso/pages/StoreManager/StoreDetail";
import AddOnManager from "@iso/pages/AddOnManager";
import AttributeManagement from "@iso/pages/AttributeManager";
import { getStorage, removeStorage, saveStorage } from "../helpers";
import * as moment from "moment";
import ScheduleSurchargeManager from "@iso/pages/ScheduleSurchargeManager";
import { API_FEEDBACK } from "../config";
import BannerManager from "@iso/pages/BannerManager";
import AddEditBanner from "@iso/pages/BannerManager/AddEditBanner";

function AppRoutes() {
   const cookies = new Cookies();
   const [isLoading, setIsLoading] = useState(true);
   const userActions = useUserActions();
   const history = useHistory();

   useEffect(() => {
      document
         .getElementById("my-manifest-placeholder")
         .setAttribute(
            "href",
            `${API_FEEDBACK}/webstore/manifest?st=4ps&url=${window.location.origin}&startUrl=${window.location.origin}&name=BO Pizza 4P's`
         );
   }, []);

   const handleLogout = async () => {
      removeStorage("session");
      cookies.remove("token");
      cookies.remove("refreshToken");
      cookies.remove("email");
      cookies.remove("uid_firebase");
      cookies.remove("store_id");
      cookies.remove("photoURL");
      await firebase.auth().signOut();
      history.push("/");
   };

   const createSesstionCookie = async (user) => {
      if (!user) {
         handleLogout();
      }
      const idToken = await user.getIdToken();
      if (idToken) {
         cookies.set("token", idToken, {
            path: "/",
         });
         const session = getStorage("session");
         const expiresDay = moment(session?.expiresIn).format("DD-MM-YYYY");
         const toDay = moment().format("DD-MM-YYYY");
         if (session && expiresDay > toDay) return;
         try {
            const resCreate = await userActions.createSessionCookie({
               token: idToken,
            });
            if (resCreate) {
               saveStorage("session", resCreate);
            }
         } catch (error) {
            console.log(error);
         }
      }
   };

   const handleRelogin = async () => {
      const session = getStorage("session");
      if (!session) {
         handleLogout();
         setIsLoading(false);
         userActions.resetInfo();
         return;
      }
      console.log(session, "session");
      try {
         const resCreate = await userActions.createCustomToken({
            sessionCookie: session?.sessionCookie,
         });
         if (resCreate) {
            firebase
               .auth()
               .signInWithCustomToken(resCreate?.customToken)
               .then(() => {
                  userActions.getInfo().finally(() => {
                     setIsLoading(false);
                  });
               })
               .catch((error) => {
                  throw error;
               });
         }
      } catch (error) {
         handleLogout();
         setIsLoading(false);
         userActions.resetInfo();
         return;
      }
   };

   useEffect(() => {
      const unregisterAuthObserver = firebase
         .auth()
         .onAuthStateChanged((user) => {
            console.log(user, "onAuthStateChanged");
            if (user) {
               cookies.set("refreshToken", user.refreshToken, {
                  path: "/",
               });

               cookies.set("email", user.email, {
                  path: "/",
               });
               cookies.set("uid_firebase", user.uid, {
                  path: "/",
               });
               createSesstionCookie(user);

               userActions.getInfo().finally(() => {
                  setIsLoading(false);
               });
            } else {
               handleRelogin();
            }
         });

      return () => {
         unregisterAuthObserver();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   if (isLoading) {
      return (
         <div className="w-full h-screen flex items-center justify-center">
            <Spin size="large" />
         </div>
      );
   } else {
      return (
         <Switch>
            <Route path="/" exact={true} component={Auth} />
            <Route path="/login" exact={true} component={Login} />
            <Route path="/register" exact={true} component={Register} />
            <PrivateRoute
               path="/product"
               exact={true}
               component={<ProductManager />}
            />
            <PrivateRoute
               path="/product/edit-product/:id"
               exact={true}
               component={<AddEditProduct />}
            />
            <PrivateRoute
               path="/product/add-product"
               exact={true}
               component={<AddEditProduct />}
            />
            <PrivateRoute
               path="/product/product-detail"
               exact={true}
               component={<ProductDetail />}
            />

            <PrivateRoute
               path="/product/add-on/:id"
               exact={true}
               component={<AddOnManager />}
            />
            <PrivateRoute
               path="/product/add-on"
               exact={true}
               component={<AddOnManager />}
            />
            <PrivateRoute
               path="/product/attribute"
               exact={true}
               component={<AttributeManagement />}
            />
            <PrivateRoute
               path="/store"
               exact={true}
               component={<StoreManager />}
            />
            <PrivateRoute
               path="/store/detail/:id"
               exact={true}
               component={<StoreDetail />}
            />
            <PrivateRoute
               path="/lang"
               exact={true}
               component={<LangManager />}
            />
            <PrivateRoute
               path="/menu-item"
               exact={true}
               component={<MenuItemManager />}
            />
            <PrivateRoute
               path="/clear-cache"
               exact={true}
               component={<ClearCache />}
            />
            <PrivateRoute
               path="/choose-system"
               exact={true}
               component={<ChooseSystem />}
            />
            <PrivateRoute
               path="/profile-setting"
               exact={true}
               component={<ProfileSetting />}
            />
            <PrivateRoute
               path="/root/staff-setting"
               exact={true}
               component={<StaffSetting />}
            />
            <PrivateRoute
               path="/root/staff-setting/assign-store/:id"
               exact={true}
               component={<AssignStore />}
            />
            <PrivateRoute
               path="/root/staff-setting/group-role/:id"
               exact={true}
               component={<GroupRoleStaff />}
            />
            <PrivateRoute
               path="/root/group-role"
               exact={true}
               component={<GroupRole />}
            />
            <PrivateRoute
               path="/root/group-role/:id"
               exact={true}
               component={<GroupRoleDetail />}
            />
            <PrivateRoute
               path="/root/group-role/:id"
               exact={true}
               component={<GroupRoleDetail />}
            />
            <PrivateRoute
               path="/schedule-surcharge"
               exact={true}
               component={<ScheduleSurchargeManager />}
            />
            <PrivateRoute
               path="/banner"
               exact={true}
               component={<BannerManager />}
            />
            <PrivateRoute
               path="/banner/edit-banner/:id"
               exact={true}
               component={<AddEditProduct />}
            />
            <PrivateRoute
               path="/banner/add-banner"
               exact={true}
               component={<AddEditBanner />}
            />
         </Switch>
      );
   }
}

export default AppRoutes;
