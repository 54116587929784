import { useProductManagerActions } from "@iso/actions/productManager.actions";
import { useStoreManagerActions } from "@iso/actions/storeManager.actions";
import {
   Form,
   Input,
   message,
   Popconfirm,
   Radio,
   Select,
   Spin,
   Collapse,
   Divider,
} from "antd";
import { useEffect, useState } from "react";
import AsyncButton from "component/AsyncButton";

import styled from "styled-components";

const WrapperForm = styled(Form)`
   .ant-form-item-label {
      min-width: 200px;
      text-align: left;
   }
`;

const CustomStyle = styled.div`
   .ant-checkbox-wrapper {
      margin-bottom: 8px;
      white-space: nowrap;
   }
   .ant-collapse {
      width: 100%;
      margin-bottom: 16px;
   }
   .ant-collapse-header {
      align-items: center !important;
      h2 {
         margin-bottom: 0;
      }
   }
   h3 {
      width: 100%;
   }
`;

const { Panel } = Collapse;

const { Option } = Select;

function Availability(props) {
   const { product, currentTab } = props;
   const productManagerActions = useProductManagerActions();
   const [form] = Form.useForm();
   const [data, setData] = useState([]);
   const [dataCategory, setDataCategory] = useState({
      list: [],
      selected: null,
   });
   const [isLoading, setIsLoading] = useState({
      getDataProduct: false,
      getDataStation: false,
   });
   const [isSubmitting, setIsSubmitting] = useState(false);
   const [checkedList, setCheckedList] = useState({});

   const getDataProduct = async () => {
      if (product?.id) {
         setIsLoading((state) => ({ ...state, getDataProduct: true }));
         try {
            const resGet = await productManagerActions.getProductDetail(
               product?.id
            );
            resGet.productId = resGet?.id;
            resGet.category_id = resGet?.category?.id;
            resGet.class_id = resGet?.class?.id;
            form.setFieldsValue(resGet);

            const cate = dataCategory?.list?.find((category) => {
               return category?.id === resGet?.category?.id;
            });
            setDataCategory((state) => ({ ...state, selected: cate }));
         } finally {
            setIsLoading((state) => ({ ...state, getDataProduct: false }));
         }
      } else {
         form.resetFields();
      }
   };

   const getDataStation = async () => {
      try {
         setIsLoading((state) => ({ ...state, getDataStation: true }));
         const resGet = await productManagerActions.getStationsAssignItem({
            item_id: product?.id,
         });
         setData(resGet);
         const defaultChecked = {};
         resGet?.forEach((region) => {
            region?.stores?.forEach((store) => {
               const station = store?.stations?.find((x) => x.isActive);
               defaultChecked[store?.id?.toString()] = station?.id || null;
            });
         });
         setCheckedList(defaultChecked);
      } finally {
         setIsLoading((state) => ({ ...state, getDataStation: false }));
      }
   };

   const onStationClick = (storeId, stationId) => {
      const currentStationId = checkedList[storeId];
      const currentChecked = { ...checkedList };
      console.log(stationId, currentStationId);
      if (stationId === currentStationId) {
         currentChecked[storeId] = null;
      } else {
         currentChecked[storeId] = stationId;
      }
      setCheckedList(currentChecked);
   };

   const handleUpdate = async () => {
      const stationIds = [];
      for (const storeId in checkedList) {
         if (checkedList[storeId]) stationIds.push(checkedList[storeId]);
      }
      setIsSubmitting(true);
      try {
         await productManagerActions.updateStationsAssignItem(product?.id, {
            station_ids: stationIds,
         });
         message.success("Update item station success!");
      } finally {
         setIsSubmitting(false);
      }
   };

   useEffect(() => {
      if (product?.id) {
         getDataProduct();
         getDataStation();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [product, currentTab]);

   return (
      <>
         <Spin spinning={isLoading?.getDataProduct}>
            <div className="p-8 mt-8 bg-white-0 rounded-sm shadow-sm">
               <WrapperForm name="product-form" form={form}>
                  <div className="grid grid-cols-2 gap-20">
                     <div>
                        <Form.Item name="productId" label="Product Id">
                           <Input disabled placeholder="Product Id" />
                        </Form.Item>
                        <Form.Item name="category_id" label="Category">
                           <Select disabled placeholder="Category">
                              {dataCategory?.list?.map((category) => (
                                 <Option
                                    key={category?.id}
                                    value={category?.id}
                                 >
                                    {category?.name}
                                 </Option>
                              ))}
                           </Select>
                        </Form.Item>
                     </div>
                     <div>
                        <Form.Item name="name" label="Name">
                           <Input disabled placeholder="Name" />
                        </Form.Item>
                        <Form.Item name="class_id" label="Class">
                           <Select placeholder="Class" disabled>
                              {dataCategory.selected?.classCate?.map((cl) => (
                                 <Option key={cl?.id} value={cl?.id}>
                                    {cl?.name}
                                 </Option>
                              ))}
                           </Select>
                        </Form.Item>
                     </div>
                  </div>
               </WrapperForm>
            </div>
         </Spin>
         <Spin spinning={isLoading?.getDataStation}>
            <CustomStyle className="mt-8">
               <Collapse>
                  {data?.map((region) => (
                     <Panel
                        header={
                           <h2 className="uppercase">{`${region?.country} - ${region?.cityname}`}</h2>
                        }
                        key={region?.id}
                     >
                        {region?.stores?.map((store, index) => (
                           <div key={store?.id}>
                              {index !== 0 && <Divider />}
                              <h3 className="capitalize">{store?.full_name}</h3>
                              <Radio.Group
                                 value={checkedList[store?.id]}
                                 style={{ width: "100%" }}
                              >
                                 <div className="flex flex-wrap">
                                    {store?.stations?.map((station) => (
                                       <div
                                          key={station?.id}
                                          className="flex w-1/3 my-4"
                                       >
                                          <Radio
                                             style={{
                                                display: "flex",
                                                marginBottom: 0,
                                             }}
                                             value={station?.id}
                                             onClick={() =>
                                                onStationClick(
                                                   store?.id,
                                                   station?.id
                                                )
                                             }
                                          >
                                             <h4 className="capitalize mb-0">
                                                {station?.name}
                                             </h4>
                                          </Radio>
                                       </div>
                                    ))}
                                 </div>
                              </Radio.Group>
                           </div>
                        ))}
                     </Panel>
                  ))}
               </Collapse>
            </CustomStyle>
         </Spin>
         {data?.length > 0 && (
            <div className="mt-8 bg-white-0 p-8 text-right flex justify-end shadow-sm">
               <AsyncButton
                  isLoading={isSubmitting}
                  type="primary"
                  onClick={() => handleUpdate()}
                  className="ml-16"
               >
                  Submit
               </AsyncButton>
            </div>
         )}
      </>
   );
}

export default Availability;
