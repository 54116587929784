export * from "./history";

export const saveStorage = (name, data) => {
   localStorage.setItem(name, JSON.stringify(data));
};

export const getStorage = (name) => {
   return JSON.parse(localStorage.getItem(name));
};

export const removeStorage = (name) => {
   localStorage.removeItem(name);
};
