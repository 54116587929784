import { Popconfirm, Table, Tag } from "antd";
import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import { useState } from "react";
import { arrayMoveImmutable } from "array-move";
import {
   SortableContainer,
   SortableElement,
   SortableHandle,
} from "react-sortable-hoc";
import AsyncButton from "component/AsyncButton";
import styled from "styled-components";
import * as CONFIG from "@iso/config/index";

const TableWrapper = styled(Table)`
   .ant-table-row.selected {
      background-color: #d2d2d2;
      .ant-table-cell-row-hover {
         background: #d2d2d2 !important;
      }
   }
`;

function ItemDefineAddOnTable(props) {
   const [isSubmitting, setIsSubmitting] = useState({});

   const onSortEnd = ({ oldIndex, newIndex }) => {
      if (oldIndex !== newIndex) {
         const newData = arrayMoveImmutable(
            props?.dataItemDefineAddOn?.slice(),
            oldIndex,
            newIndex
         ).filter((el) => !!el);
         props?.setDataItemDefineAddOn(newData);
         props?.onUpdateSorting(newData?.map((item) => item?.id));
      }
   };

   const DragHandle = SortableHandle(() => (
      <MenuOutlined
         style={{
            cursor: "grab",
            color: "#999",
         }}
      />
   ));

   const SortableItem = SortableElement((props) => <tr {...props} />);
   const SortableBody = SortableContainer((props) => <tbody {...props} />);

   const DraggableContainer = (props) => (
      <SortableBody
         useDragHandle
         disableAutoscroll
         helperClass="row-dragging"
         onSortEnd={onSortEnd}
         {...props}
      />
   );

   const DraggableBodyRow = ({ className, style, ...restProps }) => {
      const index = props?.dataItemDefineAddOn?.findIndex(
         (x) => x.id === restProps["data-row-key"]
      );

      return (
         <SortableItem
            className={`${className} cursor-pointer ${
               props?.selectedId === restProps["data-row-key"] ? "selected" : ""
            }`}
            index={index}
            {...restProps}
         />
      );
   };

   const columns = [
      {
         title: "Sort",
         dataIndex: "id",
         className: "drag-visible",
         render: () => <DragHandle />,
      },
      {
         title: "Name",
         dataIndex: ["addOn", "name"],
         key: "name",
         className: "drag-visible",
      },
      {
         title: "Type",
         dataIndex: ["addOn", "type"],
         key: "type",
         align: "center",
         className: "drag-visible",
         render: (record) => (
            <span className="uppercase">
               {record === CONFIG.ADD_ON_TYPE.COMBO ? (
                  <Tag color="green">{CONFIG.ADD_ON_TYPE_NAME.COMBO}</Tag>
               ) : record === CONFIG.ADD_ON_TYPE.SINGLE ? (
                  <Tag color="orange">{CONFIG.ADD_ON_TYPE_NAME.SINGLE}</Tag>
               ) : (
                  <Tag color="blue">{CONFIG.ADD_ON_TYPE_NAME.MULTIPLE}</Tag>
               )}
            </span>
         ),
      },
      {
         title: "Max Selection",
         dataIndex: ["addOn", "max_selection"],
         align: "center",
         key: "max_selection",
         className: "drag-hiden",
         render: (record) => (
            <span>
               {record === CONFIG.UNLIMITED_ADD_ON_SELECTION
                  ? "Unlimited"
                  : record}
            </span>
         ),
      },
      {
         align: "center",
         className: "drag-hiden",
         render: (record) => (
            <Popconfirm
               title="Are you sure to delete this Item define Add-On?"
               onConfirm={() => handleDeleteItemDefineAddOn(record["id"])}
               placement="bottom"
               okText="Yes"
               cancelText="No"
            >
               <AsyncButton
                  onClick={(e) => {
                     e.stopPropagation();
                  }}
                  onlyIcon
                  isLoading={isSubmitting[record["id"].toString()] || false}
                  type="primary"
                  style={{
                     display: "flex",
                     alignItems: "center",
                     margin: "0 auto",
                  }}
               >
                  <div className="flex items-center">
                     <CloseOutlined />
                  </div>
               </AsyncButton>
            </Popconfirm>
         ),
      },
   ];

   const handleDeleteItemDefineAddOn = async (id) => {
      try {
         const currentIsSubmitting = { ...isSubmitting };
         currentIsSubmitting[`${id}`] = true;
         setIsSubmitting(currentIsSubmitting);
         await props?.onDeleteItemDefineAddOn(id);
      } finally {
         const currentIsSubmitting = { ...isSubmitting };
         delete currentIsSubmitting[id];
         setIsSubmitting(currentIsSubmitting);
      }
   };

   return (
      <TableWrapper
         loading={props?.isLoading}
         dataSource={props?.dataItemDefineAddOn}
         columns={columns}
         pagination={false}
         rowKey="id"
         components={{
            body: {
               wrapper: DraggableContainer,
               row: DraggableBodyRow,
            },
         }}
         onRow={(record) => {
            return {
               onClick: () =>
                  props?.setAddOnSelected({
                     id: record?.addon_id,
                     name: record?.addOn?.name,
                     rowKey: record?.id,
                  }),
            };
         }}
      />
   );
}

export default ItemDefineAddOnTable;
