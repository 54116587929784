import React, { useState, useEffect } from "react";
import { Select, Spin } from "antd";

const { Option } = Select;

const SelectSearch = ({
   onSearch,
   placeholder,
   loading,
   options,
   onChange,
   value,
}) => {
   const [debounceTimeout, setDebounceTimeout] = useState(null);

   const handleSearch = (value) => {
      if (debounceTimeout) {
         clearTimeout(debounceTimeout);
      }

      const timeout = setTimeout(() => {
         onSearch(value);
      }, 500);

      setDebounceTimeout(timeout);
   };

   useEffect(() => {
      return () => {
         if (debounceTimeout) {
            clearTimeout(debounceTimeout);
         }
      };
   }, [debounceTimeout]);

   return (
      <Select
         showSearch
         value={value}
         placeholder={placeholder}
         defaultActiveFirstOption={true}
         filterOption={false}
         onSearch={handleSearch}
         onChange={onChange}
         notFoundContent={loading ? <Spin size="small" /> : null}
      >
         {options.map((option) => (
            <Option key={option?.value}>{option?.label}</Option>
         ))}
      </Select>
   );
};

export default SelectSearch;
