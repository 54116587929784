import { API_ENDPOINT } from "@iso/config/index";
import { axiosClient } from "@iso/helpers/axios.config";

const LANG_ENDPONT = `${API_ENDPOINT}/item-lang`;
const DEVICE_ENDPONT = `${API_ENDPOINT}/device`;

const itemLangManagerApi = {
   getDevices: () => {
      return axiosClient.get(DEVICE_ENDPONT);
   },
   get: (params) => {
      return axiosClient.get(LANG_ENDPONT, { params });
   },

   post: (body) => {
      return axiosClient.post(LANG_ENDPONT, body);
   },

   put: (id, body) => {
      return axiosClient.put(`${LANG_ENDPONT}/${id}`, body);
   },
};

export default itemLangManagerApi;
