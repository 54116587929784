/* eslint-disable import/no-extraneous-dependencies */
import { VAPI_KEY_FIREBASE } from "@iso/config/index";
import firebase from "firebase/app";
import "firebase/auth";
import { firebaseConfig } from "../config/firebase";
import "firebase/messaging";

export const initFirebase = () => {
   if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
   }
};

export const getFirebaseToken = async () => {
   const { currentUser } = firebase.auth();
   if (currentUser) return currentUser.getIdToken();

   // time out 10s if too slow
   return new Promise((resolve, reject) => {
      const waitTimer = setTimeout(() => {
         reject();
      }, 10000);

      const unregisterAuthObserver = firebase
         .auth()
         .onAuthStateChanged(async (user) => {
            if (!user) {
               reject();
            }
            // Refresh token
            const token = await user.getIdToken();
            resolve(token);
            unregisterAuthObserver();
            clearTimeout(waitTimer);
         });
   });
};

if (!firebase.apps.length) {
   firebase.initializeApp(firebaseConfig);
} else {
   firebase.app(); // if already initialized, use that one
}

let messaging;

if (typeof window !== "undefined") {
   if (firebase.messaging.isSupported()) {
      console.log("firebase supported");
      messaging = firebase.messaging();
   }
}

export const getMessagingToken = async () => {
   let currentToken = "";
   // console.log(VAPI_KEY_FIREBASE, "VAPI_KEY_FIREBASE");
   if (!messaging) return;
   // console.log(messaging, "messaging getMessagingToken");
   try {
      currentToken = await messaging.getToken({
         vapidKey: VAPI_KEY_FIREBASE,
      });
      localStorage.setItem("fcm_token", currentToken);
      // console.log("FCM registration token", currentToken);
   } catch (error) {
      console.log("An error occurred while retrieving token. ", error);
   }
   return currentToken;
};

export const onMessageListener = () =>
   new Promise((resolve) => {
      messaging.onMessage((payload) => {
         resolve(payload);
      });
   });
