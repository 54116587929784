import { useState, useEffect } from "react";
import { Form, Input, message, Button, Modal } from "antd";
import { useLangManagerActions } from "@iso/actions/langManager.actions";
import styled from "styled-components";

const WrapperForm = styled(Form)`
   label {
      min-width: 200px;
   }
`;

function AddEditLangModal(props) {
   const [isLoading, setIsLoading] = useState(false);
   const [form] = Form.useForm();
   const langManagerActions = useLangManagerActions();

   useEffect(() => {
      props.modalLangState.data && getData(props.modalLangState.data);
      if (props.modalLangState.visible === false) {
         form.resetFields();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [props.modalLangState.visible]);

   const getData = async (data) => {
      setIsLoading(true);
      try {
         if (data) {
            form.setFieldsValue(data);
         }
      } catch (error) {
         console.log(error);
      } finally {
         setIsLoading(false);
      }
   };

   const onFinish = async (values) => {
      setIsLoading(true);
      try {
         props.modalLangState.data
            ? await langManagerActions.editLang(
                 props.modalLangState.data?.id,
                 values
              )
            : await langManagerActions.addLang(values);
         message.success(
            `${props.modalLangState.data?.id ? "Edit" : "Add"} lang success!`
         );
         !props.modalLangState.data?.id && form.resetFields();
         props.handleToggleModalLangState(false);
         props.getData();
      } catch (error) {
         console.log(error);
      } finally {
         setIsLoading(false);
      }
   };

   return (
      <Modal
         title={`${props.modalLangState.data?.id ? "Edit" : "Add"} Languge`}
         visible={props.modalLangState.visible}
         footer={null}
         onCancel={() => props.handleToggleModalLangState(false)}
      >
         <WrapperForm name="add-edit-lang-form" onFinish={onFinish} form={form}>
            <Form.Item
               name="name"
               label="Name"
               rules={[
                  {
                     required: true,
                     message: "Lang name is required!",
                  },
               ]}
            >
               <Input placeholder="Lang name" />
            </Form.Item>
            <Form.Item
               name="iso_code"
               label="Iso Code"
               rules={[
                  {
                     required: true,
                     message: "IOS code is required!",
                  },
               ]}
            >
               <Input placeholder="IOS code" />
            </Form.Item>
            <Form.Item
               name="currency_code"
               label="Currency Code"
               rules={[
                  {
                     required: true,
                     message: "Currency code is required!",
                  },
               ]}
            >
               <Input placeholder="Currency code" />
            </Form.Item>
            <Form.Item
               name="currency_conversion_rate"
               label="Currency Conversion Rate"
               rules={[
                  {
                     required: true,
                     message: "Currency conversion rate is required!",
                  },
               ]}
            >
               <Input placeholder="Currency conversion rate" type="number" />
            </Form.Item>
            <Form.Item className="text-center" style={{ marginBottom: 0 }}>
               <Button
                  loading={isLoading}
                  type="primary"
                  htmlType="submit"
                  disabled={isLoading}
               >
                  Submit
               </Button>
            </Form.Item>
         </WrapperForm>
      </Modal>
   );
}

export default AddEditLangModal;
