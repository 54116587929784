import { message, Upload } from "antd";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { PlusOutlined } from "@ant-design/icons";

function UploadFile(props) {
   const [imageUrl, setImageUrl] = useState(props?.imageUrl || null);

   useEffect(() => {
      setImageUrl(props?.imageUrl);
   }, [props?.imageUrl]);

   const getBase64 = (img, callback) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => callback(reader.result));
      reader.readAsDataURL(img);
   };

   const beforeUpload = (file) => {
      const isJpgOrPng =
         file?.type === "image/jpeg" || file?.type === "image/png";
      if (!isJpgOrPng) {
         message.error("You can only upload JPG/PNG file!");
      }
      const isLt10M = file?.size / 1024 / 1024 < 10;
      if (!isLt10M) {
         message.error("Image must smaller than 10MB!");
      }
      return isJpgOrPng && isLt10M;
   };

   const handleChange = (info) => {
      getBase64(info?.file?.originFileObj, (url) => {
         setImageUrl(url);
      });
      props.onChange(info?.file?.originFileObj);
   };

   return (
      <WrapperUpload
         name="avatar"
         method="GET"
         listType="picture-card"
         showUploadList={false}
         beforeUpload={beforeUpload}
         onChange={handleChange}
         accept="image/*"
      >
         {imageUrl ? (
            <img src={imageUrl} alt="avatar" />
         ) : (
            <div>
               <PlusOutlined />
               <div style={{ marginTop: 8 }}>Upload</div>
            </div>
         )}
      </WrapperUpload>
   );
}

export default UploadFile;

export const WrapperUpload = styled(Upload)`
   .ant-upload {
      height: 300px !important;
      width: 100% !important;
   }

   img {
      height: 300px;
      width: 100%;
      object-fit: contain;
      padding: 10px;
   }
`;
