import { API_ENDPOINT } from "@iso/config/index";
import { axiosClient } from "@iso/helpers/axios.config";

const url = `${API_ENDPOINT}/category-item`;

const categoryApi = {
   get: (params) => {
      return axiosClient.get(url, { params });
   },
};

export default categoryApi;
