import React from "react";
import { Spin } from "antd";

function Loader() {
   return (
      <div
         className="fixed top-0 min-h-screen min-w-full flex items-center justify-center opacity-40 bg-white-0"
         style={{ zIndex: "99999" }}
      >
         <Spin size="large" />
      </div>
   );
}

export default Loader;
