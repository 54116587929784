import { Table, Button, Popconfirm, Tag, Form, Input } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useRecoilValue } from "recoil";
import { userAtom } from "@iso/state/user";
import AsyncButton from "component/AsyncButton";
import { SearchOutlined } from "@ant-design/icons";

const BannerListing = (props) => {
   const {
      data,
      onActionForm,
      onFilter,
      onDelete,
      isLoadingList,
      isLoadingDelete,
   } = props;

   const user = useRecoilValue(userAtom);
   const [form] = Form.useForm();

   const checkRoles = (roles) => {
      const userRoles = user?.roles || [];
      return roles.every((role) => userRoles.includes(role));
   };

   const handleTableChange = (pagination) => {
      onFilter({
         current: pagination.current,
         pageSize: pagination.pageSize,
      });
   };

   const columns = [
      {
         title: "Name",
         dataIndex: "name",
         key: "name",
         render: (_, record) => <span>{record?.name}</span>,
      },
      {
         title: "Image",
         dataIndex: "image",
         key: "image",
         render: (_, record) => (
            <img
               width={50}
               height={50}
               src={record?.image_url}
               alt={record?.name}
            />
         ),
      },
      {
         title: "General URL",
         dataIndex: "general_url_direction",
         key: "general_url_direction",
         width: "25%",
      },
      {
         title: "Style Type",
         key: "style_type",
         render: (_, record) => (
            <Tag
               className="capitalize"
               color={record?.style_type == "horizontal" ? "green" : "blue"}
            >
               <span className="capitalize">{record?.style_type}</span>
            </Tag>
         ),
      },
      {
         title: "View Type",
         key: "view_type",
         render: (_, record) => (
            <Tag
               className="capitalize"
               color={record?.view_type === "popup" ? "green" : "blue"}
            >
               <span className="capitalize">{record?.view_type}</span>
            </Tag>
         ),
      },
      {
         title: "Active",
         key: "is_active",
         render: (_, record) => (
            <Tag color={record?.is_active ? "green-inverse" : "red-inverse"}>
               {record?.is_active ? "Active" : "Inactive"}
            </Tag>
         ),
      },
      {
         align: "center",
         fixed: "right",
         render: (_, record) => (
            <div className="flex gap-3 justify-center">
               {checkRoles(["store_edit"]) && (
                  <>
                     <Button
                        onClick={() => onActionForm(record)}
                        type="primary"
                     >
                        Edit
                     </Button>
                     <Popconfirm
                        title="Are you sure to delete this item?"
                        onConfirm={() => onDelete(record["id"])}
                        placement="bottom"
                        okText="Yes"
                        cancelText="No"
                     >
                        <AsyncButton
                           isLoading={isLoadingDelete === record["id"]}
                           type="primary"
                           style={{ marginLeft: 8 }}
                        >
                           Delete
                        </AsyncButton>
                     </Popconfirm>
                  </>
               )}
            </div>
         ),
      },
   ];

   const handleSearch = (values) => {
      onFilter({
         ...values,
         current: 1,
         pageSize: data?.paging?.pageSize,
      });
   };

   return (
      <div>
         <div className="flex items-start justify-between mb-8">
            <div className="flex gap-4">
               <Form className="flex gap-6" form={form} onFinish={handleSearch}>
                  <Form.Item
                     style={{ marginBottom: 0 }}
                     className="mb-0"
                     name={"search"}
                     label="Search"
                  >
                     <Input placeholder="Search..." />
                  </Form.Item>
                  <Form.Item style={{ marginBottom: 0 }}>
                     <Button type="primary" htmlType="submit">
                        <p className="flex items-center">
                           <SearchOutlined />
                           <span className="ml-2">Submit</span>
                        </p>
                     </Button>
                  </Form.Item>
               </Form>
            </div>
            {checkRoles(["store_add"]) && (
               <Button type="primary" onClick={() => onActionForm({})}>
                  <p className="flex items-center">
                     <PlusOutlined />
                     <span className="ml-2">Add Item</span>
                  </p>
               </Button>
            )}
         </div>

         <Table
            columns={columns}
            rowKey="key"
            dataSource={data?.dataSource}
            onChange={handleTableChange}
            pagination={{
               current: data?.paging.current,
               total: data?.paging?.total,
               pageSize: data?.paging?.pageSize,
            }}
            loading={isLoadingList}
         />
      </div>
   );
};

export default BannerListing;
