import storeManagerApi from "api/storeManagerApi";

export { useStoreManagerActions };

function useStoreManagerActions() {
   const getFilterProperties = async () => {
      try {
         return await storeManagerApi.getFilterProperties();
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const getOptionsProperty = async (params) => {
      try {
         return await storeManagerApi.getOptionsProperty({
            ...params,
         });
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const getAllStore = async () => {
      try {
         const resGet = await storeManagerApi.getAll();
         if (resGet) {
            return {
               status: true,
               data: resGet,
            };
         }
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const getStoreList = async (params) => {
      try {
         return await storeManagerApi.gets(params);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const getRegions = async () => {
      try {
         return await storeManagerApi.getRegions();
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const getBrands = async () => {
      try {
         return await storeManagerApi.getBrands();
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const getStoreDetail = async (id) => {
      try {
         return await storeManagerApi.get(id);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const addStore = async (body) => {
      try {
         return await storeManagerApi.post(body);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const addStoreKVS = async (id) => {
      try {
         return await storeManagerApi.postStoreKVS(id);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const editStore = async (id, body) => {
      try {
         return await storeManagerApi.put(id, body);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const deleteStore = async (id) => {
      try {
         return await storeManagerApi.delete(id);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const deleteStoreKVS = async (id) => {
      try {
         return await storeManagerApi.deleteStoreKVS(id);
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   const getDeviceList = async () => {
      try {
         return await storeManagerApi.getDevices();
      } catch (error) {
         console.log(error);
         throw error;
      }
   };

   return {
      getFilterProperties,
      getOptionsProperty,

      getRegions,
      getAllStore,
      getStoreList,
      addStore,
      addStoreKVS,
      editStore,
      getStoreDetail,
      deleteStore,
      deleteStoreKVS,
      getBrands,
      getDeviceList,
   };
}
