import { useState } from "react";
import { Table, Button, message } from "antd";
import Page from "component/Page/index";
import { PlayCircleOutlined } from "@ant-design/icons";
import { useCommonManagerActions } from "@iso/actions/commonManager.actions";

const ClearCache = () => {
   const [loadActive, setLoadActive] = useState({ loading: false, type: "" });
   const { clearCacheKMS } = useCommonManagerActions();
   const [data, setData] = useState({
      dataSource: [
         {
            title: "[KMS] Clear temporary memory",
            type: "kms",
         },
      ],
      paging: {
         current: 1,
         total: 0,
         pageSize: 10,
      },
   });

   const handleClearCacheKMS = async () => {
      try {
         await clearCacheKMS();
         message.success("Clear cache successfully");
         setLoadActive({ loading: false, type: "" });
      } catch (err) {
         message.error("Clear cache failed");
         setLoadActive({ loading: false, type: "" });
      }
   };

   const handleClearCache = (type) => {
      setLoadActive({ loading: true, type });
      switch (type) {
         case "kms":
            return handleClearCacheKMS();
         default:
            setLoadActive({ loading: false, type });
            return;
      }
   };

   const columns = [
      {
         title: "Title",
         dataIndex: "title",
         key: "title",
      },
      {
         title: "Action",
         width: "10%",
         align: "center",
         fixed: "right",
         render: (_, record) => (
            <div className="flex justify-center gap-3">
               <Button
                  loading={
                     loadActive?.loading && loadActive?.type === record?.type
                  }
                  onClick={() => handleClearCache(record?.type)}
                  icon={<PlayCircleOutlined />}
                  type="primary"
               ></Button>
            </div>
         ),
      },
   ];

   return (
      <Page title="Clear Cache Management">
         <Table
            columns={columns}
            rowKey="key"
            dataSource={data.dataSource}
            pagination={null}
            loading={false}
         />
      </Page>
   );
};

export default ClearCache;
