import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { initFirebase } from "./utils/firebase";
import "@iso/assets/style/antd.scss";
import "./index.css";
import AppRoutes from "./Routes";
import * as serviceWorkerRegistration from "./firebase-messaging-sw";

initFirebase();
serviceWorkerRegistration.register();

ReactDOM.render(
   <React.StrictMode>
      <RecoilRoot>
         <BrowserRouter basename="/mms">
            <AppRoutes />
         </BrowserRouter>
      </RecoilRoot>
   </React.StrictMode>,
   document.getElementById("app")
);
