import { useState, useEffect } from "react";
import { Table, Button, Popconfirm, message } from "antd";
import { useStoreManagerActions } from "@iso/actions/storeManager.actions";
import { PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import Page from "component/Page/index";
import useAuthor from "hooks/useAuthor";
import AddStore from "./AddStore";
import FilterGroup from "component/FilterGroup";
import AsyncButton from "component/AsyncButton";

const StoreManager = () => {
   const [isLoading, setIsLoading] = useState(true);
   const [data, setData] = useState({
      dataSource: [],
      paging: {
         current: 1,
         total: 0,
         pageSize: 10,
      },
   });
   const [isShowModalAdd, setIsShowModalAdd] = useState(false);
   const [currentFilters, setCurrentFilters] = useState({});
   const [isSubmitting, setIsSubmitting] = useState({});
   const { checkRoles } = useAuthor();
   const storeManagerActions = useStoreManagerActions();

   const columns = [
      {
         title: "Store code",
         dataIndex: "id",
         key: "id",
         align: "center",
         fixed: "left",
         width: 100,
      },
      {
         title: (
            <span className="text-center inline-block w-full">
               Store Full Name
            </span>
         ),
         dataIndex: "full_name",
         key: "full_name",
         fixed: "left",
         width: 170,
      },
      {
         title: "Brand",
         dataIndex: ["brands", "name"],
         key: "brand",
         align: "center",
      },
      {
         title: "Region",
         dataIndex: ["regions", "region"],
         key: "region",
         align: "center",
      },
      {
         title: "City",
         dataIndex: ["regions", "cityname"],
         key: "city",
         align: "center",
      },
      {
         title: "Store Manager",
         dataIndex: "RM",
         key: "RM",
         align: "center",
      },
      {
         title: "Area Manager",
         dataIndex: "AM",
         key: "AM",
         align: "center",
      },
      {
         title: "Table",
         dataIndex: "total_table_number",
         key: "total_table_number",
         align: "center",
      },
      {
         title: "Table",
         dataIndex: "total_seat_number",
         key: "total_seat_number",
         align: "center",
      },
      {
         title: "Actions",
         align: "center",
         fixed: "right",
         width: 170,
         render: (record) => (
            <div className="flex justify-center gap-3 px-4">
               <Link to={`/store/detail/${record["id"]}`}>
                  <Button type="primary">Detail</Button>
               </Link>
               {checkRoles(["store_delete"]) && (
                  <>
                     <Popconfirm
                        title="Are you sure to delete this store?"
                        onConfirm={() => handleDeleteStore(record["id"])}
                        placement="bottom"
                        okText="Yes"
                        cancelText="No"
                     >
                        <AsyncButton
                           isLoading={isSubmitting[record["id"]] || false}
                           type="primary"
                           style={{ marginLeft: 8 }}
                        >
                           Delete
                        </AsyncButton>
                     </Popconfirm>
                  </>
               )}
            </div>
         ),
      },
   ];

   const getData = async (offset, limit, filters) => {
      setIsLoading(true);
      try {
         const dataGet = await storeManagerActions.getStoreList({
            page: offset,
            limit,
            ...filters,
         });
         if (dataGet) {
            setData({
               paging: {
                  current: dataGet?.page,
                  total: dataGet?.total,
                  pageSize: limit,
               },
               dataSource: dataGet?.docs,
            });
         }
      } catch (error) {
         console.log(error);
      } finally {
         setIsLoading(false);
      }
   };

   const handleTableChange = (pagination) => {
      getData(pagination.current, pagination.pageSize, currentFilters);
   };

   const handleDeleteStore = async (id) => {
      try {
         const currentIsSubmitting = { ...isSubmitting };
         currentIsSubmitting[`${id}`] = true;
         setIsSubmitting(currentIsSubmitting);
         await storeManagerActions.deleteStore(id);
         message.success("Delete store success!");
         handleTableChange(data.paging);
      } finally {
         const currentIsSubmitting = { ...isSubmitting };
         delete currentIsSubmitting[id];
         setIsSubmitting(currentIsSubmitting);
      }
   };

   useEffect(() => {
      getData(1, data.paging.pageSize, currentFilters);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <Page title="Store Management">
         <div className="staff-setting-wrapper">
            <div className="flex justify-between mb-8">
               <FilterGroup
                  currentFilters={currentFilters}
                  setCurrentFilters={setCurrentFilters}
                  onGetFilterProperties={
                     storeManagerActions.getFilterProperties
                  }
                  onGetOptionsProperty={storeManagerActions.getOptionsProperty}
                  onFilter={(filters) =>
                     getData(1, data?.paging?.pageSize, filters)
                  }
               />
               {checkRoles(["store_add"]) && (
                  <Button
                     type="primary"
                     onClick={() => setIsShowModalAdd(true)}
                  >
                     <p className="flex items-center">
                        <PlusOutlined />
                        <span className="ml-2">Add Store</span>
                     </p>
                  </Button>
               )}
            </div>
            <Table
               columns={columns}
               rowKey="key"
               dataSource={data.dataSource}
               onChange={handleTableChange}
               pagination={{
                  current: data.paging.current,
                  total: data.paging.total,
                  pageSize: data.paging.pageSize,
               }}
               loading={isLoading}
               scroll={{ x: 1200 }}
            />
            <AddStore
               visible={isShowModalAdd}
               onClose={() => setIsShowModalAdd(false)}
               getData={() => handleTableChange(data.paging)}
            />
         </div>
      </Page>
   );
};

export default StoreManager;
