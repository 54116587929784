import { PlusOutlined } from "@ant-design/icons";
import { Button, Checkbox } from "antd";
import { useEffect, useState } from "react";
import DropdownFilter from "component/DropdownFilter";
import Dropdown from "component/Dropdown";

function FilterGroup(props) {
   const [filterProperties, setFilterProperties] = useState([]);

   const getFilterProperties = async () => {
      try {
         const resGet = await props?.onGetFilterProperties();
         setFilterProperties(
            resGet?.map((property) => ({
               ...property,
               isActive: false,
            }))
         );
      } catch (error) {}
   };

   const handleFilterClick = (value) => {
      const currentFilterProperties = filterProperties;
      const index = currentFilterProperties?.findIndex(
         (property) => property?.value === value
      );
      if (index >= 0) {
         currentFilterProperties[index].isActive =
            !currentFilterProperties[index]?.isActive;
         setFilterProperties([...currentFilterProperties]);
      }
   };

   const handleFilter = async (property, filgerValues) => {
      const filters = { ...props?.currentFilters };
      if (filgerValues !== "") {
         filters[property] = filgerValues;
      } else {
         delete filters[property];
      }
      try {
         props?.onFilter(filters);
      } finally {
         props?.setCurrentFilters(filters);
      }
   };

   const ContentFilter = () => (
      <div style={{ width: "15rem" }}>
         {filterProperties?.map((property) => (
            <div
               key={property?.value}
               className="py-2 mx-4 cursor-pointer"
               onClick={() => handleFilterClick(property?.value)}
            >
               <Checkbox checked={property?.isActive} />
               <span className="capitalize ml-4">{property?.label}</span>
            </div>
         ))}
      </div>
   );

   useEffect(() => {
      getFilterProperties();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <div className="flex">
         <Dropdown content={ContentFilter()}>
            <Button>
               <div className="flex items-center">
                  <PlusOutlined />
                  <span className="ml-2">Add Filter</span>
               </div>
            </Button>
         </Dropdown>
         {filterProperties
            ?.filter((property) => property?.isActive)
            ?.map((property) => (
               <div key={property?.value} className="ml-4">
                  <DropdownFilter
                     title={property?.label}
                     onGetOptions={() =>
                        props?.onGetOptionsProperty({
                           property: property?.value,
                        })
                     }
                     onFilter={(filter) =>
                        handleFilter(property?.value, filter)
                     }
                  />
               </div>
            ))}
      </div>
   );
}

export default FilterGroup;
