import { userAtom } from "../state";
import { useRecoilValue } from "recoil";

export default function useAuthor() {
   const user = useRecoilValue(userAtom);

   const checkRoles = (roles) => {
      const userRoles = user?.roles || [];
      return roles.every((role) => userRoles.includes(role));
   };

   return { checkRoles };
}
