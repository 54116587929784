import React, { useState, useEffect } from "react";
import { Form, Input, Select, Radio, message, Button, Modal } from "antd";
import Page from "component/Page/index";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useLangManagerActions } from "@iso/actions/langManager.actions";
import { useStoreManagerActions } from "@iso/actions/storeManager.actions";
import { useMenuItemManagerActions } from "@iso/actions/menuItemManager.actions";

const WrapperForm = styled(Form)`
   label {
      min-width: 120px;
   }
`;

const STORE_MODE = ["", "full", "not_qc", "only_expeditor"];
const ITEM_TYPE = [
   {
      name: "FULL",
      value: 1,
   },
   {
      name: "HALF",
      value: 2,
   },
   {
      name: "ADDON",
      value: 3,
   },
   {
      name: "FEE",
      value: 4,
   },
];

function AddEditMenuItemModal(props) {
   const [isLoading, setIsLoading] = useState(false);
   const [form] = Form.useForm();
   const menuItemManagerActions = useMenuItemManagerActions();
   const langManagerActions = useLangManagerActions();
   const storeManagerActions = useStoreManagerActions();
   const [dataMenuDisplay, setDataMenuDisplay] = useState({
      search: null,
      data: [],
   });

   useEffect(() => {
      getDataSelect();
      props.modalMenuItemState.data && getData(props.modalMenuItemState.data);
      if (props.modalMenuItemState.visible == false) {
         form.resetFields();
      }
   }, [props.modalMenuItemState.visible]);

   const getData = async (data) => {
      const res = await menuItemManagerActions.getMenuItemDetail(
         props.modalMenuItemState.data.id
      );
      console.log(res);
      setIsLoading(true);
      try {
         if (data) {
            form.setFieldsValue(data);
            console.log(dataMenuDisplay);
         }
      } catch (error) {
         console.log(error);
      } finally {
         setIsLoading(false);
      }
   };

   const onFinish = async (values) => {
      setIsLoading(true);
      if (values?.is_recommended == "false") {
         values.is_recommended = false;
      } else {
         values.is_recommended = true;
      }
      try {
         const data = props.modalMenuItemState.data
            ? await menuItemManagerActions.editMenuItem(
                 props.modalMenuItemState.data?.id,
                 values
              )
            : await menuItemManagerActions.addMenuItem({
                 ...values,
                 item_type: parseInt(1),
              });
         if (data?.status != false) {
            message.success(
               `${
                  props.modalMenuItemState.data?.id ? "Edit" : "Add"
               } lang success!`
            );
            !props.modalMenuItemState.data?.id && form.resetFields();
            props.handleToggleModalLangState(false);
            props.getData();
         }
      } catch (error) {
         console.log(error);
      } finally {
         setIsLoading(false);
      }
   };

   const getDataSelect = async (searchStr) => {
      setIsLoading(true);
      try {
         const data = await menuItemManagerActions.getMenuDisplay(
            searchStr ? { search: searchStr } : {}
         );

         setDataMenuDisplay({ ...dataMenuDisplay, data: data?.data });
      } catch (error) {
         console.log(error);
      } finally {
         setIsLoading(false);
      }
   };

   const onSearchMenuDisplay = (val) => {
      setDataMenuDisplay({ ...dataMenuDisplay, search: val });
   };

   const onChangeMenuDisplay = (val) => {
      console.log("change:", val);
   };

   useEffect(() => {
      const delayDebounceFn = setTimeout(() => {
         if (dataMenuDisplay?.search != null || dataMenuDisplay?.search != "") {
            getDataSelect(dataMenuDisplay?.search);
         } else {
            getDataSelect();
         }
      }, 200);
      return () => clearTimeout(delayDebounceFn);
   }, [dataMenuDisplay?.search]);

   return (
      <Modal
         title={`${
            props.modalMenuItemState.data?.id ? "Edit" : "Add"
         } Menu Item`}
         visible={props.modalMenuItemState.visible}
         footer={null}
         onCancel={() => props.handleToggleModalLangState(false)}
         width={"60vw"}
      >
         <WrapperForm name="add-edit-lang-form" onFinish={onFinish} form={form}>
            <div className="grid grid-cols-2 gap-12">
               <Form.Item name="item_id" label="Item ID">
                  <Input />
               </Form.Item>
               <Form.Item name="menu_display_id" label="Menu Display ID">
                  <Select
                     defaultValue=""
                     placeholder="Search menu display"
                     showSearch
                     optionFilterProp="children"
                     onChange={onChangeMenuDisplay}
                     onSearch={onSearchMenuDisplay}
                     allowClear
                     autoClearSearchValue
                  >
                     {dataMenuDisplay &&
                        dataMenuDisplay?.data?.length > 0 &&
                        dataMenuDisplay?.data?.map((item, index) => (
                           <Select.Option key={index} value={item?.id}>
                              {item ? item?.name : ""}
                           </Select.Option>
                        ))}
                  </Select>
               </Form.Item>
            </div>
            <div className="grid grid-cols-2 gap-12">
               <Form.Item name="selling_price" label="Selling Price">
                  <Input />
               </Form.Item>
               <Form.Item
                  name="selling_tax_percentage"
                  label="Selling Tax Percentage"
               >
                  <Input />
               </Form.Item>
            </div>
            <div className="grid grid-cols-2 gap-12">
               <Form.Item name="quantity_in_stock" label="Quantity In Stock">
                  <Input />
               </Form.Item>
               <Form.Item name="quantity_sold" label="Quantity sold">
                  <Input />
               </Form.Item>
            </div>
            <div className="grid grid-cols-2 gap-12">
               <Form.Item name="shipping_fee" label="Shipping Fee">
                  <Input />
               </Form.Item>
               <Form.Item name="sorting" label="Sorting">
                  <Input />
               </Form.Item>
            </div>
            <Form.Item name="item_type" label="Item Type">
               <Select
                  defaultValue={ITEM_TYPE[0]?.value}
                  placeholder="Select item type"
               >
                  {ITEM_TYPE.map((item, index) => (
                     <Select.Option key={index} value={item?.value}>
                        {item?.name}
                     </Select.Option>
                  ))}
               </Select>
            </Form.Item>
            <Form.Item name="is_recommended" label="Is Recommended">
               <Radio.Group defaultValue={true}>
                  <Radio value={true}>Active</Radio>
                  <Radio value={false}>In active</Radio>
               </Radio.Group>
            </Form.Item>
            <Form.Item name="description" label="Description">
               <Input.TextArea rows={4} />
            </Form.Item>
            <Form.Item name="note" label="Note">
               <Input.TextArea rows={4} />
            </Form.Item>

            <div className="flex justify-end">
               <Form.Item>
                  <Button type="primary" htmlType="submit" disabled={isLoading}>
                     Submit
                  </Button>
               </Form.Item>
            </div>
         </WrapperForm>
      </Modal>
   );
}

export default AddEditMenuItemModal;
