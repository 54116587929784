import { useState, useEffect } from "react";
import { Table, Button, Input, Form, Modal, message } from "antd";
import { useStaffActions } from "@iso/actions/staffSetting.actions";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import Page from "component/Page/index";

const StaffSetting = () => {
   const [form] = Form.useForm();
   const [isSubmitting, setIsSubmitting] = useState();
   const [isLoading, setIsLoading] = useState(true);
   const [isShowModalAddMember, setIsShowModalAddMember] = useState(false);
   const [dataSearch, setDataSearch] = useState("");
   const [data, setData] = useState({
      dataSource: [],
      paging: {
         current: 1,
         total: 0,
         pageSize: 10,
      },
   });
   const staffActions = useStaffActions();

   useEffect(() => {
      getData(1, data.paging.pageSize);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const handleTableChange = (pagination) => {
      getData(pagination.current, pagination.pageSize, dataSearch);
   };

   const getData = async (offset, limit, search) => {
      setIsLoading(true);
      const data = await staffActions.getStaffSettingList({
         page: offset,
         limit,
         search: search ? search : null,
      });
      if (data) {
         setData({
            paging: {
               current: data.page,
               total: data.total,
               pageSize: limit,
            },
            dataSource: data.docs,
         });
      }
      setIsLoading(false);
   };

   const columns = [
      {
         title: "Full name",
         dataIndex: "full_name",
         key: "name",
      },
      {
         title: "Email",
         dataIndex: "email",
         key: "email",
      },
      {
         title: "Phone ",
         dataIndex: "phone_number",
         key: "phone_number",
      },
      {
         title: "Firebase User ID",
         dataIndex: "firebase_user_id",
         key: "firebase_user_id",
      },
      {
         align: "center",
         fixed: "right",
         render: (record) => (
            <div className="flex gap-3">
               <Link to={`/root/staff-setting/assign-store/${record["id"]}`}>
                  <Button type="primary">Assign store</Button>
               </Link>
               <Link
                  to={`/root/staff-setting/group-role/${record["id"]}`}
                  style={{ marginLeft: 8 }}
               >
                  <Button type="primary">Group role</Button>
               </Link>
            </div>
         ),
      },
   ];

   const handleSearch = (values) => {
      const search = values.search.trim();
      setDataSearch(search);
      getData(1, data.paging.pageSize, search);
   };

   const handleInviteMember = async (values) => {
      setIsSubmitting(true);
      try {
         const dataInvate = await staffActions.inviteMember(values);
         if (dataInvate?.status === 409) {
            form.setFields([
               {
                  name: "email",
                  errors: ["Email already exists!"],
               },
            ]);
         } else {
            message.success("Invite member success!");
            setIsShowModalAddMember(false);
            form.resetFields();
            handleTableChange(data?.paging, dataSearch);
         }
      } catch (error) {
         console.log(error);
      } finally {
         setIsSubmitting(false);
      }
   };

   const handleCloseModal = () => {
      setIsShowModalAddMember(false);
      form.resetFields();
   };

   return (
      <Page title="Staff Settings">
         <div className="staff-setting-wrapper">
            <div className="flex justify-between">
               <Form
                  name="nest-messages"
                  onFinish={handleSearch}
                  style={{ display: "flex", gap: 8 }}
               >
                  <Form.Item name="search" label="Search">
                     <Input placeholder="Search by email" />
                  </Form.Item>
                  <Form.Item>
                     <Button type="primary" htmlType="submit">
                        <p className="flex items-center">
                           <SearchOutlined />
                           <span className="ml-2">Submit</span>
                        </p>
                     </Button>
                  </Form.Item>
               </Form>
               <Button
                  type="primary"
                  onClick={() => setIsShowModalAddMember(true)}
               >
                  <p className="flex items-center">
                     <PlusOutlined />
                     <span className="ml-2">Invite member</span>
                  </p>
               </Button>
            </div>

            <Table
               columns={columns}
               rowKey="key"
               dataSource={data.dataSource}
               onChange={handleTableChange}
               pagination={{
                  current: data.paging.current,
                  total: data.paging.total,
                  pageSize: data.paging.pageSize,
               }}
               loading={isLoading}
            />

            <Modal
               title="Invite member"
               visible={isShowModalAddMember}
               footer={null}
               onCancel={() => handleCloseModal(false)}
            >
               <Form
                  name="invite-member-form"
                  onFinish={handleInviteMember}
                  form={form}
               >
                  <div style={{ width: "100%", display: "flex", gap: 8 }}>
                     <Form.Item
                        name="email"
                        label="Email"
                        style={{ flexGrow: 1 }}
                        rules={[
                           { required: true, message: "Email is required!" },
                           {
                              type: "email",
                              message: "Email is invalid",
                           },
                        ]}
                     >
                        <Input />
                     </Form.Item>
                     <Form.Item>
                        <Button
                           loading={isSubmitting}
                           type="primary"
                           htmlType="submit"
                        >
                           Submit
                        </Button>
                     </Form.Item>
                  </div>
               </Form>
            </Modal>
         </div>
      </Page>
   );
};

export default StaffSetting;
