import { useBannerManagerActions } from "@iso/actions/bannerManager.actions";
import { bannersAtom } from "@iso/state/banner";
import { DatePicker, Form, Input, Modal, Select, Switch } from "antd";
import SelectSearch from "component/SelectSearch";
import * as moment from "moment";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

const WrapperForm = styled(Form)`
   .ant-form-item-label {
      min-width: 120px;
      text-align: left;
   }
`;

function BannerChannelForm(props) {
   const { data, visible, onClose, onSubmitForm, isLoadingForm } = props;
   const [form] = Form.useForm();
   const bannerState = useRecoilValue(bannersAtom);
   const [storeInfo, setStoreInfo] = useState({
      store_id: "",
      store_channel_id: "",
   });
   const [banners, setBanners] = useState([]);
   const [isLoadingBanners, setIsLoadingBanners] = useState(false);
   const bannerManagerActions = useBannerManagerActions();

   useEffect(() => {
      handleFillValue(data);
   }, [data, visible]);

   const handleFillValue = (data) => {
      if (data?.id) {
         const date = [
            data?.active_from ? moment(data?.active_from) : null,
            data?.active_to ? moment(data?.active_to) : null,
         ];
         form.setFieldsValue({
            ...data,
            date: date,
            store_id: data?.storeChannels?.store_id,
         });
         handleGetBanner({ page: 1, limit: 10, search: data?.banner?.name });
         handleChangeStore(data?.storeChannels?.store_id);
      } else {
         form.resetFields();
      }
   };

   const handleGetBanner = async (params) => {
      try {
         setIsLoadingBanners(true);
         const res = await bannerManagerActions.getBanners(params);
         setBanners(res?.data);
         setIsLoadingBanners(false);
      } catch (err) {
         setIsLoadingBanners(false);
      }
   };

   const handleChangeStore = (value) => {
      const store_channel_id =
         bannerState?.store_channels[value]?.channels[
            Object.keys(bannerState?.store_channels[value]?.channels)[0]
         ]?.store_channel_id;

      setStoreInfo({
         store_id: value,
         store_channel_id: store_channel_id,
      });
      form.setFieldsValue({
         store_channel_id: store_channel_id,
         store_id: value,
      });
   };

   const onFinish = async (values) => {
      const payloads = { ...values };
      if (values?.date) {
         payloads.active_from = values?.date[0]?.format("YYYY-MM-DD");
         payloads.active_to = values?.date[1]?.format("YYYY-MM-DD");
      }

      onSubmitForm(payloads);
   };

   return (
      <Modal
         open={visible}
         onCancel={onClose}
         confirmLoading={isLoadingForm}
         okText="Save"
         onOk={() => form.submit()}
         title={`${data?.id ? "Edit" : "Add"} Banner`}
      >
         <div className="bg-white-0">
            <WrapperForm
               name="add-edit-product-form"
               onFinish={onFinish}
               form={form}
               initialValues={{ is_active: true }}
            >
               <div className="grid grid-cols-12 gap-8">
                  <div className="col-span-12">
                     <Form.Item
                        name="store_id"
                        label="Store"
                        rules={[
                           {
                              required: true,
                              message: "Store is required!",
                           },
                        ]}
                     >
                        <Select
                           placeholder="Select store"
                           showSearch
                           filterOption={(input, option) =>
                              option.children
                                 .toLowerCase()
                                 .indexOf(input.toLowerCase()) >= 0
                           }
                           onChange={(value) => handleChangeStore(value)}
                        >
                           {Object.keys(bannerState?.store_channels)?.map(
                              (key) => (
                                 <Select.Option value={key}>
                                    {
                                       bannerState?.store_channels[key]
                                          ?.store_name
                                    }
                                 </Select.Option>
                              )
                           )}
                        </Select>
                     </Form.Item>
                     <Form.Item
                        name="store_channel_id"
                        label="Channel"
                        rules={[
                           {
                              required: true,
                              message: "Channel is required!",
                           },
                        ]}
                     >
                        <Select placeholder="Select channel">
                           {storeInfo?.store_id
                              ? Object.keys(
                                   bannerState?.store_channels[
                                      storeInfo?.store_id
                                   ]?.channels
                                )?.map((key) => (
                                   <Select.Option
                                      value={
                                         bannerState?.store_channels[
                                            storeInfo?.store_id
                                         ]?.channels[key]?.store_channel_id
                                      }
                                   >
                                      {
                                         bannerState?.store_channels[
                                            storeInfo?.store_id
                                         ]?.channels[key]?.name
                                      }
                                   </Select.Option>
                                ))
                              : ""}
                        </Select>
                     </Form.Item>
                     <Form.Item
                        name="banner_id"
                        label="Banner"
                        rules={[
                           {
                              required: true,
                              message: "Banner is required!",
                           },
                        ]}
                     >
                        <SelectSearch
                           onSearch={(value) =>
                              handleGetBanner({
                                 limit: 10,
                                 page: 1,
                                 search: value,
                              })
                           }
                           placeholder="Search by name"
                           loading={isLoadingBanners}
                           options={banners?.map((item) => ({
                              value: item?.id,
                              label: item?.name,
                              image: item?.image,
                           }))}
                           onChange={(value) => console.log(value)}
                           value={form.getFieldValue("banner_id")}
                        />
                     </Form.Item>
                     <Form.Item
                        name="main_url_direction"
                        label="Main URL"
                        rules={[
                           {
                              required: true,
                              message: "Main URL is required!",
                           },
                        ]}
                     >
                        <Input placeholder="Main URL" />
                     </Form.Item>
                     <Form.Item
                        name="date"
                        label="Date"
                        rules={[
                           {
                              required: true,
                              message: "Date is required!",
                           },
                        ]}
                     >
                        <DatePicker.RangePicker className="w-full" />
                     </Form.Item>
                     <Form.Item
                        name="is_active"
                        label="Active"
                        valuePropName="checked"
                     >
                        <Switch defaultChecked />
                     </Form.Item>
                  </div>
               </div>
            </WrapperForm>
         </div>
      </Modal>
   );
}

export default BannerChannelForm;
