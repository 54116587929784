import { userAtom } from "@iso/state/user";
import { useRecoilState } from "recoil";
import firebase from "firebase";
import userApi from "api/userApi";

export { useUserActions };

function useUserActions() {
   const [user, setUser] = useRecoilState(userAtom);

   const getInfo = async () => {
      try {
         const resGet = await userApi.get();
         const userFB = firebase.auth().currentUser;
         const avatar = userFB?.providerData[0]?.photoURL;
         setUser({
            ...user,
            info: { ...resGet?.staff, avatar },
            roles: resGet?.functionals || [],
            stores: resGet?.storeAllow || [],
            isLogin: true,
         });
         return {
            status: true,
         };
      } catch (error) {
         console.log(error);
         return {
            status: false,
         };
      }
   };

   const registerInfo = async (data) => {
      try {
         await userApi.registerBoDeli(data);
         const resUpdate = await userApi.register(data);
         if (resUpdate) {
            setUser({ ...user, info: { ...resUpdate } });
            return { status: true };
         }
      } catch (error) {
         console.log(error);
         return {
            status: false,
         };
      }
   };

   const updateInfo = async (data) => {
      try {
         await userApi.updateBoDeli(data);
         const resUpdate = await userApi.update(data);
         if (resUpdate) {
            setUser({
               ...user,
               info: { ...user?.info, ...resUpdate },
            });
            return { status: true };
         }
      } catch (error) {
         console.log(error);
         return {
            status: false,
         };
      }
   };

   const createSessionCookie = async (data) => {
      try {
         return await userApi.createSessionCookie(data);
      } catch (error) {
         throw error;
      }
   };

   const createCustomToken = async (data) => {
      try {
         return await userApi.createCustomToken(data);
      } catch (error) {
         throw error;
      }
   };

   const resetInfo = () => {
      setUser({ info: null, roles: [], stores: [], isLogin: false });
   };

   return {
      createSessionCookie,
      createCustomToken,
      getInfo,
      resetInfo,
      updateInfo,
      registerInfo,
   };
}
