import { useState, useEffect } from "react";
import { Table, Button, Input, Form, Row, Col, Tag, Popconfirm } from "antd";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { useRecoilValue } from "recoil";
import { userAtom } from "@iso/state/user";
import { useMenuItemManagerActions } from "@iso/actions/menuItemManager.actions";
import Page from "component/Page/index";
import AddEditMenuItemModal from "./AddEditMenuItemModal";

const MenuItemManager = () => {
   const [isLoading, setIsLoading] = useState(true);
   const [inputSearch, setInputSearch] = useState("");
   const [modalMenuItemState, setModalMenuItemState] = useState({
      visible: false,
      data: null,
   });
   const [data, setData] = useState({
      dataSource: [],
      paging: {
         current: 1,
         total: 0,
         pageSize: 10,
      },
   });
   const itemLangManagerActions = useMenuItemManagerActions();
   const user = useRecoilValue(userAtom);

   const checkRoles = (roles) => {
      const userRoles = user?.roles || [];
      return roles.every((role) => userRoles.includes(role));
   };

   useEffect(() => {
      getData(1, data.paging.pageSize);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const handleTableChange = (pagination, filters) => {
      console.log(inputSearch);
      getData(
         pagination.current,
         pagination.pageSize,
         filters
            ? Object.keys(filters).length === 0
               ? inputSearch
               : filters
            : filters !== false
            ? inputSearch
            : ""
      );
   };

   const getData = async (offset, limit, params) => {
      setIsLoading(true);
      const data = await itemLangManagerActions.getMenuItemList({
         page: offset,
         limit,
         search: params ? params : "",
      });
      if (data) {
         setData({
            paging: {
               current: data?.page,
               total: data?.total,
               pageSize: limit,
            },
            dataSource: data?.data,
         });
      }
      setIsLoading(false);
   };

   const handleToggleModalLangState = (visible, data) => {
      setModalMenuItemState({
         visible: visible,
         data: data || null,
      });
   };

   const columns = [
      {
         title: "Item code",
         dataIndex: "item_id",
         key: "item_id",
      },
      {
         title: "Store ID",
         dataIndex: "name",
         key: "name",
      },
      {
         title: "Sales Type ID",
         dataIndex: "short_name",
         key: "short_name",
      },
      {
         title: "Selling Price",
         dataIndex: "selling_price",
         key: "selling_price",
      },
      {
         title: "Selling Tax Percentage",
         key: "selling_tax_percentage",
         align: "center",
         render: (record) => (
            <span>
               {record?.selling_tax_percentage
                  ? `${record?.selling_tax_percentage}%`
                  : "-"}
            </span>
         ),
      },
      {
         title: "Is active",
         key: "store_mode",
         render: (record) => (
            <Tag color={record?.is_active ? "green" : "orange"} title="Paid">
               {record?.is_active ? "Active" : "In Active"}
            </Tag>
         ),
      },
      {
         title: "Shipping Fee",
         dataIndex: "shipping_fee",
         key: "shipping_fee",
      },
      {
         title: "Note",
         dataIndex: "note",
         key: "note",
      },
      {
         align: "center",
         fixed: "right",
         render: (record) =>
            checkRoles(["item_lang_edit"]) && (
               <div className="flex gap-3">
                  <Button
                     type="primary"
                     onClick={() => handleToggleModalLangState(true, record)}
                  >
                     Edit
                  </Button>
                  <Popconfirm
                     title="Are you sure to delete this item?"
                     onConfirm={() => handleDelete(record["id"])}
                     placement="bottom"
                     okText="Yes"
                     cancelText="No"
                  >
                     <Button type="primary" style={{ marginLeft: 8 }}>
                        Delete
                     </Button>
                  </Popconfirm>
               </div>
            ),
      },
   ];

   const handleDelete = async (id) => {
      await itemLangManagerActions.deleteMenuItem(id);
      getData();
   };

   const onFinish = (values) => {
      setInputSearch(values.search.trim());
      values.search.trim() === ""
         ? handleTableChange(data.paging, false)
         : handleTableChange(data.paging, values.search.trim());
   };

   return (
      <Page title="Menu Item Management">
         <div>
            <Row>
               <Col span={12}>
                  <Form
                     name="nest-messages"
                     onFinish={onFinish}
                     style={{ width: "50%", display: "flex", gap: 8 }}
                  >
                     <Form.Item name="search" label="Search">
                        <Input />
                     </Form.Item>
                     <Form.Item>
                        <Button type="primary" htmlType="submit">
                           <p className="flex items-center">
                              <SearchOutlined />
                              <span className="ml-2">Submit</span>
                           </p>
                        </Button>
                     </Form.Item>
                  </Form>
               </Col>
               <Col span={3} offset={9} className="text-right">
                  {checkRoles(["item_lang_add"]) && (
                     <Button
                        type="primary"
                        onClick={() => handleToggleModalLangState(true)}
                     >
                        <p className="flex items-center">
                           <PlusOutlined />
                           <span className="ml-2">Add Item</span>
                        </p>
                     </Button>
                  )}
               </Col>
            </Row>
            <AddEditMenuItemModal
               modalMenuItemState={modalMenuItemState}
               handleToggleModalLangState={handleToggleModalLangState}
               getData={getData}
            />

            <Table
               columns={columns}
               rowKey="key"
               dataSource={data.dataSource}
               onChange={handleTableChange}
               pagination={{
                  current: data.paging.current,
                  total: data.paging.total,
                  pageSize: data.paging.pageSize,
               }}
               loading={isLoading}
            />
         </div>
      </Page>
   );
};

export default MenuItemManager;
