import { useState, useEffect } from "react";
import { Checkbox, Row, Col, message } from "antd";
import { useStaffActions } from "@iso/actions/staffSetting.actions";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Page from "component/Page/index";
import Loading from "../Loading";

const CustomStyle = styled.div`
   .ant-checkbox-wrapper {
      margin-bottom: 8px;
   }
`;

const AssignStore = () => {
   const staffActions = useStaffActions();
   const [checkedList, setCheckedList] = useState([]);
   const [dataDetail, setDataDetail] = useState();
   const [isLoading, setIsLoading] = useState(false);
   const { id } = useParams();

   const getData = async () => {
      setIsLoading(true);
      try {
         const resGet = await staffActions.getStaffSettingStoreDetail(id);
         setDataDetail(resGet);
         if (resGet?.data) {
            setCheckedList(
               resGet?.data
                  .filter((x) => x.isActive === true)
                  .map((item) => item["id"])
            );
         }
      } catch (error) {
         console.log(error);
      } finally {
         setIsLoading(false);
      }
   };

   useEffect(() => {
      getData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const handleSelectStore = async (e, data) => {
      try {
         await staffActions.updateStaffStore(id, {
            store_id: data["id"],
            action: checkedList.includes(data["id"]) ? "REMOVE" : "ADD",
         });
         checkedList.includes(data["id"])
            ? message.success("Remove store success!", [0.5])
            : message.success("Assign store success!", [0.5]);
      } catch (error) {
         console.log(error);
      }
   };

   const onChange = (checkedList) => {
      setCheckedList(checkedList);
   };

   return isLoading ? (
      <Loading />
   ) : (
      <Page title="Assign Store">
         <div className="assign-store-wrapper">
            <Row className="px-6 py-8 mb-12 bg-white-0 rounded-sm shadow-sm">
               <Col span={8}>
                  <h3 className="mb-0">
                     {dataDetail?.staff?.full_name || "-"}
                  </h3>
               </Col>
               <Col span={8}>
                  <h3 className="mb-0">{dataDetail?.staff?.email || "-"}</h3>
               </Col>
               <Col span={8}>
                  <h3 className="mb-0">
                     {dataDetail?.staff?.phone_number || "-"}
                  </h3>
               </Col>
            </Row>
            <hr />
            <h2 className="my-7">Store list</h2>
            <div className="px-6 py-8 bg-white-0 rounded-sm shadow-sm">
               <CustomStyle>
                  <Checkbox.Group
                     style={{ width: "100%" }}
                     value={checkedList}
                     onChange={onChange}
                  >
                     <Row>
                        {dataDetail?.data && dataDetail?.data?.length > 0
                           ? dataDetail?.data?.map((item, index) => (
                                <Col span={8} key={index}>
                                   <Checkbox
                                      value={item["id"]}
                                      onClick={(e) =>
                                         handleSelectStore(e, item)
                                      }
                                   >
                                      <h4 className="capitalize my-4">
                                         {item?.full_name}
                                      </h4>
                                   </Checkbox>
                                </Col>
                             ))
                           : ""}
                     </Row>
                  </Checkbox.Group>
               </CustomStyle>
            </div>
         </div>
      </Page>
   );
};

export default AssignStore;
