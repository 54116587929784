import { useState, useEffect } from "react";
import { Table, Button, Popconfirm, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useAttributeManagerActions } from "@iso/actions/attributeManager.actions";
import AddEditAttributeTypeModal from "./AddEditAttributeType";
import useAuthor from "hooks/useAuthor";
import FilterGroup from "component/FilterGroup";
import AsyncButton from "component/AsyncButton";

const AttributeType = (props) => {
   const [isLoading, setIsLoading] = useState(true);
   const [isSubmitting, setIsSubmitting] = useState({});
   const [modalAttributeTypeState, setModalAttributeTypeState] = useState({
      visible: false,
      data: null,
   });
   const [data, setData] = useState({
      dataSource: [],
      paging: {
         current: 1,
         total: 0,
         pageSize: 10,
      },
   });
   const { checkRoles } = useAuthor();
   const [currentFilters, setCurrentFilters] = useState({});
   const attributeActions = useAttributeManagerActions();

   const columns = [
      {
         title: "ID",
         dataIndex: "id",
         key: "id",
         align: "center",
      },
      {
         title: "Name",
         dataIndex: "name",
         key: "name",
         align: "center",
      },
      {
         title: "Actions",
         align: "center",
         fixed: "right",
         width: 180,
         render: (record) => (
            <>
               <div className="flex justify-center gap-3">
                  {checkRoles(["attribute_edit"]) && (
                     <Button
                        type="primary"
                        onClick={() =>
                           handleToggleModalAttributeTypeState(true, record)
                        }
                     >
                        Update
                     </Button>
                  )}
                  {checkRoles(["attribute_delete"]) && (
                     <Popconfirm
                        title="Are you sure to delete this attribute type?"
                        onConfirm={() =>
                           handleDeleteAttributeType(record["id"])
                        }
                        placement="bottom"
                        okText="Yes"
                        cancelText="No"
                     >
                        <AsyncButton
                           isLoading={isSubmitting[record["id"]] || false}
                           type="primary"
                           style={{ marginLeft: 8 }}
                        >
                           Delete
                        </AsyncButton>
                     </Popconfirm>
                  )}
               </div>
            </>
         ),
      },
   ];

   const getData = async (page, limit, filters) => {
      try {
         setIsLoading(true);
         const resGet = await attributeActions.getAttributeTypeList({
            page: page,
            limit,
            ...filters,
         });
         setData({
            paging: {
               current: parseInt(resGet.page),
               total: parseInt(resGet.total),
               pageSize: parseInt(resGet.limit),
            },
            dataSource: resGet?.data,
         });
      } catch (error) {
         console.log(error);
      } finally {
         setIsLoading(false);
      }
   };

   const handleTableChange = (pagination) => {
      getData(pagination.current, pagination.pageSize, currentFilters);
   };

   const handleToggleModalAttributeTypeState = (visible, data) => {
      setModalAttributeTypeState({
         visible: visible,
         data: data || null,
      });
   };

   const handleDeleteAttributeType = async (id) => {
      try {
         const currentIsSubmitting = { ...isSubmitting };
         currentIsSubmitting[`${id}`] = true;
         setIsSubmitting(currentIsSubmitting);
         await attributeActions.deleteAttributeType(id);
         message.success("Delete attribute type success!!!");
         handleTableChange(data.paging, currentFilters);
      } finally {
         const currentIsSubmitting = { ...isSubmitting };
         delete currentIsSubmitting[id];
         setIsSubmitting(currentIsSubmitting);
      }
   };

   useEffect(() => {
      getData(1, data.paging.pageSize, currentFilters);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [props?.currentTab]);

   return (
      <>
         <div className="flex justify-between mt-4 mb-8">
            <FilterGroup
               currentFilters={currentFilters}
               setCurrentFilters={setCurrentFilters}
               onGetFilterProperties={
                  attributeActions.getFilterPropertiesAttributeType
               }
               onGetOptionsProperty={
                  attributeActions.getOptionsPropertyAttributeType
               }
               onFilter={(filters) =>
                  getData(1, data?.paging?.pageSize, filters)
               }
            />
            {checkRoles(["attribute_add"]) && (
               <Button
                  type="primary"
                  onClick={() => handleToggleModalAttributeTypeState(true)}
               >
                  <p className="flex items-center">
                     <PlusOutlined />
                     <span className="ml-2">Add Attribute Type</span>
                  </p>
               </Button>
            )}
         </div>

         <AddEditAttributeTypeModal
            modalAttributeTypeState={modalAttributeTypeState}
            handleToggleModalAttributeTypeState={
               handleToggleModalAttributeTypeState
            }
            getData={getData}
         />

         <Table
            columns={columns?.filter((col) => {
               if (col.title === "Actions") {
                  return (
                     checkRoles(["attribute_edit"]) ||
                     checkRoles(["attribute_delete"])
                  );
               }
               return true;
            })}
            onChange={handleTableChange}
            rowKey="key"
            dataSource={data.dataSource}
            loading={isLoading}
            scroll={{ x: 1200 }}
            pagination={{
               current: data.paging.current,
               total: data.paging.total,
               pageSize: data.paging.pageSize,
            }}
         />
      </>
   );
};

export default AttributeType;
