import { API_ENDPOINT, API_KMS } from "@iso/config/index";
import { axiosClient } from "@iso/helpers/axios.config";

const PRODUCT_ENDPONT = `${API_ENDPOINT}/product-item`;
const ITEM_DEFINE_ADD_ON_ENDPONT = `${API_ENDPOINT}/item-define-addon`;
const PROODUCT_STATION_ENDPONT = `${API_KMS}/item-station`;
const ITEM_ATTRIBUTE_ENDPONT = `${API_ENDPOINT}/item_attribute`;

const productManagerApi = {
   getFilterProperties: () => {
      return axiosClient.get(`${PRODUCT_ENDPONT}/filter-properties`);
   },
   getOptionsProperty: (params) => {
      return axiosClient.get(`${PRODUCT_ENDPONT}/options-property`, { params });
   },
   getAll: (params) => {
      return axiosClient.get(`${PRODUCT_ENDPONT}/all`, { params });
   },
   gets: (params) => {
      return axiosClient.get(PRODUCT_ENDPONT, { params });
   },
   get: (id) => {
      return axiosClient.get(`${PRODUCT_ENDPONT}/${id}`);
   },
   post: (data) => {
      return axiosClient.post(PRODUCT_ENDPONT, data);
   },
   put: (id, data) => {
      return axiosClient.put(`${PRODUCT_ENDPONT}/${id}`, data);
   },
   del: (id) => {
      return axiosClient.delete(`${PRODUCT_ENDPONT}/${id}`);
   },
   //Customization Tab
   getAddOnGroups: (params) => {
      return axiosClient.get(`${ITEM_DEFINE_ADD_ON_ENDPONT}/add-on-groups`, {
         params,
      });
   },

   getItemDefineAddOn: (params) => {
      return axiosClient.get(ITEM_DEFINE_ADD_ON_ENDPONT, {
         params,
      });
   },

   postItemDefineAddOn: (data) => {
      return axiosClient.post(ITEM_DEFINE_ADD_ON_ENDPONT, data);
   },

   postSortingItemsDefintAddOn: (body) => {
      return axiosClient.post(
         `${ITEM_DEFINE_ADD_ON_ENDPONT}/update-sorting`,
         body
      );
   },

   delItemDefineAddOn: (id) => {
      return axiosClient.delete(`${ITEM_DEFINE_ADD_ON_ENDPONT}/${id}`);
   },

   //Attribute Tab
   getItemAttribute: (params) => {
      return axiosClient.get(ITEM_ATTRIBUTE_ENDPONT, { params });
   },

   putItemAttribute: (id, data) => {
      return axiosClient.put(`${ITEM_ATTRIBUTE_ENDPONT}/${id}`, data);
   },

   //Availability Tab
   getStationsAssignItem: (params) => {
      return axiosClient.get(`${PROODUCT_STATION_ENDPONT}/station-assign`, {
         params,
      });
   },

   putStationsAssignItem: (id, data) => {
      return axiosClient.put(
         `${PROODUCT_STATION_ENDPONT}/station-assign/${id}`,
         data
      );
   },
};

export default productManagerApi;
