import { Result } from "antd";
import { userAtom } from "@iso/state";
import { useRecoilValue } from "recoil";
import { Redirect, useHistory } from "react-router-dom";
import { Button } from "../../../node_modules/antd/lib/index";
import Cookies from "universal-cookie";

function Auth() {
   const user = useRecoilValue(userAtom);
   const cookies = new Cookies();
   const history = useHistory();
   return (
      <>
         {console.log(user)}
         {!user?.isLogin ? (
            <Redirect
               to={{
                  pathname: "/login",
               }}
            />
         ) : !user?.info?.id ? (
            <div className="min-h-screen flex items-center justify-center">
               <Result
                  status="warning"
                  title={
                     <>
                        <p className="text-primary-500 opacity-75 text-2xl mb-4">
                           You're not invited into the system.
                        </p>
                        <div className="border-solid border-2 rounded-sm p-4 mb-4">
                           <p className="text-primary-500 opacity-75 text-sl mb-2 text-left">
                              <strong>uid:</strong>{" "}
                              <span className="">
                                 {cookies.get("uid_firebase")}
                              </span>
                           </p>
                           <p className="text-primary-500 opacity-75 text-sl text-left mb-0">
                              <strong>email:</strong>{" "}
                              <span className="">{cookies.get("email")}</span>
                           </p>
                        </div>
                        <Button onClick={() => history.push("/login")}>
                           Try again with another account
                        </Button>
                     </>
                  }
               />
            </div>
         ) : !user?.info?.firebase_user_id ? (
            <Redirect
               to={{
                  pathname: "/register",
               }}
            />
         ) : (
            <Redirect
               to={{
                  pathname: "/choose-system",
               }}
            />
         )}
      </>
   );
}

export default Auth;
