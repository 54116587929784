import { firebaseAuthConfig } from "../../config/firebase";
import { Card, Typography } from "antd";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase from "firebase/app";
import "firebase/auth";
import Lottie from "lottie-react";
import styled from "styled-components";
import pizzaAnimation from "../../assets/animation/pizza.json";

const Pizza = styled.div`
   width: 250px;
   height: 250px;
   margin: 0 auto;
`;

const Centered = styled.div`
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
`;

const Login = () => (
   <div className="fade-in bg-gray-400 min-h-screen min-w-full">
      <Centered>
         <Pizza>
            <Lottie animationData={pizzaAnimation} loop />
         </Pizza>
         <Typography.Title level={2} className="text-center">
            Pizza 4Ps - MMS
         </Typography.Title>
         <Card>
            <StyledFirebaseAuth
               uiConfig={firebaseAuthConfig}
               firebaseAuth={firebase.auth()}
            />
         </Card>
      </Centered>
      <a
         target="_blank"
         rel="noreferrer"
         className="fixed right-4 bottom-4 z-10 text-primary-500"
         href="https://online.pizza4ps.com/pages/chinh-sach-bao-mat?view=vn"
      >
         Our Data Privacy Policy
      </a>
   </div>
);

export default Login;
