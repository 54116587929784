import { loaderAtom } from "@iso/state/loader";
import { useRecoilValue } from "recoil";
import Container from "component/Container/index";
import Loader from "component/Loader/index";
import PageHeader from "./PageHeader/index";

function Page(props) {
   const { children, title } = props;
   const loader = useRecoilValue(loaderAtom);

   return (
      <div className="fade-in pb-10 h-full">
         {loader && <Loader />}
         <Container>
            <PageHeader title={title} />
            {children}
         </Container>
      </div>
   );
}

export default Page;
