// eslint-disable-next-line import/no-extraneous-dependencies
import firebase from "firebase/app";
import { firebaseConfig } from ".";
import Cookies from "universal-cookie";

export const firebaseAuthConfig = {
   signInFlow: "popup",
   signInSuccessUrl: "/",
   signInOptions: [
      {
         provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
         scopes: ["https://www.googleapis.com/auth/userinfo.profile"],
         requireDisplayName: true,
         customParameters: {
            prompt: "select_account",
         },
      },
   ],
   callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: (authResult) => {
         const cookies = new Cookies();

         cookies.set("token", authResult?.credential.idToken, {
            path: "/",
         });
         return true;
      },
   },
};

export { firebaseConfig };
