import { PlusOutlined } from "@ant-design/icons";
import { useAddOnManagerActions } from "@iso/actions/addOnManager.actions";
import { useProductManagerActions } from "@iso/actions/productManager.actions";
import { Checkbox, message, Table } from "antd";
import { useEffect, useState } from "react";
import DropdownFilter from "component/DropdownFilter";
import AsyncButton from "component/AsyncButton";

function ProductItemTable(props) {
   const [isLoading, setIsLoading] = useState(false);
   const [data, setData] = useState({
      dataSource: [],
      paging: {
         current: 1,
         total: 0,
         pageSize: 10,
      },
      selected: [],
   });
   const [isSubmitting, setIsSubmitting] = useState({});
   const [currentFilters, setCurrentFilters] = useState({});
   const addOnManagerActions = useAddOnManagerActions();
   const productManagerActions = useProductManagerActions();

   const productItemColumns = [
      {
         title: "Item Name",
         dataIndex: "name",
         key: "name",
      },
      {
         title: "Item Code",
         dataIndex: "id",
         key: "id",
         align: "center",
      },
      {
         align: "center",
         render: (record) => (
            <AsyncButton
               onClick={() => handleSubmitItemAddOn(record["id"])}
               onlyIcon
               isLoading={isSubmitting[record["id"].toString()] || false}
               type="primary"
               style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "0 auto",
               }}
            >
               <div className="flex items-center">
                  <PlusOutlined />
               </div>
            </AsyncButton>
         ),
      },
   ];

   const getDataProduct = async (page, limit, filters) => {
      setIsLoading((state) => ({ ...state, data: true }));
      try {
         const resGet = await productManagerActions.getProducts({
            page,
            limit,
            ...filters,
         });
         setData((state) => ({
            ...state,
            paging: {
               current: parseInt(resGet.page),
               total: parseInt(resGet.total),
               pageSize: parseInt(resGet.limit),
            },
            dataSource: resGet?.data,
         }));
      } catch (error) {
         console.log(error);
      } finally {
         setIsLoading((state) => ({ ...state, data: false?.data }));
      }
   };

   const handleSubmitItemAddOn = async (itemId) => {
      try {
         const currentIsSubmitting = { ...isSubmitting };
         currentIsSubmitting[`${itemId}`] = true;
         setIsSubmitting(currentIsSubmitting);
         await addOnManagerActions.addAddOnItem({
            item_id: itemId,
            addon_id: props?.addOnId,
         });
         message.success("Add add-on item success!");
         props?.onGetDataAddOnItems();
         setData((state) => ({ ...state, selected: [] }));
      } finally {
         const currentIsSubmitting = { ...isSubmitting };
         delete currentIsSubmitting[itemId];
         setIsSubmitting(currentIsSubmitting);
      }
   };

   const handleTableChange = (pagination) => {
      getDataProduct(pagination.current, pagination.pageSize, currentFilters);
   };

   const handleFilter = async (property, filgerValues) => {
      const filters = { ...currentFilters };
      if (filgerValues !== "") {
         filters[property] = filgerValues;
      } else {
         delete filters[property];
      }
      try {
         await getDataProduct(1, data?.paging?.pageSize, filters);
      } finally {
         setCurrentFilters(filters);
      }
   };

   useEffect(() => {
      getDataProduct(1, data?.paging?.pageSize);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <div className="col-span-3">
         <div className="flex justify-between mb-8">
            <div className="flex">
               <div className="mr-4">
                  <DropdownFilter
                     title="Category"
                     onGetOptions={() =>
                        productManagerActions.getOptionsProperty({
                           property: "category_id",
                        })
                     }
                     onFilter={(filter) => handleFilter("category_id", filter)}
                  />
               </div>
               <div className="mr-4">
                  <DropdownFilter
                     title="Class"
                     onGetOptions={() =>
                        productManagerActions.getOptionsProperty({
                           property: "class_id",
                        })
                     }
                     onFilter={(filter) => handleFilter("class_id", filter)}
                  />
               </div>
               <DropdownFilter
                  title="Name"
                  onGetOptions={() =>
                     productManagerActions.getOptionsProperty({
                        property: "name",
                     })
                  }
                  onFilter={(filter) => handleFilter("name", filter)}
               />
            </div>
         </div>
         <Table
            loading={isLoading.data}
            columns={productItemColumns}
            dataSource={data?.dataSource}
            pagination={{
               current: data?.paging.current,
               total: data?.paging.total,
               pageSize: data?.paging.pageSize,
               position: ["bottomCenter"],
            }}
            onChange={handleTableChange}
         />
      </div>
   );
}

export default ProductItemTable;
