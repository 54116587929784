import { useState, useEffect } from "react";
import { Table, Button, Popconfirm, Tag, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useAddOnManagerActions } from "@iso/actions/addOnManager.actions";
import { Link } from "react-router-dom";
import AddAddOnModal from "./AddAddOn";
import useAuthor from "hooks/useAuthor";
import AsyncButton from "component/AsyncButton";
import FilterGroup from "component/FilterGroup";
import * as CONFIG from "@iso/config/index";

const AddOn = () => {
   const [isLoading, setIsLoading] = useState(true);
   const [isSubmitting, setIsSubmitting] = useState({});
   const [isShowModalAdd, setIsShowModalAdd] = useState(false);
   const [data, setData] = useState({
      dataSource: [],
      paging: {
         current: 1,
         total: 0,
         pageSize: 10,
      },
   });
   const [currentFilters, setCurrentFilters] = useState({});
   const { checkRoles } = useAuthor();
   const addOnManagerActions = useAddOnManagerActions();

   const columns = [
      {
         title: "Name",
         dataIndex: "name",
         key: "name",
         align: "center",
      },
      {
         title: "Type",
         dataIndex: "type",
         key: "type",
         align: "center",
         render: (record) => (
            <span className="uppercase">
               {record === CONFIG.ADD_ON_TYPE.COMBO ? (
                  <Tag color="green">{CONFIG.ADD_ON_TYPE_NAME.COMBO}</Tag>
               ) : record === CONFIG.ADD_ON_TYPE.SINGLE ? (
                  <Tag color="orange">{CONFIG.ADD_ON_TYPE_NAME.SINGLE}</Tag>
               ) : (
                  <Tag color="blue">{CONFIG.ADD_ON_TYPE_NAME.MULTIPLE}</Tag>
               )}
            </span>
         ),
      },
      {
         title: "Max",
         dataIndex: "max_selection",
         key: "max_selection",
         align: "center",
         render: (record) => (
            <span>
               {record === CONFIG.UNLIMITED_ADD_ON_SELECTION
                  ? "Unlimited"
                  : record}
            </span>
         ),
      },
      {
         title: "Selection Count",
         dataIndex: "addOnItem",
         key: "addOnItem",
         align: "center",
         render: (record) => <span>{record?.length}</span>,
      },
      {
         title: "Actions",
         align: "center",
         fixed: "right",
         width: 180,
         render: (record) => (
            <>
               <div className="flex justify-center gap-3">
                  <Link to={`/product/add-on/${record["id"]}`}>
                     {checkRoles(["add_on_edit"]) && (
                        <Button type="primary">Detail</Button>
                     )}
                  </Link>
                  {checkRoles(["add_on_delete"]) && (
                     <Popconfirm
                        title="Are you sure to delete this language?"
                        onConfirm={() => handleDeleteLang(record["id"])}
                        placement="bottom"
                        okText="Yes"
                        cancelText="No"
                     >
                        <AsyncButton
                           isLoading={isSubmitting[record["id"]] || false}
                           type="primary"
                           style={{ marginLeft: 8 }}
                        >
                           Delete
                        </AsyncButton>
                     </Popconfirm>
                  )}
               </div>
            </>
         ),
      },
   ];

   const getData = async (page, limit, filters) => {
      try {
         setIsLoading(true);
         const resGet = await addOnManagerActions.getAddOnList({
            page: page,
            limit,
            ...filters,
         });
         setData({
            paging: {
               current: parseInt(resGet.page),
               total: parseInt(resGet.total),
               pageSize: parseInt(resGet.limit),
            },
            dataSource: resGet?.data,
         });
      } catch (error) {
         console.log(error);
      } finally {
         setIsLoading(false);
      }
   };

   const handleTableChange = (pagination) => {
      getData(pagination.current, pagination.pageSize, currentFilters);
   };

   const handleDeleteLang = async (id) => {
      const currentIsSubmitting = { ...isSubmitting };
      currentIsSubmitting[`${id}`] = true;
      setIsSubmitting(currentIsSubmitting);
      try {
         await addOnManagerActions.deleteAddOn(id);
         message.success("Delete Add-On item success!");
         handleTableChange(data?.paging, currentFilters);
      } finally {
         const currentIsSubmitting = { ...isSubmitting };
         delete currentIsSubmitting[id];
         setIsSubmitting(currentIsSubmitting);
      }
   };

   useEffect(() => {
      getData(1, data?.paging?.pageSize);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <div className="add-on-wrapper">
         <div className="flex justify-between mt-4 mb-8">
            <FilterGroup
               currentFilters={currentFilters}
               setCurrentFilters={setCurrentFilters}
               onGetFilterProperties={addOnManagerActions.getFilterProperties}
               onGetOptionsProperty={addOnManagerActions.getOptionsProperty}
               onFilter={(filters) =>
                  getData(1, data?.paging?.pageSize, filters)
               }
            />
            {checkRoles(["add_on_add"]) && (
               <Button type="primary" onClick={() => setIsShowModalAdd(true)}>
                  <p className="flex items-center">
                     <PlusOutlined />
                     <span className="ml-2">Add Add-On</span>
                  </p>
               </Button>
            )}
         </div>

         <AddAddOnModal
            visible={isShowModalAdd}
            onClose={() => setIsShowModalAdd(false)}
            getData={() => handleTableChange(data.paging)}
         />

         <Table
            columns={columns?.filter((col) => {
               if (col.title === "Actions") {
                  return (
                     checkRoles(["add_on_edit"]) ||
                     checkRoles(["add_on_delete"])
                  );
               }
               return true;
            })}
            onChange={handleTableChange}
            rowKey="key"
            dataSource={data.dataSource}
            loading={isLoading}
            scroll={{ x: 1200 }}
            pagination={{
               current: data.paging.current,
               total: data.paging.total,
               pageSize: data.paging.pageSize,
            }}
         />
      </div>
   );
};

export default AddOn;
