import { useProductManagerActions } from "@iso/actions/productManager.actions";
import { Form, Input, Select, Spin, Tabs } from "antd";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { useLangManagerActions } from "@iso/actions/langManager.actions";
import LangDetail from "./LangDetail";
import { useItemLangManagerActions } from "@iso/actions/itemLangManager.actions";

const { TabPane } = Tabs;

const WrapperForm = styled(Form)`
   .ant-form-item-label {
      min-width: 200px;
      text-align: left;
   }
`;

const TabsWrapper = styled(Tabs)`
   .ant-tabs-nav {
      border-bottom: none;
   }
`;

const CustomStyle = styled.div`
   .ant-checkbox-wrapper {
      margin-bottom: 8px;
      white-space: nowrap;
   }
   .ant-collapse {
      width: 100%;
      margin-bottom: 16px;
   }
   .ant-collapse-header {
      align-items: center !important;
      h2 {
         margin-bottom: 0;
      }
   }
   h3 {
      width: 100%;
   }
`;

const { Option } = Select;

function Content(props) {
   const { product } = props;
   const [form] = Form.useForm();
   const [dataCategory, setDataCategory] = useState({
      list: [],
      selected: null,
   });
   const [dataDevice, setDataDevice] = useState([]);
   const [dataLang, setDataLang] = useState([]);
   const [isLoading, setIsLoading] = useState({
      getDataProduct: false,
      getDataItemAttribute: false,
      getDataLang: false,
      getDataDevice: false,
   });
   const [currentTab, setCurrentTab] = useState({ device: "", lang: "" });
   const productManagerActions = useProductManagerActions();
   const langManagerActions = useLangManagerActions();
   const itemLangManagerActions = useItemLangManagerActions();

   const getDataProduct = async () => {
      if (product?.id) {
         setIsLoading((state) => ({ ...state, getDataProduct: true }));
         try {
            const resGet = await productManagerActions.getProductDetail(
               product?.id
            );
            resGet.productId = resGet?.id;
            resGet.category_id = resGet?.category?.id;
            resGet.class_id = resGet?.class?.id;
            form.setFieldsValue(resGet);

            const cate = dataCategory?.list?.find((category) => {
               return category?.id === resGet?.category?.id;
            });
            setDataCategory((state) => ({ ...state, selected: cate }));
         } finally {
            setIsLoading((state) => ({ ...state, getDataProduct: false }));
         }
      } else {
         form.resetFields();
      }
   };

   const getDataDevices = async () => {
      setIsLoading((state) => ({ ...state, getDataLang: true }));
      try {
         const resGet = await itemLangManagerActions.getDevices();
         setDataDevice(resGet);
         if (resGet?.length > 0) {
            setCurrentTab((state) => ({ ...state, device: resGet[0]?.id }));
         }
      } finally {
         setIsLoading((state) => ({ ...state, getDataLang: false }));
      }
   };

   const getDataLang = async () => {
      setIsLoading((state) => ({ ...state, getDataLang: true }));
      try {
         const resGet = await langManagerActions.getLangList();
         setDataLang(resGet);
         if (resGet?.length > 0) {
            setCurrentTab((state) => ({ ...state, lang: resGet[0]?.id }));
         }
      } finally {
         setIsLoading((state) => ({ ...state, getDataLang: false }));
      }
   };

   const handleChangeTab = (type, tab) => {
      if (type === "lang") {
         setCurrentTab((state) => ({ ...state, lang: tab }));
      } else {
         setCurrentTab((state) => ({
            ...state,
            device: tab,
            lang: dataLang?.length > 0 ? dataLang[0] : "",
         }));
      }
   };

   useEffect(() => {
      if (product?.id) {
         getDataLang();
         getDataProduct();
      } else {
         form.resetFields();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [product]);

   useEffect(() => {
      getDataDevices();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <>
         <Spin spinning={isLoading?.getDataProduct}>
            <div className="p-8 mt-8 bg-white-0 rounded-sm shadow-sm">
               <WrapperForm name="product-form" form={form}>
                  <div className="grid grid-cols-2 gap-20">
                     <div>
                        <Form.Item name="productId" label="Product Id">
                           <Input disabled placeholder="Product Id" />
                        </Form.Item>
                        <Form.Item name="category_id" label="Category">
                           <Select disabled placeholder="Category">
                              {dataCategory?.list?.map((category) => (
                                 <Option
                                    key={category?.id}
                                    value={category?.id}
                                 >
                                    {category?.name}
                                 </Option>
                              ))}
                           </Select>
                        </Form.Item>
                     </div>
                     <div>
                        <Form.Item name="name" label="Name">
                           <Input disabled placeholder="Name" />
                        </Form.Item>
                        <Form.Item name="class_id" label="Class">
                           <Select placeholder="Class" disabled>
                              {dataCategory.selected?.classCate?.map((cl) => (
                                 <Option key={cl?.id} value={cl?.id}>
                                    {cl?.name}
                                 </Option>
                              ))}
                           </Select>
                        </Form.Item>
                     </div>
                  </div>
               </WrapperForm>
            </div>
         </Spin>

         <Spin spinning={isLoading?.getDataLang}>
            {dataDevice?.length > 0 && dataLang?.length > 0 && (
               <CustomStyle className="mt-8">
                  <Tabs
                     type="card"
                     onChange={(tab) => handleChangeTab("device", tab)}
                  >
                     {dataDevice?.map((device) => (
                        <TabPane tab={device?.name} key={device?.id}>
                           {currentTab?.device === device?.id && (
                              <TabsWrapper
                                 type="card"
                                 tabPosition="left"
                                 onChange={(tab) =>
                                    handleChangeTab("lang", tab)
                                 }
                              >
                                 {dataLang?.map((lang) => (
                                    <TabPane tab={lang?.name} key={lang?.id}>
                                       {currentTab.lang === lang?.id && (
                                          <LangDetail
                                             deviceId={device?.id}
                                             product={product}
                                             langId={lang?.id}
                                          />
                                       )}
                                    </TabPane>
                                 ))}
                              </TabsWrapper>
                           )}
                        </TabPane>
                     ))}
                  </Tabs>
               </CustomStyle>
            )}
         </Spin>
      </>
   );
}

export default Content;
