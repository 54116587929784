import { useProductManagerActions } from "@iso/actions/productManager.actions";
import {
   Form,
   Input,
   Select,
   Spin,
   Checkbox,
   Row,
   Collapse,
   message,
} from "antd";
import { useEffect, useState } from "react";
import AsyncButton from "component/AsyncButton";
import styled from "styled-components";

const { Panel } = Collapse;

const WrapperForm = styled(Form)`
   .ant-form-item-label {
      min-width: 200px;
      text-align: left;
   }
`;

const CustomStyle = styled.div`
   .ant-checkbox-wrapper {
      margin-bottom: 8px;
      white-space: nowrap;
   }
   .ant-collapse {
      width: 100%;
      margin-bottom: 16px;
   }
   .ant-collapse-header {
      align-items: center !important;
      h2 {
         margin-bottom: 0;
      }
   }
   h3 {
      width: 100%;
   }
`;

const { Option } = Select;

function Attribute(props) {
   const { product } = props;
   const productManagerActions = useProductManagerActions();
   const [form] = Form.useForm();
   const [dataCategory, setDataCategory] = useState({
      list: [],
      selected: null,
   });
   const [checkedList, setCheckedList] = useState([]);
   const [data, setData] = useState([]);
   const [isLoading, setIsLoading] = useState({
      getDataProduct: false,
      getDataItemAttribute: false,
   });
   const [isSubmitting, setIsSubmitting] = useState(false);

   const getDataProduct = async () => {
      if (product?.id) {
         setIsLoading((state) => ({ ...state, getDataProduct: true }));
         try {
            const resGet = await productManagerActions.getProductDetail(
               product?.id
            );
            resGet.productId = resGet?.id;
            resGet.category_id = resGet?.category?.id;
            resGet.class_id = resGet?.class?.id;
            form.setFieldsValue(resGet);

            const cate = dataCategory?.list?.find((category) => {
               return category?.id === resGet?.category?.id;
            });
            setDataCategory((state) => ({ ...state, selected: cate }));
         } finally {
            setIsLoading((state) => ({ ...state, getDataProduct: false }));
         }
      } else {
         form.resetFields();
      }
   };

   const getDataItemAttribute = async () => {
      setIsLoading((state) => ({ ...state, getDataItemAttribute: true }));
      try {
         const resGet = await productManagerActions.getItemAttribute({
            item_id: product?.id,
         });
         const arrDefaultChecked = [];
         resGet?.map((attributeType) =>
            attributeType?.attributes?.map(
               (attribute) =>
                  attribute?.isActive && arrDefaultChecked.push(attribute?.id)
            )
         );
         setData(resGet);
         setCheckedList(arrDefaultChecked);
      } finally {
         setIsLoading((state) => ({ ...state, getDataItemAttribute: false }));
      }
   };

   const onAttributeClick = (status, attributeId) => {
      if (status) {
         setCheckedList([...checkedList, attributeId]);
      } else {
         const newAttributes = checkedList?.filter(
            (attribute) => attribute !== attributeId
         );
         setCheckedList(newAttributes);
      }
   };

   const handleUpdate = async () => {
      setIsSubmitting(true);
      try {
         await productManagerActions.updateItemAttribute(product?.id, {
            attribute_ids: checkedList,
         });
         message.success("Update item attribute success!");
         getDataItemAttribute(product?.id);
      } finally {
         setIsSubmitting(false);
      }
   };

   useEffect(() => {
      if (product?.id) {
         getDataProduct();
         getDataItemAttribute(product?.id);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [product]);

   return (
      <>
         <Spin spinning={isLoading?.getDataProduct}>
            <div className="p-8 mt-8 bg-white-0 rounded-sm shadow-sm">
               <WrapperForm name="product-form" form={form}>
                  <div className="grid grid-cols-2 gap-20">
                     <div>
                        <Form.Item name="productId" label="Product Id">
                           <Input disabled placeholder="Product Id" />
                        </Form.Item>
                        <Form.Item name="category_id" label="Category">
                           <Select disabled placeholder="Category">
                              {dataCategory?.list?.map((category) => (
                                 <Option
                                    key={category?.id}
                                    value={category?.id}
                                 >
                                    {category?.name}
                                 </Option>
                              ))}
                           </Select>
                        </Form.Item>
                     </div>
                     <div>
                        <Form.Item name="name" label="Name">
                           <Input disabled placeholder="Name" />
                        </Form.Item>
                        <Form.Item name="class_id" label="Class">
                           <Select placeholder="Class" disabled>
                              {dataCategory.selected?.classCate?.map((cl) => (
                                 <Option key={cl?.id} value={cl?.id}>
                                    {cl?.name}
                                 </Option>
                              ))}
                           </Select>
                        </Form.Item>
                     </div>
                  </div>
               </WrapperForm>
            </div>
         </Spin>
         <Spin spinning={isLoading?.getDataItemAttribute}>
            <CustomStyle className="mt-8">
               <Checkbox.Group style={{ width: "100%" }} value={checkedList}>
                  <Row>
                     <Collapse>
                        {data?.map((attributeType) => (
                           <Panel
                              header={
                                 <h2 className="uppercase">
                                    {attributeType?.name}
                                 </h2>
                              }
                              key={attributeType?.id}
                           >
                              <div className="flex flex-wrap">
                                 {attributeType?.attributes?.map(
                                    (attribute) => (
                                       <div
                                          key={attribute?.id}
                                          className="flex w-1/3 my-4"
                                       >
                                          <Checkbox
                                             style={{
                                                display: "flex",
                                                marginBottom: 0,
                                             }}
                                             value={attribute?.id}
                                             onChange={(e) =>
                                                onAttributeClick(
                                                   e?.target?.checked,
                                                   attribute?.id
                                                )
                                             }
                                          >
                                             <h4 className="capitalize mb-0">
                                                {attribute?.name}
                                             </h4>
                                          </Checkbox>
                                       </div>
                                    )
                                 )}
                              </div>
                           </Panel>
                        ))}
                     </Collapse>
                  </Row>
               </Checkbox.Group>
            </CustomStyle>
         </Spin>
         {data?.length > 0 && (
            <div className="bg-white-0 p-8 text-right flex justify-end shadow-sm">
               <AsyncButton
                  isLoading={isSubmitting}
                  type="primary"
                  onClick={() => handleUpdate()}
               >
                  Submit
               </AsyncButton>
            </div>
         )}
      </>
   );
}

export default Attribute;
