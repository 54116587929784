import axios from "axios";
import firebase from "firebase";
import { message } from "antd";

export const axiosClient = axios.create({
   headers: {
      Accept: "application/json",
   },
});

axiosClient.interceptors.request.use(async (config) => {
   const currentUser = firebase.auth().currentUser;
   if (currentUser) {
      const token = await currentUser.getIdToken();
      config.headers.Authorization = `Bearer ${token}`;
   }
   return config;
});

axiosClient.interceptors.response.use(
   (response) => {
      if (response && response.data) {
         return response.data;
      }
   },
   (error) => {
      const mess = error?.response?.data?.message;
      message.error(mess);
      return Promise.reject(error);
   }
);
