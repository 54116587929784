import { useStoreManagerActions } from "@iso/actions/storeManager.actions";
import { Form, Input, message, Modal, Select, Spin } from "antd";
import { useEffect, useState } from "react";
import AsyncButton from "component/AsyncButton";
import styled from "styled-components";

const WrapperForm = styled(Form)`
   label {
      min-width: 120px;
   }
`;

function AddStore(props) {
   const [form] = Form.useForm();
   const [isLoading, setIsLoading] = useState(false);
   const [isSubmitting, setIsSubmitting] = useState(false);
   const [dataBrands, setDataBrands] = useState([]);
   const [dataRegions, setDataRegions] = useState({
      list: [],
      regions: [],
      regionSeleted: null,
   });
   const storeManagerActions = useStoreManagerActions();

   const getData = () => {
      setIsLoading(true);
      Promise.all([
         storeManagerActions.getBrands(),
         storeManagerActions.getRegions(),
      ])
         .then((values) => {
            setDataBrands(values[0]);

            const regions = values[1];
            const regionOptions = [];
            regions?.forEach((region) => {
               const index = regionOptions?.findIndex(
                  (regionOption) => regionOption?.value === region?.region
               );
               if (index >= 0) {
                  const current = regionOptions[index];
                  regionOptions[index] = {
                     ...current,
                     citys: [
                        ...current?.citys,
                        { label: region?.cityname, value: region?.city },
                     ],
                  };
               } else {
                  regionOptions.push({
                     value: region?.region,
                     citys: [{ label: region?.cityname, value: region?.city }],
                  });
               }
            });

            setDataRegions({
               list: regions,
               regions: regionOptions,
               regionSelected: null,
            });
         })
         .finally(() => {
            setIsLoading(false);
         });
   };

   const handleChangeRegion = (value) => {
      const regionSelected = dataRegions?.regions?.find(
         (dataRegion) => dataRegion?.value === value
      );
      setDataRegions((state) => ({ ...state, regionSelected: regionSelected }));
      form.setFieldsValue({ city: null });
   };

   const handleAddStore = async (values) => {
      setIsSubmitting(true);
      try {
         const region = dataRegions?.list?.find(
            (dataRegion) =>
               dataRegion?.region === values?.region_id &&
               dataRegion?.city === values?.city
         );
         values.region = region?.id;
         await storeManagerActions.addStore(values);
         message.success("Add store success!");
         props?.getData();
         props?.onClose();
      } finally {
         setIsSubmitting(false);
      }
   };

   useEffect(() => {
      getData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <div>
         <Modal
            title="Add Store"
            visible={props.visible}
            footer={null}
            onCancel={() => props.onClose(false)}
            width={"60vw"}
         >
            <Spin spinning={isLoading}>
               <WrapperForm
                  name="add-edit-store-form"
                  onFinish={handleAddStore}
                  form={form}
               >
                  <div className="grid grid-cols-2 gap-12">
                     <div>
                        <Form.Item
                           name="store_id"
                           label="Store ID"
                           rules={[
                              {
                                 required: true,
                                 message: "Store id is required!",
                              },
                           ]}
                        >
                           <Input placeholder="Store Code" />
                        </Form.Item>
                        <Form.Item
                           name="full_name"
                           label="Full Name"
                           rules={[
                              {
                                 required: true,
                                 message: "Store name is required!",
                              },
                           ]}
                        >
                           <Input placeholder="Store name" />
                        </Form.Item>

                        <Form.Item
                           name="short_name"
                           label="Short Name"
                           rules={[
                              {
                                 required: true,
                                 message: "Short name is required!",
                              },
                           ]}
                        >
                           <Input placeholder="Short name" />
                        </Form.Item>
                        <Form.Item
                           name="region_id"
                           label="Region"
                           rules={[
                              {
                                 required: true,
                                 message: "Store region is required!",
                              },
                           ]}
                        >
                           <Select
                              onChange={handleChangeRegion}
                              placeholder="Select Region"
                           >
                              {dataRegions?.regions?.map((item) => (
                                 <Select.Option
                                    key={item?.value}
                                    value={item?.value}
                                 >
                                    {item?.value}
                                 </Select.Option>
                              ))}
                           </Select>
                        </Form.Item>
                        <Form.Item
                           name="city"
                           label="City"
                           rules={[
                              {
                                 required: true,
                                 message: "Store region is required!",
                              },
                           ]}
                        >
                           <Select placeholder="Select City">
                              {dataRegions?.regionSelected?.citys?.map(
                                 (item) => (
                                    <Select.Option
                                       key={item?.value}
                                       value={item?.value}
                                    >
                                       {item.label}
                                    </Select.Option>
                                 )
                              )}
                           </Select>
                        </Form.Item>
                     </div>
                     <div>
                        <Form.Item
                           name="brand_id"
                           label="Brand"
                           rules={[
                              {
                                 required: true,
                                 message: "Brand region is required!",
                              },
                           ]}
                        >
                           <Select placeholder="Select Brand">
                              {dataBrands?.map((item, index) => (
                                 <Select.Option key={index} value={item?.id}>
                                    {item?.name || "-"}
                                 </Select.Option>
                              ))}
                           </Select>
                        </Form.Item>
                        <Form.Item name="RM" label="RM">
                           <Input placeholder="Restaurant Manager" />
                        </Form.Item>
                        <Form.Item name="AM" label="AM">
                           <Input placeholder="Area manager" />
                        </Form.Item>
                        <Form.Item name="SRM" label="SRM">
                           <Input placeholder="Senior Restaurant Manager" />
                        </Form.Item>
                     </div>
                  </div>
                  <Form.Item
                     className="text-center"
                     style={{ marginBottom: 0 }}
                  >
                     <AsyncButton
                        type="primary"
                        htmlType="submit"
                        isLoading={isSubmitting}
                     >
                        <span>Submit</span>
                     </AsyncButton>
                  </Form.Item>
               </WrapperForm>
            </Spin>
         </Modal>
      </div>
   );
}

export default AddStore;
