import useModal from "hooks/useModal";
import BannerForm from "./BannerForm";
import BannerListing from "./BannerListing";
import { useEffect, useState } from "react";
import { useBannerManagerActions } from "@iso/actions/bannerManager.actions";
import { message } from "antd";

function Banner() {
   const {
      visible: visiblePopupBanner,
      toggle: togglePopupBanner,
      hide: hidePopupBanner,
   } = useModal();
   const [data, setData] = useState({
      dataSource: [],
      paging: {
         current: 1,
         total: 0,
         pageSize: 10,
         search: "",
      },
   });
   const [bannerDetail, setBannerDetail] = useState({});
   const [loading, setLoading] = useState({
      isLoadingForm: false,
      isLoadingList: false,
      isLoadingDelete: "",
   });
   const bannerManagerActions = useBannerManagerActions();

   useEffect(() => {
      handleGetData(data?.paging);
   }, []);

   const handleGetData = async (params) => {
      setLoading({ ...loading, isLoadingList: true });
      try {
         const resGet = await bannerManagerActions.getBanners({
            page: params?.current,
            limit: params?.pageSize,
            search: params?.search,
         });
         setData({
            dataSource: resGet?.data,
            paging: {
               ...data?.paging,
               current: parseInt(resGet?.page),
               total: parseInt(resGet?.total),
            },
         });
         setLoading({ ...loading, isLoadingList: false });
      } catch (err) {
         setLoading({ ...loading, isLoadingList: false });
      }
   };

   const handleSubmitForm = async (values) => {
      try {
         const payload = { ...values };
         setLoading({ ...loading, isLoadingForm: true });
         if (typeof payload?.image_url !== "string" && payload?.image_url) {
            const response = await bannerManagerActions.uploadImage(
               payload?.image_url
            );
            payload.image_url = response?.url || "";
         }
         if (bannerDetail?.id) {
            await bannerManagerActions.updateBanner(bannerDetail?.id, payload);
         } else {
            await bannerManagerActions.createBanner(payload);
         }
         message.success(
            `${bannerDetail?.id ? "Edit" : "Add"} banner success!`
         );
         handleGetData();
         hidePopupBanner();
         setLoading({ ...loading, isLoadingForm: false });
      } catch (err) {
         setLoading({ ...loading, isLoadingForm: false });
         message.error(`${bannerDetail?.id ? "Edit" : "Add"} banner error!`);
      }
   };

   const handleDelete = async (id) => {
      setLoading({ ...loading, isLoadingDelete: id });
      try {
         await bannerManagerActions.deleteBanner(id);
         handleGetData();
         setLoading({ ...loading, isLoadingDelete: "" });
         message.success(`Delete banner success!`);
      } catch (err) {
         setLoading({ ...loading, isLoadingDelete: "" });
         message.error(`Delete banner error!`);
      }
   };

   const handleShowPopupBanner = (data) => {
      setBannerDetail(data);
      togglePopupBanner();
   };

   return (
      <section>
         <BannerListing
            data={data}
            onDelete={handleDelete}
            onFilter={handleGetData}
            onActionForm={handleShowPopupBanner}
            isLoadingList={loading.isLoadingList}
            isLoadingDelete={loading.isLoadingDelete}
         />
         <BannerForm
            data={bannerDetail}
            visible={visiblePopupBanner}
            onClose={togglePopupBanner}
            onSubmitForm={handleSubmitForm}
            isLoadingForm={loading.isLoadingForm}
            onFilter={handleGetData}
         />
      </section>
   );
}

export default Banner;
