import { API_ENDPOINT } from "@iso/config/index";
import { axiosClient } from "@iso/helpers/axios.config";

const ATTRIBUTE_TYPE_ENDPONT = `${API_ENDPOINT}/attribute_type`;
const ATTRIBUTE_ENDPONT = `${API_ENDPOINT}/attribute`;
const ITEM_ATTRIBUTE_ENDPONT = `${API_ENDPOINT}/item_attribute`;

const attributeManagerApi = {
   //Attribute Type
   getFilterPropertiesAttributeType: () => {
      return axiosClient.get(`${ATTRIBUTE_TYPE_ENDPONT}/filter-properties`);
   },

   getOptionsPropertyAttributeType: (params) => {
      return axiosClient.get(`${ATTRIBUTE_TYPE_ENDPONT}/options-property`, {
         params,
      });
   },

   getAllAttributeTypes: () => {
      return axiosClient.get(`${ATTRIBUTE_TYPE_ENDPONT}/all`);
   },

   getAttributeTypes: (params) => {
      return axiosClient.get(ATTRIBUTE_TYPE_ENDPONT, { params });
   },

   getAttributeType: (id) => {
      return axiosClient.get(`${ATTRIBUTE_TYPE_ENDPONT}/${id}`);
   },

   postAttributeType: (body) => {
      return axiosClient.post(ATTRIBUTE_TYPE_ENDPONT, body);
   },

   putAttributeType: (id, body) => {
      return axiosClient.put(`${ATTRIBUTE_TYPE_ENDPONT}/${id}`, body);
   },

   deleteAttributeType: (id) => {
      return axiosClient.delete(`${ATTRIBUTE_TYPE_ENDPONT}/${id}`);
   },

   //Attribute
   getFilterPropertiesAttribute: () => {
      return axiosClient.get(`${ATTRIBUTE_ENDPONT}/filter-properties`);
   },

   getOptionsPropertyAttribute: (params) => {
      return axiosClient.get(`${ATTRIBUTE_ENDPONT}/options-property`, {
         params,
      });
   },

   getAllAttribute: () => {
      return axiosClient.get(`${ATTRIBUTE_ENDPONT}/all`);
   },

   getAttributes: (params) => {
      return axiosClient.get(ATTRIBUTE_ENDPONT, { params });
   },

   getAttribute: (id) => {
      return axiosClient.get(`${ATTRIBUTE_ENDPONT}/${id}`);
   },

   postAttribute: (body) => {
      return axiosClient.post(ATTRIBUTE_ENDPONT, body);
   },

   putAttribute: (id, body) => {
      return axiosClient.put(`${ATTRIBUTE_ENDPONT}/${id}`, body);
   },

   deleteAttribute: (id) => {
      return axiosClient.delete(`${ATTRIBUTE_ENDPONT}/${id}`);
   },

   //Item Attribute
   postItemAttribute: (body) => {
      return axiosClient.post(ITEM_ATTRIBUTE_ENDPONT, body);
   },

   putItemAttribute: (id, body) => {
      return axiosClient.put(`${ITEM_ATTRIBUTE_ENDPONT}/${id}`, body);
   },

   deleteItemAttribute: (id) => {
      return axiosClient.delete(`${ITEM_ATTRIBUTE_ENDPONT}/${id}`);
   },
};

export default attributeManagerApi;
